<template>
    <h5 class="mb-2">Contracts</h5>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Active Contracts</h3>
                    <!-- <div class="card-tools">
                        <div class="input-group input-group-sm">
                            <input type="text" name="table_search" class="form-control float-right" placeholder="Search" v-model="meta.search" @keyup="search()" />
                        </div>
                    </div> -->
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Plants</label>
                                <Search
                                    :class="{ 'is-invalid': errors.plant_id }"
                                    :customClass="{ 'is-invalid': errors.plant_id }"
                                    :initialize="meta.plant_id"
                                    id="plant_id"
                                    label="plant_name"
                                    placeholder="Select Plant"
                                    :data="plants"
                                    @input="selectPlant($event)"
                                />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">       
                                <label>Vendors</label>
                                <Search
                                    :class="{ 'is-invalid': errors.vendor_id }"
                                    :customClass="{ 'is-invalid': errors.vendor_id }"
                                    :initialize="meta.vendor_id"
                                    id="vendor_id"
                                    label="vendor_name"
                                    placeholder="Select Vendor"
                                    :data="vendors"
                                    @input="selectVendor($event)"
                                />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label>Search</label>
                            <div class="input-group input-group-sm">
                                <input type="text" name="table_search" class="form-control float-right" placeholder="Search" v-model="meta.search" @keyup="search()" />
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="float-right">
                                <button class="btn btn-danger btn-sm" @click="resetFilters()"><i class="fa fa-undo" aria-hidden="true"></i> Reset</button>
                            </div>
                        </div>
                    </div>
                    <table class="table table-responsive-sm table-bordered table-striped table-sm" style="margin-top:10px">
                        <thead>
                            <tr>
                                <th class="text-center" width="1%">#</th>
                                <th @click="sort('sap_contract_no')" width="10%">
                                    SAP Contract No
                                    <span>
                                        <i v-if="meta.keyword=='sap_contract_no' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                        <i v-else-if="meta.keyword=='sap_contract_no' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('material_code')" width="10%">
                                    Material Code
                                    <i v-if="meta.keyword=='material_code' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='material_code' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th @click="sort('grade')" width="10%">
                                    Materail Description
                                    <i v-if="meta.keyword=='grade' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='grade' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th width="10%">
                                    Plant
                                    <!-- <i v-if="meta.keyword=='message' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='message' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i> -->
                                </th>
                                <th width="10%">
                                    Vendor
                                    <!-- <i v-if="meta.keyword=='message' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='message' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i> -->
                                </th>
                                <th width="10%">
                                    From Date
                                    <!-- <i v-if="meta.keyword=='message' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='message' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i> -->
                                </th>
                                <th width="10%">
                                    To Date
                                    <!-- <i v-if="meta.keyword=='message' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='message' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i> -->
                                </th>
                                <th width="10%">
                                    Amendment Date
                                    <!-- <i v-if="meta.keyword=='message' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='message' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i> -->
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="contracts.length==0">
                                <td colspan="9" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(contract,key) in contracts" :key="key">
                                <td>{{ meta.from+key }}</td>
                                <td>{{ contract.sap_contract_no }}</td>
                                <td>{{ contract.material_code }}</td>
                                <td>{{ contract.grade }}</td>
                                <td>{{ contract.plant.plant_name }}</td>
                                <td>{{ contract.vendor.vendor_name }}</td>
                                <td>{{ convertDateFormat(contract.from_date) }}</td>
                                <td>{{ convertDateFormat(contract.to_date) }}</td>
                                <td>{{ convertDateFormat(contract.amendment_date) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    import moment from "moment";
    import Search from "@/components/Search.vue";
    export default {
        components: { Pagination, Search },
        data() {
            return {
                plants:[],
                vendors:[],
                contracts: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "contract_id",
                    plant_id:'',
                    vendor_id:'',
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                errors: [],
            };
        },
        mounted() {
            this.getPlants()
            this.index()
        },
        methods: {
            resetFilters(){
                this.meta.search =  "",
                this.meta.order_by =  "asc",
                this.meta.keyword =  "contract_id",
                this.meta.plant_id = '',
                this.meta.vendor_id = '',
                this.meta.per_page =  10,
                this.meta.totalRows =  0,
                this.meta.page =  1,
                this.meta.lastPage =  1,
                this.meta.from =  1,
                this.meta.maxPage =  1,
                this.index()
            },
            getVendors() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getVendors" })
                    .then(function (response) {
                        vm.vendors = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            selectVendor(e) {
                let vm = this
                vm.meta.vendor_id = e
                vm.meta.page = 1
                vm.index()
            },
            getPlants() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getPlants" })
                    .then(function (response) {
                        vm.plants = response.data.data
                        vm.getVendors()
                    })
                    .catch(function (error) {
                        console.log(error)
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            selectPlant(e) {
                let vm = this;
                vm.meta.plant_id = e;
                vm.meta.page = 1
                vm.index()
            },
            convertDateFormat(date) {
                return moment(date).format("DD-MM-YYYY")
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateContracts", data: vm.meta })
                    .then(function (response) {
                        vm.contracts = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        console.log(error)
                        // vm.errors = error.response.data.errors;
                        // vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
        },
    };
</script>
<style scoped>
.padding{
    padding:5px;
}
</style>
