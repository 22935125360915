<template>
    <div class="wrapper" v-if="!$store.getters.user">
        <router-view />
    </div>
    <div class="wrapper" v-else>
        <Header></Header>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <router-view />
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import Header from "./components/Header.vue";
    import Footer from "./components/Footer.vue";
    export default {
        components: { Header, Footer },

        data() {
            return {};
        },
        created() {
            //Read the status information in sessionStorage when the page is loaded
            if (sessionStorage.getItem("user")) {
                this.$store.dispatch("setUser", JSON.parse(sessionStorage.getItem("user")));
                sessionStorage.removeItem("user");
            }
            if (sessionStorage.getItem("token")) {
                this.$store.dispatch("setToken", sessionStorage.getItem("token"));
                sessionStorage.removeItem("token");
            }
            if (sessionStorage.getItem("artwork")) {
                this.$store.dispatch("setArtwork", JSON.parse(sessionStorage.getItem("artwork")));
                // this.getArtwork()
                sessionStorage.removeItem("artwork");
            }
            if (sessionStorage.getItem("versions")) {
                this.$store.dispatch("setVersions", JSON.parse(sessionStorage.getItem("versions")));
                sessionStorage.removeItem("versions");
            }
            //  if (sessionStorage.getItem("permissions") ) {
            //     this.$store.dispatch('setPermissions', JSON.parse(sessionStorage.getItem("permissions")))
            //     sessionStorage.removeItem('permissions')
            // }
            //Save the information in vuex to sessionStorage when the page is refreshed
            window.addEventListener("beforeunload", () => {
                sessionStorage.setItem("user", JSON.stringify(this.$store?.getters?.user));
                sessionStorage.setItem("artwork", JSON.stringify(this.$store?.getters?.artwork));
                sessionStorage.setItem("versions", JSON.stringify(this.$store?.getters?.versions));
                sessionStorage.setItem("token", this.$store?.getters?.token);
                // sessionStorage.setItem("permissions",  JSON.stringify(this.$store?.getters?.permissions))
            });
        },

        methods:{
            getArtwork() {
                let vm = this;
                let artwork = this.$store.getters.artwork
                vm.$store.dispatch("setArtwork", null)
                vm.$store.dispatch("post", { uri: "getArtwork", data: artwork })
                .then(function (response) {
                    vm.$store.dispatch("setArtwork", response.data.data)
                    vm.setArtworkVersions()
                })
                .catch(function (error) {
                    console.log(error)
                });
            },

            setArtworkVersions(){
                let vm = this
                vm.versions = []
                let artwork = this.$store.getters.artwork
                if(artwork.parent?.children.length){
                    let version_exists = vm.versions.filter(function(element){
                        element.artwork_id == artwork.parent.artwork_id
                    })
                    if(!version_exists.length){
                        vm.versions = artwork.parent.children
                        if(vm.role=='TS' || vm.role=='TPMC' || vm.role=='CPC Maker' || vm.role=='CPC Checker'){
                            vm.versions.unshift({
                                artwork_id : artwork.parent.artwork_id,
                                artwork_no : artwork.parent.artwork_no,
                                artwork_date : artwork.parent.artwork_date,
                                artwork_type : artwork.parent.artwork_type,
                                plant_id : artwork.parent.plant_id,
                                bag_type_id : artwork.parent.bag_type_id,
                                material_id : artwork.parent.material_id,
                                language_id : artwork.parent.language_id,
                                bis_approval_date : artwork.parent.bis_approval_date,
                                artwork_pdf_name : artwork.parent.artwork_pdf,
                                artwork_image_name : artwork.parent.artwork_image,
                                artwork_ai_name : artwork.parent.artwork_ai,
                                cpc_approved_date: artwork.parent.cpc_approved_date,
                                next_cpc_approved_date: artwork.parent.next_cpc_approved_date
                            })
                        }
                    }
                }else if(artwork.children?.length){
                    vm.versions = artwork.children
                    let version_exists = vm.versions.filter(function(element){
                        element.artwork_id == artwork.artwork_id
                    })
                    if(!version_exists.length){
                        vm.versions.unshift({
                            artwork_id : artwork.artwork_id,
                            artwork_no : artwork.artwork_no,
                            artwork_date : artwork.artwork_date,
                            artwork_type : artwork.artwork_type,
                            plant_id : artwork.plant_id,
                            bag_type_id : artwork.bag_type_id,
                            material_id : artwork.material_id,
                            language_id : artwork.language_id,
                            bis_approval_date : artwork.bis_approval_date,
                            artwork_pdf_name : artwork.artwork_pdf,
                            artwork_image_name : artwork.artwork_image,
                            artwork_ai_name : artwork.artwork_ai,
                            cpc_approved_date : artwork.cpc_approved_date,
                            next_cpc_approved_date: artwork.next_cpc_approved_date
                        })
                    }
                }else{
                    let version_exists = vm.versions.filter(function(element){
                        element.artwork_id == artwork.artwork_id
                    })
                    if(!version_exists.length){
                        vm.versions.unshift({
                            artwork_id : artwork.artwork_id,
                            artwork_no : artwork.artwork_no,
                            artwork_date : artwork.artwork_date,
                            artwork_type : artwork.artwork_type,
                            plant_id : artwork.plant_id,
                            bag_type_id : artwork.bag_type_id,
                            material_id : artwork.material_id,
                            language_id : artwork.language_id,
                            bis_approval_date : artwork.bis_approval_date,
                            artwork_pdf_name : artwork.artwork_pdf,
                            artwork_image_name : artwork.artwork_image,
                            artwork_ai_name : artwork.artwork_ai,
                            cpc_approved_date : artwork.cpc_approved_date,
                            next_cpc_approved_date: artwork.next_cpc_approved_date
                        })
                    }
                }
                vm.$store.dispatch("setVersions", vm.versions);
            },
        }
    };
</script>
