<template>
    <h5 class="mb-2">Bag Types</h5>
    <div class="row">
        <div class="col-sm-4">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">New Bag Type</h3>
                </div>
                <form @submit.prevent="submitForm">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="bag_type">Bag Type <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm" id="bag_type" placeholder="Bag Type" :class="{'is-invalid': errors.bag_type}" v-model="bag_type.bag_type" ref="bag_type" />
                            <span v-if="errors.bag_type" class="invalid-feedback">{{ errors.bag_type[0] }}</span>
                        </div>
                        <div class="form-group">
                            <label for="Description">Description </label>
                            <textarea type="text" class="form-control form-control-sm" id="Description" placeholder="Descritpion" v-model="bag_type.description" ref="description" />
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="float-right">
                            <button type="submit" class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button>
                            <button class="btn btn-outline-success btn-sm" type="submit">
                                <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                <span v-else> <i class="fas fa-save"></i> Update </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-sm-8">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Bag Types</h3>
                    <div class="card-tools">
                        <div class="input-group input-group-sm">
                            <input type="text" name="table_search" class="form-control float-right" placeholder="Search" v-model="meta.search" @keyup="search()" />
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-responsive-sm table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th @click="sort('bag_type')">
                                    Bag Type
                                    <span>
                                        <i v-if="meta.keyword=='bag_type' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                        <i v-else-if="meta.keyword=='bag_type' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('description')">
                                    Description
                                    <i v-if="meta.keyword=='description' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='description' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="bag_types.length==0">
                                <td colspan="5" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(bag_type,key) in bag_types" :key="key">
                                <td>{{meta.from+key }}</td>
                                <td>{{bag_type.bag_type}}</td>
                                <td>
                                    {{bag_type.description}}
                                </td>
                                <td class="text-center">
                                    <div class="card-tools">
                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" :id="'bag_type' + bag_type.bag_type_id" :checked="bag_type.status" :value="bag_type.status" @change="deleteBagType(bag_type)" />
                                            <label class="custom-control-label" :for="'bag_type' + bag_type.bag_type_id"></label>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center py-0 align-middle">
                                    <button class="btn btn-xs btn-outline-success" data-toggle="tooltip" data-placement="bottom" title="Edit" @click.prevent="edit(bag_type)">
                                        <i class="fas fa-pencil-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                status: true,
                bag_types: [],
                bag_type: {
                    bag_type_id: "",
                    bag_type: "",
                    description: "",
                },
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "bag_type_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                errors: [],
            };
        },
        mounted() {
            this.index();
        },
        methods: {
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateBagTypes", data: vm.meta })
                    .then(function (response) {
                        vm.bag_types = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.addBagType();
                } else {
                    vm.updateBagType();
                }
            },
            addBagType() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addBagType", data: vm.bag_type })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Bag Type Added successfully");
                        vm.index();
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            edit(bag_type) {
                let vm = this;
                vm.errors = []
                vm.status = false 
                vm.bag_type = bag_type
                vm.$refs.bag_type.focus();
            },
            updateBagType() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateBagType", data: vm.bag_type };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Bag Type is successfully updated");
                        vm.discard();
                        vm.errors = [];
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
           
            deleteBagType(bag_type) {
                let vm = this;
                bag_type.status = bag_type.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deleteBagType", data: bag_type })
                    .then(function () {
                        vm.discard();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
                let vm = this;
                vm.bag_type.bag_type_id = "";
                vm.bag_type.bag_type = "";
                vm.bag_type.description = "";
                vm.$refs.bag_type.focus();
                vm.errors = [];
                vm.status = true;
                vm.index();
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
        },
    };
</script>
