<template>
    <h5 class="mb-2">Materials</h5>
    <div class="row">
        <div class="col-sm-4">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">New Material</h3>
                </div>
                <form @submit.prevent="submitForm">
                    <div class="card-body">
                        <div class="form-group">
                            <label>Bag Type <span class="text-danger">*</span></label>
                            <Search
                                :class="{ 'is-invalid': errors.bag_type_id }"
                                :customClass="{ 'is-invalid': errors.bag_type_id }"
                                :initialize="material.bag_type_id"
                                id="bag_type_id"
                                label="bag_type"
                                placeholder="Select Bag Type"
                                :data="bagtypes"
                                @input="bagtype => material.bag_type_id = bagtype"
                            />
                            <span v-if="errors.bag_type_id" class="invalid-feedback">{{ errors.bag_type_id[0] }}</span>
                        </div>
                        <div class="form-group">
                            <label>Material <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm" placeholder="Material" :class="{'is-invalid': errors.material}" v-model="material.material" ref="material" />
                            <span v-if="errors.material" class="invalid-feedback">{{ errors.material[0] }}</span>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="float-right">
                            <button type="submit" class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button>
                            <button class="btn btn-outline-success btn-sm" type="submit">
                                <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                <span v-else> <i class="fas fa-save"></i> Update </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-sm-8">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Materials</h3>
                    <div class="card-tools">
                        <div class="input-group input-group-sm">
                            <input type="text" name="table_search" class="form-control float-right" placeholder="Search" v-model="meta.search" @keyup="search()" />
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-responsive-sm table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th @click="sort('bag_type_id')">
                                    Bag Type
                                    <i v-if="meta.keyword=='bag_type_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='bag_type_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th @click="sort('material')">
                                    Material
                                    <i v-if="meta.keyword=='material' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='material' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="materials.length==0">
                                <td colspan="7" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(material, key) in materials" :key="key">
                                <td>{{meta.from+key}}</td>
                                <td>{{material.bag_type.bag_type}}</td>
                                <td>{{material.material}}</td>
                                <td class="text-center">
                                    <div class="card-tools">
                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                :id="'material' + material.material_id"
                                                :checked="material.status"
                                                :value="material.status"
                                                @change="deleteMaterial(material)"
                                            />
                                            <label class="custom-control-label" :for="'material' + material.material_id"></label>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center py-0 align-middle">
                                    <button class="btn btn-xs btn-outline-success" @click.prevent="edit(material)" data-toggle="tooltip" data-placement="bottom" title="Edit">
                                        <i class="fas fa-pencil-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    import Search from "@/components/Search.vue";
    export default {
        components: { Pagination, Search },
        data() {
            return {
                status: true,
                languages: [],
                language: {
                    language_id: "",
                    language: "",
                    description: "",
                },
                plants:[],
                bagtypes:[],
                material: {
                    material_type_id: "",
                    material_code: "",
                    material: "",
                    plant_id: "",
                    bag_type_id: "",
                    description: "",
                },
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "material",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                materials:[],
                errors: [],
            };
        },
        mounted() {
            this.index();
        },
        methods: {
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            getPlants() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getPlants" })
                    .then(function (response) {
                        vm.plants = response.data.data;
                        vm.getBagTypes()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getBagTypes() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getBagTypes" })
                    .then(function (response) {
                        vm.bagtypes = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateMaterials", data: vm.meta })
                    .then(function (response) {
                        vm.materials = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                        vm.getPlants()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.addMaterial();
                } else {
                    vm.updateMaterial();
                }
            },
            addMaterial() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addMaterial", data: vm.material })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Material Added successfully");
                        vm.index();
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            edit(material) {
                let vm = this;
                vm.errors = []
                vm.status = false 
                vm.material = material
                vm.$refs.material.focus();
            },
            updateMaterial() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateMaterial", data: vm.material };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Material is successfully updated");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            deleteMaterial(material) {
                let vm = this;
                material.status = material.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deleteMaterial", data:material })
                    .then(function () {
                        vm.discard();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
                let vm = this;
                vm.material.material_type_id = "";
                vm.material.material = "";
                vm.material.plant_id = "";
                vm.material.bag_type_id = "";
                vm.$refs.material.focus();
                vm.index();
                vm.errors = [];
                vm.status = true;
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
        },
    };
</script>
