<template>
    <h5 class="mb-2">Log</h5>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Log</h3>
                    <div class="card-tools">
                        <span class="badge bg-success float-right padding" v-if="log.status">Success</span>
                        <span class="badge bg-danger float-right padding" v-else>Failed</span>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-3">
                            <label>File Path</label>
                            <p>{{log.file_path}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Upload Date Time</label>
                            <p>{{convertDateFormat(log.date_time)}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Created Date Time</label>
                            <p>{{convertDateFormat(log.created_at)}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Message</label>
                            <p>{{log.message}}</p>
                        </div>
                        <div class="col-sm-12">
                            <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th class="center">Row</th>
                                        <th class="center">Attribute</th>
                                        <th class="center">Errors</th>
                                        <th class="center">Values</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-if="log.log_errors.length<=0" colspan="4" class="center">No records found</td>
                                    </tr>
                                    <tr v-for="log_error, key in log.log_errors" :key="key">
                                        <td>{{log_error['row']}}</td>
                                        <td>{{log_error['attribute']}}</td>
                                        <td>
                                            <div v-for="error, error_key in log_error['errors']" :key="error_key">
                                                {{error }}
                                            </div>
                                        </td>
                                        <td>{{log_error['values']}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    export default {
        components: {  },
        data() {
            return {
                log: {
                    log_id:'',
                    file_path:'',
                    date_time:'',
                    status:'',
                    message:'',
                    log_errors:[]
                }
            };
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.log.log_id = to.params.log_id
            })
        },
        mounted() {
            this.getLog()
        },
        methods: {

            getLog(){
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getLog", data: vm.log })
                    .then(function (response) {
                        vm.log = response.data.data
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            convertDateFormat(date) {
                return moment(date).format("DD-MM-YYYY hh:mm A")
            }
        },
    };
</script>
<style scoped>
.center{
    text-align: center;
}
.padding{
    padding:5px;
}
</style>
