<template>
    <h5 class="mb-2">{{artwork.artwork_no}}</h5>
    <div class="row">
        <div class="col-sm-3">
            <Versions  ref="versions"  @showArtwork="showArtwork"/>
        </div>
        <div class="col-sm-9">
            <div class="card">
                <Menu />
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4" v-if="!artwork_tpmc.table_visible">
                            <label>Upload Excel <span class="text-danger">*</span></label>
                            <div class="form-group">
                                <div class="custom-file">
                                    <input type="file" ref="specification_excel" class="custom-file-input custom-file-input-sm" id="customFile" :class="{'is-invalid': errors.specification_excel}" @change="getSpecificationExcel($event)" accept=".xlsx" />
                                    <label class="custom-file-label">{{ artwork.specification_excel_name }}</label>
                                    <span v-if="errors.specification_excel" class="invalid-feedback">{{ errors.specification_excel[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4" v-if="!artwork_tpmc.table_visible">
                            <label>Upload PDF <span class="text-danger">*</span></label>
                            <div class="form-group">
                                <div class="custom-file">
                                    <input type="file" ref="specification_pdf" class="custom-file-input custom-file-input-sm" id="customFile" :class="{'is-invalid': errors.specification_pdf}" @change="getSpecificationPdf($event)" accept=".pdf" />
                                    <label class="custom-file-label">{{ artwork.specification_pdf_name }}</label>
                                    <span v-if="errors.specification_pdf" class="invalid-feedback">{{ errors.specification_pdf[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4" v-if="!artwork_tpmc.table_visible">
                            <div class="form-group">
                                <label>Submitted Date <span class="text-danger">*</span></label>
                                <input type="date" class="form-control" :class="{ 'is-invalid': errors.tpmc_submitted_date }" v-model="tpmc_submitted_date" readonly/>
                                <span v-if="errors.tpmc_submitted_date" class="invalid-feedback">{{ errors.tpmc_submitted_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="!artwork_tpmc.table_visible">
                            <a :href="specificationExcelUrl()" target="_blank">Download template</a>
                        </div>
                        <div class="col-md-12" style="margin-top:10px">
                        <div v-if="errors.length">
                            <span style="background-color:red; color:white; font-size:12pt; padding:5px 10px">Error Details</span><br><br>
                            <ul v-for="error, key in errors" :key="key">
                                <li>{{error}}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-12" v-if="artwork_tpmc.table_visible">
                        <table class="table table-responsive-sm table-bordered table-striped table-sm" v-if="artwork?.bag_type?.bag_type=='PP' || artwork?.bag_type?.bag_type=='RPP'">
                            <tbody>
                                <tr>
                                    <td width="5%">1</td>
                                    <td width="45%">Bag Capacity</td>
                                    <td width="50%">
                                        <span>{{artwork_tpmc['Bag Capacity']}}</span>
                                        <span>{{artwork_tpmc.bag_capacity}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.bag_capacity }"  class="form-control form-control-sm" v-model="artwork_tpmc.bag_capacity"> -->
                                        <span v-if="errors.bag_capacity" class="invalid-feedback">{{ errors.bag_capacity[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Colour of Fabric</td>
                                    <td>
                                        <span>{{artwork_tpmc['Fabric Colour']}}</span>
                                        <span>{{artwork_tpmc.colour_fabric}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.colour_fabric }"  class="form-control form-control-sm" v-model="artwork_tpmc.colour_fabric"> -->
                                        <span v-if="errors.colour_fabric" class="invalid-feedback">{{ errors.colour_fabric[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Length</td>
                                    <td>
                                        <span>{{artwork_tpmc['Length']}}</span>
                                        <span>{{artwork_tpmc.length}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.length }"  class="form-control form-control-sm" v-model="artwork_tpmc.length"> -->
                                        <span v-if="errors.length" class="invalid-feedback">{{ errors.length[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Width</td>
                                    <td>
                                        <span>{{artwork_tpmc['Width']}}</span>
                                        <span>{{artwork_tpmc.width}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.width }"  class="form-control form-control-sm" v-model="artwork_tpmc.width"> -->
                                        <span v-if="errors.width" class="invalid-feedback">{{ errors.width[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Valve Width</td>
                                    <td>
                                        <span>{{artwork_tpmc['Valve Width']}}</span>
                                        <span>{{artwork_tpmc.valve_width}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.valve_width }"  class="form-control form-control-sm" v-model="artwork_tpmc.valve_width"> -->
                                        <span v-if="errors.valve_width" class="invalid-feedback">{{ errors.valve_width[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Valve Depth</td>
                                    <td>
                                        <span>{{artwork_tpmc['Valve Depth']}}</span>
                                        <span>{{artwork_tpmc.valve_depth}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.valve_depth }"  class="form-control form-control-sm" v-model="artwork_tpmc.valve_depth"> -->
                                        <span v-if="errors.valve_depth" class="invalid-feedback">{{ errors.valve_depth[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Valve Type</td>
                                    <td>
                                        <span>{{artwork_tpmc['Valve Type']}}</span>
                                        <span>{{artwork_tpmc.valve_type}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.valve_type }"  class="form-control form-control-sm" v-model="artwork_tpmc.valve_type"> -->
                                        <span v-if="errors.valve_type" class="invalid-feedback">{{ errors.valve_type[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Bag Weight</td>
                                    <td>
                                        <span>{{artwork_tpmc['Bag Weight (gm)']}}</span>
                                        <span>{{artwork_tpmc.bag_weight}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.bag_weight }"  class="form-control form-control-sm" v-model="artwork_tpmc.bag_weight"> -->
                                        <span v-if="errors.bag_weight" class="invalid-feedback">{{ errors.bag_weight[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Ends and Picks per decimeter</td>
                                    <td>
                                        <span>{{artwork_tpmc['Ends and Picks per Decimeter']}}</span>
                                        <span>{{artwork_tpmc.ends_and_picks_per_decimeter}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.ends_and_picks_per_decimeter }"  class="form-control form-control-sm" v-model="artwork_tpmc.ends_and_picks_per_decimeter"> -->
                                        <span v-if="errors.ends_and_picks_per_decimeter" class="invalid-feedback">{{ errors.ends_and_picks_per_decimeter[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Breaking Load (Min) – Length/Width Average</td>
                                    <td>
                                        <span>{{artwork_tpmc['Breaking Load Length/Width (Average)']}}</span>
                                        <span>{{artwork_tpmc.breaking_load_length_width_average}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.breaking_load_length_width_average }"  class="form-control form-control-sm" v-model="artwork_tpmc.breaking_load_length_width_average"> -->
                                        <span v-if="errors.breaking_load_length_width_average" class="invalid-feedback">{{ errors.breaking_load_length_width_average[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Breaking Load (Min) – Length/Width Individual</td>
                                    <td>
                                        <span>{{artwork_tpmc['Breaking Load Length/Width (Individual)']}}</span>
                                        <span>{{artwork_tpmc.breaking_load_length_width_individual}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.breaking_load_length_width_individual }"  class="form-control form-control-sm" v-model="artwork_tpmc.breaking_load_length_width_individual"> -->
                                        <span v-if="errors.breaking_load_length_width_individual" class="invalid-feedback">{{ errors.breaking_load_length_width_individual[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Breaking Load (Min) – Seam (Bottom/Top) Average</td>
                                    <td>
                                        <span>{{artwork_tpmc['Breaking Load Seam Bottom/Top (Average)']}}</span>
                                        <span>{{artwork_tpmc.breaking_load_seam_average}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.breaking_load_seam_average }"  class="form-control form-control-sm" v-model="artwork_tpmc.breaking_load_seam_average"> -->
                                        <span v-if="errors.breaking_load_seam_average" class="invalid-feedback">{{ errors.breaking_load_seam_average[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Breaking Load (Min) – Seam (Bottom/Top) Individual</td>
                                    <td>
                                        <span>{{artwork_tpmc['Breaking Load Seam Bottom/Top (Individual)']}}</span>
                                        <span>{{artwork_tpmc.breaking_load_seam_individual}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.breaking_load_seam_individual }"  class="form-control form-control-sm" v-model="artwork_tpmc.breaking_load_seam_individual"> -->
                                        <span v-if="errors.breaking_load_seam_individual" class="invalid-feedback">{{ errors.breaking_load_seam_individual[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Load (Min) – Stitching</td>
                                    <td>
                                        <span>{{artwork_tpmc['Load (min) Stitching']}}</span>
                                        <span>{{artwork_tpmc.load_min_stitching}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.load_min_stitching }"  class="form-control form-control-sm" v-model="artwork_tpmc.load_min_stitching"> -->
                                        <span v-if="errors.load_min_stitching" class="invalid-feedback">{{ errors.load_min_stitching[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Elongation at Break of Fabric (min)</td>
                                    <td>
                                        <span>{{artwork_tpmc['Elongation at Break of Fabric (min) %']}}</span>
                                        <span>{{artwork_tpmc.elongation_at_break_of_fabric}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.elongation_at_break_of_fabric }"  class="form-control form-control-sm" v-model="artwork_tpmc.elongation_at_break_of_fabric"> -->
                                        <span v-if="errors.elongation_at_break_of_fabric" class="invalid-feedback">{{ errors.elongation_at_break_of_fabric[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>Ash Content (%)</td>
                                    <td>
                                        <span>{{artwork_tpmc['Ash Content (max) %']}}</span>
                                        <span>{{artwork_tpmc.ash_content}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.ash_content }"  class="form-control form-control-sm" v-model="artwork_tpmc.ash_content"> -->
                                        <span v-if="errors.ash_content" class="invalid-feedback">{{ errors.ash_content[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Fabric GSM</td>
                                    <td>
                                        <span>{{artwork_tpmc['Fabric GSM']}}</span>
                                        <span>{{artwork_tpmc.fabric_gsm}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_gsm }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_gsm"> -->
                                        <span v-if="errors.fabric_gsm" class="invalid-feedback">{{ errors.fabric_gsm[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>Tape Width</td>
                                    <td>
                                        <span>{{artwork_tpmc['Tape Width']}}</span>
                                        <span>{{artwork_tpmc.tape_width}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.tape_width }"  class="form-control form-control-sm" v-model="artwork_tpmc.tape_width"> -->
                                        <span v-if="errors.tape_width" class="invalid-feedback">{{ errors.tape_width[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>Tape Mesh</td>
                                    <td>
                                        <span>{{artwork_tpmc['Tape Mesh']}}</span>
                                        <span>{{artwork_tpmc.tape_mesh}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.tape_mesh }"  class="form-control form-control-sm" v-model="artwork_tpmc.tape_mesh"> -->
                                        <span v-if="errors.tape_mesh" class="invalid-feedback">{{ errors.tape_mesh[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>Tape Denier</td>
                                    <td>
                                        <span>{{artwork_tpmc['Tape Denier']}}</span>
                                        <span>{{artwork_tpmc.tape_denier}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.tape_denier }"  class="form-control form-control-sm" v-model="artwork_tpmc.tape_denier"> -->
                                        <span v-if="errors.tape_denier" class="invalid-feedback">{{ errors.tape_denier[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>Printing</td>
                                    <td>
                                        <span>{{artwork_tpmc['Printing']}}</span>
                                        <span>{{artwork_tpmc.printing}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.printing }"  class="form-control form-control-sm" v-model="artwork_tpmc.printing"> -->
                                        <span v-if="errors.printing" class="invalid-feedback">{{ errors.printing[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>Tape Strength</td>
                                    <td>
                                        <span>{{artwork_tpmc['Tape Strength']}}</span>
                                        <span>{{artwork_tpmc.strength}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.strength }"  class="form-control form-control-sm" v-model="artwork_tpmc.strength"> -->
                                        <span v-if="errors.strength" class="invalid-feedback">{{ errors.strength[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>No of Stitches per Decimeter</td>
                                    <td>
                                        <span>{{artwork_tpmc['No. of Stitches per Decimeter']}}</span>
                                        <span>{{artwork_tpmc.no_of_stitches_per_decimeter}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.no_of_stitches_per_decimeter }"  class="form-control form-control-sm" v-model="artwork_tpmc.no_of_stitches_per_decimeter"> -->
                                        <span v-if="errors.no_of_stitches_per_decimeter" class="invalid-feedback">{{ errors.no_of_stitches_per_decimeter[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>Colour Strip</td>
                                    <td>
                                        <span>{{artwork_tpmc['Colour Strip']}}</span>
                                        <span>{{artwork_tpmc.colour_strip}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.colour_strip }"  class="form-control form-control-sm" v-model="artwork_tpmc.colour_strip"> -->
                                        <span v-if="errors.colour_strip" class="invalid-feedback">{{ errors.colour_strip[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>Stitching Thread</td>
                                    <td>
                                        <span>{{artwork_tpmc['Stitching Thread Colour']}}</span>
                                        <span>{{artwork_tpmc.stitching_thread}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.stitching_thread }"  class="form-control form-control-sm" v-model="artwork_tpmc.stitching_thread"> -->
                                        <span v-if="errors.stitching_thread" class="invalid-feedback">{{ errors.stitching_thread[0] }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-responsive-sm table-bordered table-striped table-sm" v-else>
                            <thead>
                                <tr>
                                    <td width="5%">1</td>
                                    <td width="45%">Bag Capacity</td>
                                    <td width="50%">
                                        <span>{{artwork_tpmc['Bag Capacity']}}</span>
                                        <span>{{artwork_tpmc.bag_capacity}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.bag_capacity }"  class="form-control form-control-sm" v-model="artwork_tpmc['Bag Capacity']"> -->
                                        <span v-if="errors.bag_capacity" class="invalid-feedback">{{ errors.bag_capacity[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Fabric Colour</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric Colour']}}</span>
                                        <span>{{ artwork_tpmc['colour_fabric']}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.colour_fabric }"  class="form-control form-control-sm" v-model="artwork_tpmc['Fabric Colour']"> -->
                                        <span v-if="errors.colour_fabric" class="invalid-feedback">{{ errors.colour_fabric[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Length</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Length']}}</span>
                                        <span>{{ artwork_tpmc.length}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.length }"  class="form-control form-control-sm" v-model="artwork_tpmc['Length']"> -->
                                        <span v-if="errors.length" class="invalid-feedback">{{ errors.length[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Width</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Width']}}</span>
                                        <span>{{ artwork_tpmc.width}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.width }"  class="form-control form-control-sm" v-model="artwork_tpmc['Width']"> -->
                                        <span v-if="errors.width" class="invalid-feedback">{{ errors.width[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Valve Width</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Valve Width']}}</span>
                                        <span>{{ artwork_tpmc.valve_width}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.valve_width }"  class="form-control form-control-sm" v-model="artwork_tpmc['Valve Width']"> -->
                                        <span v-if="errors.valve_width" class="invalid-feedback">{{ errors.valve_width[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Valve Depth</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Valve Depth']}}</span>
                                        <span>{{ artwork_tpmc.valve_depth}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.valve_depth }"  class="form-control form-control-sm" v-model="artwork_tpmc['Valve Depth']"> -->
                                        <span v-if="errors.valve_depth" class="invalid-feedback">{{ errors.valve_depth[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Valve Colour</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Valve Colour']}}</span>
                                        <span>{{ artwork_tpmc.valve_colour}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.valve_colour }"  class="form-control form-control-sm" v-model="artwork_tpmc['Valve Colour']"> -->
                                        <span v-if="errors.valve_colour" class="invalid-feedback">{{ errors.valve_colour[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Patch Length (OS) mm</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Patch Length (OS) mm']}}</span>
                                        <span>{{ artwork_tpmc.patch_length_os}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.patch_length_os }"  class="form-control form-control-sm" v-model="artwork_tpmc['Patch Length (OS) mm']"> -->
                                        <span v-if="errors.patch_length_os" class="invalid-feedback">{{ errors.patch_length_os[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Patch Length (DS) mm</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Patch Length (DS) mm']}}</span>
                                        <span>{{ artwork_tpmc.patch_length_ds}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.patch_length_ds }"  class="form-control form-control-sm" v-model="artwork_tpmc['Patch Length (DS) mm']"> -->
                                        <span v-if="errors.patch_length_ds" class="invalid-feedback">{{ errors.patch_length_ds[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Patch Width (OS) mm</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Patch Width (OS) mm']}}</span>
                                        <span>{{ artwork_tpmc.patch_width_os}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.patch_width_os }"  class="form-control form-control-sm" v-model="artwork_tpmc['Patch Width (OS) mm']"> -->
                                        <span v-if="errors.patch_width_os" class="invalid-feedback">{{ errors.patch_width_os[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Patch Width (DS) mm</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Patch Width (DS) mm']}}</span>
                                        <span>{{ artwork_tpmc.patch_width_ds}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.patch_width_ds }"  class="form-control form-control-sm" v-model="artwork_tpmc['Patch Width (DS) mm']"> -->
                                        <span v-if="errors.patch_width_ds" class="invalid-feedback">{{ errors.patch_width_ds[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Valve Position</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Valve Position']}}</span>
                                        <span>{{ artwork_tpmc.valve_position}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.valve_position }"  class="form-control form-control-sm" v-model="artwork_tpmc['Valve Position']"> -->
                                        <span v-if="errors.valve_position" class="invalid-feedback">{{ errors.valve_position[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Printing</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Printing']}}</span>
                                        <span>{{ artwork_tpmc.printing}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.printing }"  class="form-control form-control-sm" v-model="artwork_tpmc['Printing']"> -->
                                        <span v-if="errors.printing" class="invalid-feedback">{{ errors.printing[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Lamination Coating GSM</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Lamination Coating GSM']}}</span>
                                        <span>{{ artwork_tpmc.lamination_coating_gsm}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.lamination_coating_gsm }"  class="form-control form-control-sm" v-model="artwork_tpmc['Lamination Coating GSM']"> -->
                                        <span v-if="errors.lamination_coating_gsm" class="invalid-feedback">{{ errors.lamination_coating_gsm[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>15</td>
                                    <td>Fabric GSM</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric GSM']}}</span>
                                        <span>{{ artwork_tpmc.fabric_gsm}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_gsm }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_gsm"> -->
                                        <span v-if="errors.fabric_gsm" class="invalid-feedback">{{ errors.fabric_gsm[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>16</td>
                                    <td>Tape Width mm</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Tape Width mm']}}</span>
                                        <span>{{ artwork_tpmc.tape_width}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.tape_width }"  class="form-control form-control-sm" v-model="artwork_tpmc.tape_width"> -->
                                        <span v-if="errors.tape_width" class="invalid-feedback">{{ errors.tape_width[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>17</td>
                                    <td>Tape Mesh (per inch)</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Tape Mesh (per inch)']}}</span>
                                        <span>{{ artwork_tpmc.tape_mesh}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.tape_mesh }"  class="form-control form-control-sm" v-model="artwork_tpmc.tape_mesh"> -->
                                        <span v-if="errors.tape_mesh" class="invalid-feedback">{{ errors.tape_mesh[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>18</td>
                                    <td>Tape Denier</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Tape Denier']}}</span>
                                        <span>{{ artwork_tpmc.tape_denier}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.tape_denier }"  class="form-control form-control-sm" v-model="artwork_tpmc.tape_denier"> -->
                                        <span v-if="errors.tape_denier" class="invalid-feedback">{{ errors.tape_denier[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>19</td>
                                    <td>Block Perforation</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Block Perforation']}}</span>
                                        <span>{{ artwork_tpmc.block_perforation}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.patch_length_os }" class="form-control form-control-sm" v-model="artwork_tpmc.patch_length_os"> -->
                                        <span v-if="errors.patch_length_os" class="invalid-feedback">{{ errors.patch_length_os[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>20</td>
                                    <td>Microfine Perforation</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Microfine Perforation']}}</span>
                                        <span>{{ artwork_tpmc.microfine_perforation}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.microfine_perforation }"  class="form-control form-control-sm" v-model="artwork_tpmc.microfine_perforation"> -->
                                        <span v-if="errors.microfine_perforation" class="invalid-feedback">{{ errors.microfine_perforation[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>21</td>
                                    <td>Air Permeability</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Air Permeability']}}</span>
                                        <span>{{ artwork_tpmc.air_permeability}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.air_permeability }"  class="form-control form-control-sm" v-model="artwork_tpmc.air_permeability"> -->
                                        <span v-if="errors.air_permeability" class="invalid-feedback">{{ errors.air_permeability[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>22</td>
                                    <td>Fabric Strength (Wrap) Average</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric Strength (Wrap) Average']}}</span>
                                        <span>{{ artwork_tpmc.fabric_strength_wrap_average}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_elongation_break_warp_average }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_elongation_break_warp_average"> -->
                                        <span v-if="errors.fabric_elongation_break_warp_average" class="invalid-feedback">{{ errors.fabric_elongation_break_warp_average[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>23</td>
                                    <td>Fabric Strength (Wrap) Individual</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric Strength (Wrap) Individual']}}</span>
                                        <span>{{ artwork_tpmc.fabric_strength_wrap_individual}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_elongation_break_warp_individual }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_elongation_break_warp_individual"> -->
                                        <span v-if="errors.fabric_elongation_break_warp_individual" class="invalid-feedback">{{ errors.fabric_elongation_break_warp_individual[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>24</td>
                                    <td>Fabric Strength (Weft) Average</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric Strength (Weft) Average']}}</span>
                                        <span>{{ artwork_tpmc.fabric_strength_weft_average}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_strength_weft_average }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_strength_weft_average"> -->
                                        <span v-if="errors.fabric_strength_weft_average" class="invalid-feedback">{{ errors.fabric_strength_weft_average[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>25</td>
                                    <td>Fabric Strength (Weft) Individual</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric Strength (Weft) Individual']}}</span>
                                        <span>{{ artwork_tpmc.fabric_strength_weft_individual}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_strength_weft_individual }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_strength_weft_individual"> -->
                                        <span v-if="errors.fabric_strength_weft_individual" class="invalid-feedback">{{ errors.fabric_strength_weft_individual[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>26</td>
                                    <td>Patch Strength (Top & Bottom) Average</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Patch Strength (Top & Bottom) Average']}}</span>
                                        <span>{{ artwork_tpmc.patch_strength_top_bottom_average}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.patch_strength_top_bottom_average }"  class="form-control form-control-sm" v-model="artwork_tpmc.patch_strength_top_bottom_average"> -->
                                        <span v-if="errors.patch_strength_top_bottom_average" class="invalid-feedback">{{ errors.patch_strength_top_bottom_average[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>27</td>
                                    <td>Patch Strength (Top & Bottom) Individual</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Patch Strength (Top & Bottom) Individual']}}</span>
                                        <span>{{ artwork_tpmc.patch_strength_top_bottom_individual}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.patch_strength_top_bottom_individual }"  class="form-control form-control-sm" v-model="artwork_tpmc.patch_strength_top_bottom_individual"> -->
                                        <span v-if="errors.patch_strength_top_bottom_individual" class="invalid-feedback">{{ errors.patch_strength_top_bottom_individual[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>28</td>
                                    <td>Fabric Elongation at Break (Wrap) Average (%)</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric Elongation at Break (Wrap) Average']}}</span>
                                        <span>{{ artwork_tpmc.fabric_elongation_break_warp_average}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_elongation_break_warp_average }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_elongation_break_warp_average"> -->
                                        <span v-if="errors.fabric_elongation_break_warp_average" class="invalid-feedback">{{ errors.fabric_elongation_break_warp_average[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>29</td>
                                    <td>Fabric Elongation at Break (Wrap) Individul (%)</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric Elongation at Break (Wrap) Individual']}}</span>
                                        <span>{{ artwork_tpmc.fabric_elongation_break_warp_individual}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_elongation_break_warp_individual }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_elongation_break_warp_individual"> -->
                                        <span v-if="errors.fabric_elongation_break_warp_individual" class="invalid-feedback">{{ errors.fabric_elongation_break_warp_individual[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>30</td>
                                    <td>Fabric Elongation at Break (Weft) Average (%)</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric Elongation at Break (Weft) Average']}}</span>
                                        <span>{{ artwork_tpmc.fabric_elongation_break_weft_average}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_elongation_break_weft_average }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_elongation_break_weft_average"> -->
                                        <span v-if="errors.fabric_elongation_break_weft_average" class="invalid-feedback">{{ errors.fabric_elongation_break_weft_average[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>31</td>
                                    <td>Fabric Elongation at Break (Weft) Individual (%)</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Fabric Elongation at Break (Weft) Individual']}}</span>
                                        <span>{{ artwork_tpmc.fabric_elongation_break_weft_individual}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.fabric_elongation_break_weft_individual }"  class="form-control form-control-sm" v-model="artwork_tpmc.fabric_elongation_break_weft_individual"> -->
                                        <span v-if="errors.fabric_elongation_break_weft_individual" class="invalid-feedback">{{ errors.fabric_elongation_break_weft_individual[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>32</td>
                                    <td>Ash Content (max) %</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Ash Content (max) %']}}</span>
                                        <span>{{ artwork_tpmc.ash_content}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.ash_content }"  class="form-control form-control-sm" v-model="artwork_tpmc.ash_content"> -->
                                        <span v-if="errors.ash_content" class="invalid-feedback">{{ errors.ash_content[0] }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>33</td>
                                    <td>Bag Weight (gm)</td>
                                    <td>
                                        <span>{{ artwork_tpmc['Bag Weight (gm)']}}</span>
                                        <span>{{ artwork_tpmc.bag_weight}}</span>
                                        <!-- <input type="text" :disabled="artwork_tpmc.isdisabled" :class="{ 'is-invalid': errors.bag_weight }" class="form-control form-control-sm" v-model="artwork_tpmc.bag_weight"> -->
                                        <span v-if="errors.bag_weight" class="invalid-feedback">{{ errors.bag_weight[0] }}</span>
                                    </td>
                                </tr>                                    
                            </thead>
                        </table>
                        <div class="text-center" v-if="this.$store.getters.user?.role=='TPMC' && artwork_tpmc.table_visible && artwork.artwork_type=='Revised' && artwork.change_in=='artwork' && !artwork.tpmc_submitted_date">
                            <b style="color: #0b0b86;">Note : If no changes in the specs plz click on Submit otherwise edit and upload the revised specs</b>
                        </div>
                    </div>

                    </div>

                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <!-- <span>{{this.$store.getters.user?.role +' '+artwork_tpmc.table_visible+' '+ artwork.artwork_type+' '+artwork.change_in+' '+artwork.tpmc_submitted_date}}</span> -->
                        <button type="button" v-if="this.$store.getters.user?.role=='TPMC' && !artwork_tpmc.table_visible" class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button>
                        <button class="btn btn-outline-success btn-sm" v-if="this.$store.getters.user?.role=='TPMC' && !artwork_tpmc.table_visible" @click="uploadData()">
                            <span> <i class="fas fa-save"></i> Upload Data </span>
                        </button>
                        <button type="button" v-if="this.$store.getters.user?.role=='CPC Maker' && !artwork_tpmc.table_visible" class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button>
                        <button class="btn btn-outline-success btn-sm" v-if="this.$store.getters.user?.role=='CPC Maker' && !artwork_tpmc.table_visible" @click="uploadData()">
                            <span> <i class="fas fa-save"></i> Upload Data </span>
                        </button>
                        <button type="button" v-if="this.$store.getters.user?.role=='CPC Checker' && !artwork_tpmc.table_visible" class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button>
                        <button class="btn btn-outline-success btn-sm" v-if="this.$store.getters.user?.role=='CPC Checker' && !artwork_tpmc.table_visible" @click="uploadData()">
                            <span> <i class="fas fa-save"></i> Upload Data </span>
                        </button>
                        <button type="button" v-if="this.$store.getters.user?.role=='TPMC' && artwork_tpmc.table_visible && !artwork.sku_mapped_date"  class="btn btn-outline-warning btn-sm" @click.prevent="editArtworkTpmc()"><i class="fas fa-edit"></i> Edit</button>
                        <button type="button" v-if="showReviseButton()" class="btn btn-outline-success btn-sm" @click.prevent="reviseArtworkTpmc()"><i class="fa fa-undo"></i> Revise</button>
                        <button type="button" v-if="this.$store.getters.user?.role=='CPC Maker' && artwork_tpmc.table_visible && !artwork.cpc_approved_date" class="btn btn-outline-warning btn-sm" @click.prevent="editArtworkTpmc()"><i class="fas fa-edit"></i> Edit</button>
                        <button type="button" v-if="this.$store.getters.user?.role=='CPC Checker' && artwork_tpmc.table_visible&& !artwork.cpc_approved_date" class="btn btn-outline-warning btn-sm" @click.prevent="editArtworkTpmc()"><i class="fas fa-edit"></i> Edit</button>
                        <!-- <span>{{ artwork_tpmc.table_visible +' '+ artwork.artwork_type +' '+artwork.change_in}}</span> -->
                        <button type="button" v-if="this.$store.getters.user?.role=='TPMC' && artwork_tpmc.table_visible && artwork.artwork_type=='Revised' && artwork.change_in=='artwork' && !artwork.tpmc_submitted_date" class="btn btn-outline-success btn-sm" @click.prevent="addArtworkTpmc()"><i class="fas fa-save"></i> Submit</button>
                        <button type="button" v-if="this.$store.getters.user?.role=='TPMC' && artwork_tpmc.table_visible && artwork_tpmc.update_status" class="btn btn-outline-success btn-sm" @click.prevent="addArtworkTpmc()"><i class="fas fa-save"></i> Submit</button>
                        <button type="button" v-if="this.$store.getters.user?.role=='CPC Maker' && artwork_tpmc.table_visible && artwork_tpmc.update_status" class="btn btn-outline-success btn-sm" @click.prevent="addArtworkTpmc()"><i class="fas fa-save"></i> Submit</button>
                        <button type="button" v-if="this.$store.getters.user?.role=='CPC Checker' && artwork_tpmc.table_visible && artwork_tpmc.update_status" class="btn btn-outline-success btn-sm" @click.prevent="addArtworkTpmc()"><i class="fas fa-save"></i> Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import Versions from '@/components/artworks/Versions.vue'
import Menu from '@/components/artworks/Menu.vue'
import moment from 'moment'
export default {    
    components:{Versions, Menu},

    emits:['specificationTableVisibility', 'updateArtwork'],
    watch:{
        'artwork.bag_capacity':function(){
            if(this.artwork.bag_capacity){
                this.setArtworkTpmc(this.artwork)
            }
            else{
                this.artwork_tpmc.table_visible = false
                this.artwork.specification_excel_name = "Choose excel file"
                this.artwork.specification_pdf_name = "Choose pdf file"
                this.tpmc_submitted_date = moment().format('yyyy-MM-DD')
            }
        },
        '$store.getters.artwork':function(){
            if(this.$store.getters.artwork){
                this.artwork = this.$store.getters.artwork
                this.artwork.specification_excel_name = "Choose excel file"
                this.artwork.specification_pdf_name = "Choose pdf file"
            }
            if(this.artwork.material_code){
                this.setArtworkTpmc(this.artwork)
            }
        },
    },
    data(){
        return{
            role: this.$store.getters.user?.role,
            specification_excel:'',
            specification_excel_name:'',
            artwork_tpmc:{
                artwork_id:'',
                bag_type:'',
                specification_excel_name:'Choose excel file',
                specification_excel :'',
                specification_pdf_name:'Choose pdf file',
                specification_pdf :'',
                bag_capacity : '',
                colour_fabric : '',
                length : '',
                width : '',
                valve_width : '',
                valve_depth : '',
                valve_colour : '',
                patch_length_os : '',
                patch_length_ds : '',
                patch_width_os : '',
                patch_width_ds : '',
                valve_position : '',
                printing : '',
                lamination_coating_gsm : '',
                fabric_gsm : '',
                tape_width : '',
                tape_mesh : '',
                tape_denier : '',
                block_perforation : '',
                microfine_perforation : '',
                air_permeability : '',
                fabric_strength_wrap_average : '',
                fabric_strength_wrap_individual : '',
                fabric_strength_weft_average : '',
                fabric_strength_weft_individual : '',
                patch_strength_top_bottom_average : '',
                patch_strength_top_bottom_individual : '',
                fabric_elongation_break_warp_average : '',
                fabric_elongation_break_warp_individual : '',
                fabric_elongation_break_weft_average : '',
                fabric_elongation_break_weft_individual : '',
                ash_content : '',
                bag_weight : '',
                breaking_load_length_width_average:'',
                breaking_load_length_width_individual:'',
                breaking_load_seam_average:'',
                breaking_load_seam_individual:'',
                load_min_stitching:'',
                strength:'',
                no_of_stitches_per_decimeter:'',
                colour_strip:'',
                stitching_thread:'',
                isdisabled:true,
                update_status:false,
                tpmc_submitted_date:'',
                is_visible:false,
                table_visible:false,
                new_specifications:false
            },
            
            artwork:{},
            versions:[],
            errors:[]
        }
    },
    mounted(){
    },
    created(){

        this.artwork = this.$store.getters.artwork
        this.artwork.specification_excel_name = "Choose excel file"   
        this.artwork.specification_pdf_name = "Choose pdf file"
        this.tpmc_submitted_date = moment().format('yyyy-MM-DD')
        this.setArtworkTpmc(this.artwork)
    },
    methods:{
        showArtwork(artwork_id){
            this.$emit('showArtwork', artwork_id);
        },

        setArtworkTpmc(artwork){
            this.artwork_tpmc = artwork
            this.artwork_tpmc.isdisabled = true
            if(this.artwork_tpmc.bag_capacity){
                this.artwork_tpmc.table_visible = true
            }
            else{
                this.artwork_tpmc.table_visible = false
            }
        },

        getSpecificationExcel(e){
            let vm = this
            vm.artwork_tpmc.specification_excel= e.target.files[0]
            vm.artwork_tpmc.specification_excel_name = e.target.files[0].name
            vm.specification_excel= e.target.files[0]
            vm.specification_excel_name = e.target.files[0].name
        },

        specificationExcelUrl(){
            if(this.artwork?.bag_type?.bag_type == 'PP' || this.artwork?.bag_type?.bag_type == 'RPP')
                return this.$store.getters.assetUrl+'pp_rpp_specifications.xlsx'
            else
                return this.$store.getters.assetUrl+'lpp_bopp_specifications.xlsx'
        },
        
        discard(){
            this.errors = [] 
            this.artwork.specification_excel_name = 'Choose excel file' 
            this.artwork_tpmc.specification_excel  = '' 
            this.artwork.specification_pdf_name = 'Choose pdf file' 
            this.$refs.specification_excel.value = ''
            this.$refs.specification_pdf.value = ''
            this.artwork_tpmc.specification_pdf  = ''
            this.tpmc_submitted_date = moment().format('yyyy-MM-DD')
        },
        getSpecificationExcel(e){
            let vm = this
            vm.artwork.specification_excel= e.target.files[0]
            vm.artwork.specification_excel_name = e.target.files[0].name
        },

        getSpecificationPdf(e){
            let vm = this;
            vm.artwork.specification_pdf= e.target.files[0]
            vm.artwork.specification_pdf_name = e.target.files[0].name
        },
        getArtwork() {
            let vm = this;
            vm.$store.dispatch("setArtwork", null)
            vm.$store.dispatch("post", { uri: "getArtwork", data: vm.artwork })
            .then(function (response) {
                vm.$store.dispatch("setArtwork", response.data.data)
                vm.setArtworkTpmc(response.data.data)
            })
            .catch(function (error) {
                console.log(error)
            });
        },
        uploadData(){
            let vm = this;
            vm.errors = []
            let loader = vm.$loading.show()
            const formData = new FormData()
            vm.artwork.change_in = ''
            formData.append('artwork_id', vm.artwork.artwork_id)
            formData.append('artwork_no', vm.artwork.artwork_no)
            formData.append('bag_type', vm.artwork.bag_type.bag_type)
            if(vm.artwork.specification_excel)
                formData.append('specification_excel', vm.artwork.specification_excel)
            if(vm.artwork.specification_pdf)
                formData.append('specification_pdf', vm.artwork.specification_pdf)
            
            vm.$store.dispatch("post", { uri: "importTpmcExcel", data:formData })
            .then(function (response) {
                loader.hide();
                vm.artwork_tpmc = response.data.data
                vm.artwork_tpmc.isdisabled = true
                vm.artwork_tpmc.table_visible = true
                vm.artwork_tpmc.update_status = true
                let table_visibility = {
                    'table_visible': true,
                    'update_status': true,
                    'new_specifications':false
                }
                vm.$emit('specificationTableVisibility', table_visibility);
            })
            .catch(function (error) {
                loader.hide();
                console.log(error)
                vm.errors = error.response.data.errors
                vm.$store.dispatch("error", error.response.data.message);
            });
        },

        addArtworkTpmc(){
            let vm = this;
            if(vm.artwork.change_in=='artwork'){
                vm.updaArtworkTpmc()
            }else{
                let loader = vm.$loading.show()
                vm.artwork_tpmc.artwork_id = vm.artwork.artwork_id
                const formData = new FormData()
                formData.append('artwork_id', vm.artwork.artwork_id)
                formData.append('artwork_no', vm.artwork.artwork_no)
                formData.append('bag_type', vm.artwork.bag_type.bag_type)
                formData.append('new_specifications', true)
                formData.append('specification_excel', vm.artwork.specification_excel)
                formData.append('specification_pdf', vm.artwork.specification_pdf)
                formData.append('role', vm.$store.getters.user.role)
                if(vm.artwork.bag_type.bag_type == 'LPP' || vm.artwork.bag_type.bag_type == 'BOPP'){
                    formData.append('bag_capacity', vm.artwork_tpmc['Bag Capacity']?vm.artwork_tpmc['Bag Capacity']:'')
                    formData.append('colour_fabric', vm.artwork_tpmc['Fabric Colour']?vm.artwork_tpmc['Fabric Colour']:'')
                    formData.append('length', vm.artwork_tpmc['Length']?vm.artwork_tpmc['Length']:'')
                    formData.append('width', vm.artwork_tpmc['Width']?vm.artwork_tpmc['Width']:'')
                    formData.append('valve_width', vm.artwork_tpmc['Valve Width']?vm.artwork_tpmc['Valve Width']:'')
                    formData.append('valve_depth', vm.artwork_tpmc['Valve Depth']?vm.artwork_tpmc['Valve Depth']:'')
                    formData.append('valve_colour', vm.artwork_tpmc['Valve Colour']?vm.artwork_tpmc['Valve Colour']:'')
                    formData.append('patch_length_os', vm.artwork_tpmc['Patch Length (OS) mm']?vm.artwork_tpmc['Patch Length (OS) mm']:'')
                    formData.append('patch_length_ds', vm.artwork_tpmc['Patch Length (DS) mm']?vm.artwork_tpmc['Patch Length (DS) mm']:'')
                    formData.append('patch_width_os', vm.artwork_tpmc['Patch Width (OS) mm']?vm.artwork_tpmc['Patch Width (OS) mm']:'')
                    formData.append('patch_width_ds', vm.artwork_tpmc['Patch Width (DS) mm']?vm.artwork_tpmc['Patch Width (DS) mm']:'')
                    formData.append('valve_position', vm.artwork_tpmc['Valve Position']?vm.artwork_tpmc['Valve Position']:'')
                    formData.append('printing', vm.artwork_tpmc['Printing']?vm.artwork_tpmc['Printing']:'')
                    formData.append('lamination_coating_gsm', vm.artwork_tpmc['Lamination Coating GSM']?vm.artwork_tpmc['Lamination Coating GSM']:'')
                    formData.append('fabric_gsm', vm.artwork_tpmc['Fabric GSM']?vm.artwork_tpmc['Fabric GSM']:'')
                    formData.append('tape_width', vm.artwork_tpmc['Tape Width mm']?vm.artwork_tpmc['Tape Width mm']:'')
                    formData.append('tape_mesh', vm.artwork_tpmc['Tape Mesh (per inch)']?vm.artwork_tpmc['Tape Mesh (per inch)']:'')
                    formData.append('tape_denier', vm.artwork_tpmc['Tape Denier']?vm.artwork_tpmc['Tape Denier']:'')
                    formData.append('block_perforation', vm.artwork_tpmc['Block Perforation']?vm.artwork_tpmc['Block Perforation']:'')
                    formData.append('microfine_perforation', vm.artwork_tpmc['Microfine Perforation']?vm.artwork_tpmc['Microfine Perforation']:'')
                    formData.append('air_permeability', vm.artwork_tpmc['Air Permeability']?vm.artwork_tpmc['Air Permeability']:'')
                    formData.append('fabric_strength_wrap_average', vm.artwork_tpmc['Fabric Strength (Wrap) Average']?vm.artwork_tpmc['Fabric Strength (Wrap) Average']:'')
                    formData.append('fabric_strength_wrap_individual', vm.artwork_tpmc['Fabric Strength (Wrap) Individual']?vm.artwork_tpmc['Fabric Strength (Wrap) Individual']:'')
                    formData.append('fabric_strength_weft_average', vm.artwork_tpmc['Fabric Strength (Weft) Average']?vm.artwork_tpmc['Fabric Strength (Weft) Average']:'')
                    formData.append('fabric_strength_weft_individual', vm.artwork_tpmc['Fabric Strength (Weft) Individual']?vm.artwork_tpmc['Fabric Strength (Weft) Individual']:'')
                    formData.append('patch_strength_top_bottom_average', vm.artwork_tpmc['Patch Strength (Top & Bottom) Average']?vm.artwork_tpmc['Patch Strength (Top & Bottom) Average']:'')
                    formData.append('patch_strength_top_bottom_individual', vm.artwork_tpmc['Patch Strength (Top & Bottom) Individual']?vm.artwork_tpmc['Patch Strength (Top & Bottom) Individual']:'')
                    formData.append('fabric_elongation_break_warp_average', vm.artwork_tpmc['Fabric Elongation at Break (Wrap) Average']?vm.artwork_tpmc['Fabric Elongation at Break (Wrap) Average']:'')
                    formData.append('fabric_elongation_break_warp_individual', vm.artwork_tpmc['Fabric Elongation at Break (Wrap) Individual']?vm.artwork_tpmc['Fabric Elongation at Break (Wrap) Individual']:'')
                    formData.append('fabric_elongation_break_weft_average', vm.artwork_tpmc['Fabric Elongation at Break (Weft) Average']?vm.artwork_tpmc['Fabric Elongation at Break (Weft) Average']:'')
                    formData.append('fabric_elongation_break_weft_individual', vm.artwork_tpmc['Fabric Elongation at Break (Weft) Individual']?vm.artwork_tpmc['Fabric Elongation at Break (Weft) Individual']:'')
                    formData.append('ash_content', vm.artwork_tpmc['Ash Content (max) %']?vm.artwork_tpmc['Ash Content (max) %']:'')
                    formData.append('bag_weight', vm.artwork_tpmc['Bag Weight (gm)']?vm.artwork_tpmc['Bag Weight (gm)']:'')
                }else{
                    formData.append('bag_capacity', vm.artwork_tpmc['Bag Capacity']?vm.artwork_tpmc['Bag Capacity']:'')
                    formData.append('colour_fabric', vm.artwork_tpmc['Fabric Colour']?vm.artwork_tpmc['Fabric Colour']:'')
                    formData.append('length', vm.artwork_tpmc['Length']?vm.artwork_tpmc['Length']:'')
                    formData.append('width', vm.artwork_tpmc['Width']?vm.artwork_tpmc['Width']:'')
                    formData.append('valve_width', vm.artwork_tpmc['Valve Width']?vm.artwork_tpmc['Valve Width']:'')
                    formData.append('valve_depth', vm.artwork_tpmc['Valve Depth']?vm.artwork_tpmc['Valve Depth']:'')
                    formData.append('valve_type', vm.artwork_tpmc['Valve Type']?vm.artwork_tpmc['Valve Type']:'')
                    formData.append('bag_weight', vm.artwork_tpmc['Bag Weight (gm)']?vm.artwork_tpmc['Bag Weight (gm)']:'')
                    formData.append('ends_and_picks_per_decimeter', vm.artwork_tpmc['Ends and Picks per Decimeter'])
                    formData.append('breaking_load_length_width_average', vm.artwork_tpmc['Breaking Load Length/Width (Average)']?vm.artwork_tpmc['Breaking Load Length/Width (Average)']:'')
                    formData.append('breaking_load_length_width_individual', vm.artwork_tpmc['Breaking Load Length/Width (Individual)']?vm.artwork_tpmc['Breaking Load Length/Width (Individual)']:'')
                    formData.append('breaking_load_seam_average', vm.artwork_tpmc['Breaking Load Seam Bottom/Top (Average)']?vm.artwork_tpmc['Breaking Load Seam Bottom/Top (Average)']:'')
                    formData.append('breaking_load_seam_individual', vm.artwork_tpmc['Breaking Load Seam Bottom/Top (Individual)']?vm.artwork_tpmc['Breaking Load Seam Bottom/Top (Individual)']:'')
                    formData.append('elongation_at_break_of_fabric', vm.artwork_tpmc['Elongation at Break of Fabric (min) %']?vm.artwork_tpmc['Elongation at Break of Fabric (min) %']:'')
                    formData.append('ash_content', vm.artwork_tpmc['Ash Content (max) %']?vm.artwork_tpmc['Ash Content (max) %']:'')
                    formData.append('fabric_gsm', vm.artwork_tpmc['Fabric GSM']?vm.artwork_tpmc['Fabric GSM']:'')
                    formData.append('tape_width', vm.artwork_tpmc['Tape Width']?vm.artwork_tpmc['Tape Width']:'')
                    formData.append('tape_mesh', vm.artwork_tpmc['Tape Mesh']?vm.artwork_tpmc['Tape Mesh']:'')
                    formData.append('tape_denier', vm.artwork_tpmc['Tape Denier']?vm.artwork_tpmc['Tape Denier']:'')
                    formData.append('printing', vm.artwork_tpmc['Printing']?vm.artwork_tpmc['Printing']:'')
                    formData.append('strength', vm.artwork_tpmc['Tape Strength']?vm.artwork_tpmc['Tape Strength']:'')
                    formData.append('load_min_stitching', vm.artwork_tpmc['Load (min) Stitching']?vm.artwork_tpmc['Load (min) Stitching']:'')
                    formData.append('no_of_stitches_per_decimeter', vm.artwork_tpmc['No. of Stitches per Decimeter']?vm.artwork_tpmc['No. of Stitches per Decimeter']:'')
                    formData.append('colour_strip', vm.artwork_tpmc['Colour Strip']?vm.artwork_tpmc['Colour Strip']:'')
                    formData.append('stitching_thread', vm.artwork_tpmc['Stitching Thread Colour']?vm.artwork_tpmc['Stitching Thread Colour']:'')
                }
                vm.$store
                    .dispatch("post", { uri: "updateArtworkTpmc", data:formData })
                .then(function (response) {
                    loader.hide();
                    vm.artwork_tpmc.isdisabled = true
                    vm.artwork_tpmc.table_visible = true
                    vm.getArtwork()
                    vm.errors = []
                    vm.$store.dispatch("success", "Specifications added successfully")
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message)
                });
            }
        },

        updaArtworkTpmc(){
            let vm = this;
            let loader = vm.$loading.show()
            vm.artwork_tpmc.artwork_id = vm.artwork.artwork_id
            const formData = new FormData()
            formData.append('artwork_id', vm.artwork.artwork_id)
            formData.append('artwork_no', vm.artwork.artwork_no)
            formData.append('bag_type', vm.artwork.bag_type.bag_type)
            formData.append('new_specifications', true)
            formData.append('specification_excel', vm.artwork.specification_excel)
            formData.append('specification_pdf', vm.artwork.specification_pdf)
            formData.append('role', vm.$store.getters.user.role)
            if(vm.artwork.bag_type.bag_type == 'LPP' || vm.artwork.bag_type.bag_type == 'BOPP'){
                formData.append('bag_capacity', vm.artwork_tpmc.bag_capacity?vm.artwork_tpmc.bag_capacity:'')
                formData.append('colour_fabric', vm.artwork_tpmc.colour_fabric?vm.artwork_tpmc.colour_fabric:'')
                formData.append('length', vm.artwork_tpmc.length?vm.artwork_tpmc.length:'')
                formData.append('width', vm.artwork_tpmc.width?vm.artwork_tpmc.width:'')
                formData.append('valve_width', vm.artwork_tpmc.valve_width?vm.artwork_tpmc.valve_width:'')
                formData.append('valve_depth', vm.artwork_tpmc.valve_depth?vm.artwork_tpmc.valve_depth:'')
                formData.append('valve_colour', vm.artwork_tpmc.valve_colour?vm.artwork_tpmc.valve_colour:'')
                formData.append('patch_length_os', vm.artwork_tpmc.patch_length_os?vm.artwork_tpmc.patch_length_os:'')
                formData.append('patch_length_ds', vm.artwork_tpmc.patch_length_ds?vm.artwork_tpmc.patch_length_ds:'')
                formData.append('patch_width_os', vm.artwork_tpmc.patch_width_os?vm.artwork_tpmc.patch_width_os:'')
                formData.append('patch_width_ds', vm.artwork_tpmc.patch_width_ds?vm.artwork_tpmc.patch_width_ds:'')
                formData.append('valve_position', vm.artwork_tpmc.valve_position?vm.artwork_tpmc.valve_position:'')
                formData.append('printing', vm.artwork_tpmc.printing?vm.artwork_tpmc.printing:'')
                formData.append('lamination_coating_gsm', vm.artwork_tpmc.lamination_coating_gsm?vm.artwork_tpmc.lamination_coating_gsm:'')
                formData.append('fabric_gsm', vm.artwork_tpmc.fabric_gsm?vm.artwork_tpmc.fabric_gsm:'')
                formData.append('tape_width', vm.artwork_tpmc.tape_width?vm.artwork_tpmc.tape_width:'')
                formData.append('tape_mesh', vm.artwork_tpmc.tape_mesh?vm.artwork_tpmc.tape_mesh:'')
                formData.append('tape_denier', vm.artwork_tpmc.tape_denier?vm.artwork_tpmc.tape_denier:'')
                formData.append('block_perforation', vm.artwork_tpmc.block_perforation?vm.artwork_tpmc.block_perforation:'')
                formData.append('microfine_perforation', vm.artwork_tpmc.microfine_perforation?vm.artwork_tpmc.microfine_perforation:'')
                formData.append('air_permeability', vm.artwork_tpmc.air_permeability?vm.artwork_tpmc.air_permeability:'')
                formData.append('fabric_strength_wrap_average', vm.artwork_tpmc.fabric_strength_wrap_average?vm.artwork_tpmc.fabric_strength_wrap_average:'')
                formData.append('fabric_strength_wrap_individual', vm.artwork_tpmc.fabric_strength_wrap_individual?vm.artwork_tpmc.fabric_strength_wrap_individual:'')
                formData.append('fabric_strength_weft_average', vm.artwork_tpmc.fabric_strength_weft_average?vm.artwork_tpmc.fabric_strength_weft_average:'')
                formData.append('fabric_strength_weft_individual', vm.artwork_tpmc.fabric_strength_weft_individual?vm.artwork_tpmc.fabric_strength_weft_individual:'')
                formData.append('patch_strength_top_bottom_average', vm.artwork_tpmc.patch_strength_top_bottom_average?vm.artwork_tpmc.patch_strength_top_bottom_average:'')
                formData.append('patch_strength_top_bottom_individual', vm.artwork_tpmc.patch_strength_top_bottom_individual?vm.artwork_tpmc.patch_strength_top_bottom_individual:'')
                formData.append('fabric_elongation_break_warp_average', vm.artwork_tpmc.fabric_elongation_break_warp_average?vm.artwork_tpmc.fabric_elongation_break_warp_average:'')
                formData.append('fabric_elongation_break_warp_individual', vm.artwork_tpmc.fabric_elongation_break_warp_individual?vm.artwork_tpmc.fabric_elongation_break_warp_individual:'')
                formData.append('fabric_elongation_break_weft_average', vm.artwork_tpmc.fabric_elongation_break_weft_average?vm.artwork_tpmc.fabric_elongation_break_weft_average:'')
                formData.append('fabric_elongation_break_weft_individual', vm.artwork_tpmc.fabric_elongation_break_weft_individual?vm.artwork_tpmc.fabric_elongation_break_weft_individual:'')
                formData.append('ash_content', vm.artwork_tpmc.ash_content?vm.artwork_tpmc.ash_content:'')
                formData.append('bag_weight', vm.artwork_tpmc.bag_weight?vm.artwork_tpmc.bag_weight:'')
            }else{
                formData.append('bag_capacity', vm.artwork_tpmc.bag_capacity?vm.artwork_tpmc.bag_capacity:'')
                formData.append('colour_fabric', vm.artwork_tpmc.colour_fabric?vm.artwork_tpmc.colour_fabric:'')
                formData.append('length', vm.artwork_tpmc.length?vm.artwork_tpmc.length:'')
                formData.append('width', vm.artwork_tpmc.width?vm.artwork_tpmc.width:'')
                formData.append('valve_width', vm.artwork_tpmc.valve_width?vm.artwork_tpmc.valve_width:'')
                formData.append('valve_depth', vm.artwork_tpmc.valve_depth?vm.artwork_tpmc.valve_depth:'')
                formData.append('valve_type', vm.artwork_tpmc.valve_type?vm.artwork_tpmc.valve_type:'')
                formData.append('bag_weight', vm.artwork_tpmc.bag_weight?vm.artwork_tpmc.bag_weight:'')
                formData.append('ends_and_picks_per_decimeter', vm.artwork_tpmc.ends_and_picks_per_decimeter)
                formData.append('breaking_load_length_width_average', vm.artwork_tpmc.breaking_load_length_width_average?vm.artwork_tpmc.breaking_load_length_width_average:'')
                formData.append('breaking_load_length_width_individual', vm.artwork_tpmc.breaking_load_length_width_individual?vm.artwork_tpmc.breaking_load_length_width_individual:'')
                formData.append('breaking_load_seam_average', vm.artwork_tpmc.breaking_load_seam_average?vm.artwork_tpmc.breaking_load_seam_average:'')
                formData.append('breaking_load_seam_individual', vm.artwork_tpmc.breaking_load_seam_individual?vm.artwork_tpmc.breaking_load_seam_individual:'')
                formData.append('elongation_at_break_of_fabric', vm.artwork_tpmc.elongation_at_break_of_fabric?vm.artwork_tpmc.elongation_at_break_of_fabric:'')
                formData.append('ash_content', vm.artwork_tpmc.ash_content?vm.artwork_tpmc.ash_content:'')
                formData.append('fabric_gsm', vm.artwork_tpmc.fabric_gsm?vm.artwork_tpmc.fabric_gsm:'')
                formData.append('tape_width', vm.artwork_tpmc.tape_width?vm.artwork_tpmc.tape_width:'')
                formData.append('tape_mesh', vm.artwork_tpmc.tape_mesh?vm.artwork_tpmc.tape_mesh:'')
                formData.append('tape_denier', vm.artwork_tpmc.tape_denier?vm.artwork_tpmc.tape_denier:'')
                formData.append('printing', vm.artwork_tpmc.printing?vm.artwork_tpmc.printing:'')
                formData.append('strength', vm.artwork_tpmc.strength?vm.artwork_tpmc.strength:'')
                formData.append('load_min_stitching', vm.artwork_tpmc.load_min_stitching?vm.artwork_tpmc.load_min_stitching:'')
                formData.append('no_of_stitches_per_decimeter', vm.artwork_tpmc.no_of_stitches_per_decimeter?vm.artwork_tpmc.no_of_stitches_per_decimeter:'')
                formData.append('colour_strip', vm.artwork_tpmc.colour_strip?vm.artwork_tpmc.colour_strip:'')
                formData.append('stitching_thread', vm.artwork_tpmc.stitching_thread?vm.artwork_tpmc.stitching_thread:'')
            }
            vm.$store
                .dispatch("post", { uri: "updateArtworkTpmc", data:formData })
            .then(function (response) {
                loader.hide();
                vm.artwork_tpmc.isdisabled = true
                vm.artwork_tpmc.table_visible = true
                vm.getArtwork()
                vm.errors = []
                vm.errors = []
                vm.$store.dispatch("success", "Specifications added successfully")
            })
            .catch(function (error) {
                loader.hide();
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message)
            });
        },

        editArtworkTpmc(){
            this.artwork_tpmc.update_status = false
            this.artwork_tpmc.table_visible = false
            this.artwork.specification_excel_name = "Choose excel file"
            this.artwork.specification_pdf_name = "Choose pdf file"
            // this.artwork_tpmc.isdisabled = false
            // console.log(this.artwork_tpmc)
            // if(this.artwork_tpmc.artwork_id)
            //     this.artwork_tpmc.update_status = true
            // else    
            //     this.artwork_tpmc.update_status = false
        },

        generateArtworkNo(){
            let version_number = this.artwork.artwork_no.split("_")[3].split("")
            this.artwork.artwork_no =  this.artwork.artwork_no.split("_")[0]+'_'+this.artwork.artwork_no.split("_")[1]+'_'+this.artwork.artwork_no.split("_")[2]+'_'+version_number[0]+''+(parseInt(version_number[1])+1)
        },

        reviseArtworkTpmc(){
            if(confirm('Are you sure you want to revise artwork?')){
                let vm = this;
                if(!vm.artwork.reference_id){
                    vm.artwork.reference_id = vm.artwork.artwork_id
                }
                vm.artwork.parent_artwork_no = vm.artwork.artworks_no
                vm.generateArtworkNo()
                let loader = vm.$loading.show()
                vm.$store
                    .dispatch("post", { uri: "reviseArtworkSpecification", data:vm.artwork })
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("setArtwork", response.data.data)
                        vm.artwork_tpmc.table_visible = false
                        vm.setArtworkVersions()
                    })
                    .catch(function (error) {
                        loader.hide();
                        console.log(error)
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message)
                    });
            }
        },

        setArtworkVersions(){
            let vm = this
            vm.versions = []
            let artwork = this.$store.getters.artwork
            if(artwork.children?.length){
                vm.versions = artwork.children
                    vm.versions.unshift({
                    artwork_id : artwork.artwork_id,
                    artwork_no : artwork.artwork_no,
                    artwork_date : artwork.artwork_date,
                    artwork_type : artwork.artwork_type,
                    plant_id : artwork.plant_id,
                    bag_type_id : artwork.bag_type_id,
                    material_id : artwork.material_id,
                    language_id : artwork.language_id,
                    bis_approval_date : artwork.bis_approval_date,
                    artwork_pdf_name : artwork.artwork_pdf,
                    artwork_image_name : artwork.artwork_image,
                    artwork_ai_name : artwork.artwork_ai,
                    cpc_approved_date : artwork.cpc_approved_date,
                    next_cpc_approved_date: artwork.next_cpc_approved_date
                })
            }else if(artwork.parent?.children.length){
                vm.versions = artwork.parent.children
                    vm.versions.unshift({
                    artwork_id : artwork.parent.artwork_id,
                    artwork_no : artwork.parent.artwork_no,
                    artwork_date : artwork.parent.artwork_date,
                    artwork_type : artwork.parent.artwork_type,
                    plant_id : artwork.parent.plant_id,
                    bag_type_id : artwork.parent.bag_type_id,
                    material_id : artwork.parent.material_id,
                    language_id : artwork.parent.language_id,
                    bis_approval_date : artwork.parent.bis_approval_date,
                    artwork_pdf_name : artwork.parent.artwork_pdf,
                    artwork_image_name : artwork.parent.artwork_image,
                    artwork_ai_name : artwork.parent.artwork_ai,
                    cpc_approved_date: artwork.parent.cpc_approved_date,
                    next_cpc_approved_date: artwork.parent.next_cpc_approved_date
                })
            }else{
                vm.versions.unshift({
                    artwork_id : artwork.artwork_id,
                    artwork_no : artwork.artwork_no,
                    artwork_date : artwork.artwork_date,
                    artwork_type : artwork.artwork_type,
                    plant_id : artwork.plant_id,
                    bag_type_id : artwork.bag_type_id,
                    material_id : artwork.material_id,
                    language_id : artwork.language_id,
                    bis_approval_date : artwork.bis_approval_date,
                    artwork_pdf_name : artwork.artwork_pdf,
                    artwork_image_name : artwork.artwork_image,
                    artwork_ai_name : artwork.artwork_ai,
                    cpc_approved_date : artwork.cpc_approved_date,
                    next_cpc_approved_date: artwork.next_cpc_approved_date
                })
            }
            vm.$store.dispatch("setVersions", vm.versions);
        },

        showReviseButton(){
            let revise_button = false
            if(this.$store.getters.versions.length){
                let last_artwork = this.$store.getters.versions[this.$store.getters.versions.length-1]
                if(this.artwork.artwork_no == last_artwork.artwork_no)
                    revise_button = true
                else
                    revise_button = false
            }else{
                revise_button = false
            }
            if(this.role == 'TPMC' && this.artwork_tpmc.table_visible && this.artwork.revise && revise_button)
                return true
            else return false
        },

        specificationTableVisibility(table_visibility){
            this.artwork_tpmc = table_visibility
            this.updateVisibility()
        },

        updateVisibility(){
            let role = this.$store.getters.user.role
            if(role == 'TPMC'){
                this.artwork_tpmc.is_visible = true
                this.cpcm.is_visible = false
            }
            else if(role == 'CPC Maker' && this.artwork.tpmc_submitted_date){
                this.artwork_tpmc.is_visible = true
                this.cpcm.is_visible = true
            }else if(role== 'CPC Checker' && this.artwork.tpmc_submitted_date && !this.artwork.sku_mapped_date){
                this.artwork_tpmc.is_visible = true
                this.cpcm.is_visible = false
                this.cpcc.is_visible = false
            }else if(role== 'CPC Checker' && this.artwork.sku_mapped_date){
                this.artwork_tpmc.is_visible = true
                this.cpcm.is_visible = true
                this.cpcc.is_visible = true
            }else{
                this.artwork_tpmc.is_visible = false
                this.cpcm.is_visible = false
                this.cpcc.is_visible = false
            }
        },
    }
}
</script>