<template>
    <div class="login-page" v-if="consent_status">
        <div class="login-box">
            <div class="card card-outline card-primary">
                <div class="card-header text-center">
                    <a href="" class="h1">
                        <img src="@/assets/ultratech-cement-logo.png" style="width: 100px;" />
                    </a>
                    <h4 style="margin-bottom: 0px;">Artwork</h4>
                    <span>Management Tool</span>
                </div>
                <div class="card-body">
                    <p class="login-box-msg">Sign in to start your session</p>
                    <form autocomplete="off" @submit.prevent="submit">
                        <div class="form-group mb-3">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <span class="fas fa-user"></span>
                                    </div>
                                </div>
                                <input type="text" class="form-control" :class="{'is-invalid': errors.username}" placeholder="Enter your username" v-model="user.username" ref="username" tabindex="1" />
                                <span v-if="errors.username" class="invalid-feedback">{{ errors.username[0] }}</span>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <span class="fas fa-lock"></span>
                                    </div>
                                </div>
                                <input type="password" class="form-control" :class="{'is-invalid': errors.password}" placeholder="Password" v-model="user.password" ref="password" tabindex="2" />
                                <span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <button method="submit" class="btn btn-primary btn-block">Sign In</button>
                            <router-link class="text-center" to="/forgot_password">
                                <p>Forgot Password ?</p>
                            </router-link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="login-page1 py-4" v-else>
        <div class="login-box1">
            <div class="card card-outline card-primary">
                <div class="card-header text-center">
                    <a href="" class="h1">
                        <img class="mb-2" src="@/assets/ultratech-cement-logo.png" style="width: 70px;" />
                    </a>
                    <h4 style="margin-bottom: 0px;">Artwork</h4>
                    <span>Management Tool</span>
                </div>
                <div class="card-body pt-3">
                    <h6 class="login-box-msg pb-1"><strong>CONSENT FORM</strong></h6>
                    <p class="text-align-justify">
                        UltraTech Cement (“UltraTech”) shall collect, use, store, transfer, or otherwise process your Personal Data such as [name, contact details, email id etc.] [Note: Please insert the exact details to be collected by
                        UltraTech] for the purposes of [], (“Permitted Purpose”).
                    </p>
                    <p class="text-align-justify">
                        If you wish to withdraw/rescind your consent to the usage of your Personal Data for the Permitted Purpose, you may do so by clicking on [] or informing [] at [] [Note: Please insert the link or name and email id].
                        Upon withdrawal of your consent, UltraTech shall cease all processing of your Personal Data with respect to the Permitted Purpose.
                    </p>
                    <p class="text-align-justify">
                        You can review and update your Personal Data provided to UltraTech by sending an email to [] at [] or click on [] [Note: Please insert name and email id or the link].
                    </p>
                    <p class="text-align-justify">
                        <span><input type="checkbox" class="chkhgt mr-2" v-model="user.consent" :value="user.consent" /></span>I, the undersigned, have read the Consent Form above and fully understand contents thereof. I do hereby consent
                        to UltraTech, its affiliates and service providers to collect, store, use and process my Personal Data for the Permitted Purpose in the manner set out above.
                    </p>
                </div>
                <div class="card-footer text-right">
                    <button type="button" :disabled="!user.consent" class="btn btn-sm btn-primary" @click="addConsent">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                user: {
                    username: "",
                    password: "",
                    device: "Web",
                    consent: false,
                    user_id: "",
                },
                consent_status: true,
                status: true,
                errors: [],
            };
        },
        mounted() {
            this.$refs.username.focus();
        },
        methods: {
            submit() {
                if (this.status == true) {
                    this.login();
                } else {
                    this.verifyOtp();
                }
            },
            verifyOtp() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("auth", { uri: "verifyOtp", data: vm.user })
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("setUser", response.data.user);
                        vm.$store.dispatch("setToken", response.data.token);
                        vm.$router.push("/");
                    })
                    .catch(function (error) {
                        loader.hide();

                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            login() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("auth", { uri: "login", data: vm.user })
                    .then(function (response) {
                        loader.hide();
                        vm.user = response.data;
                        // if (response.data.user.consent == null || response.data.user.consent.consent == 0) {
                        //     vm.consent_status = false;
                        // } else {
                            vm.$store.dispatch("setUser", response.data.user);
                            vm.$store.dispatch("setToken", response.data.token);
                            vm.$router.push("/");
                        // }
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addConsent() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.user.user_id = vm.user.user.user_id;
                vm.$store
                    .dispatch("auth", { uri: "addConsent", data: vm.user })
                    .then(function (response) {
                        loader.hide();
                        vm.user.user.consent = response.data.consent;
                        if (response.data.consent?.consent) {
                            vm.$store.dispatch("setUser", vm.user.user);
                            vm.$store.dispatch("setToken", vm.user.token);
                            vm.$router.push("/");
                        }
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>
<style scoped>
    .login-page1 {
        align-items: center;
        background-color: #e9ecef;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .login-box1 {
        width: 600px;
    }
</style>
