<template>
    <div class="d-flex justify-content-between">
        <h5 class="mb-2">Dashboard</h5>
        <div class="custom-control custom-switch" v-if="role == 'CPC Maker'">
            <input type="checkbox" class="custom-control-input" @change="filterArchived()" id="archive_switch"
                v-model="meta.archived">
            <label class="custom-control-label" for="archive_switch">Archived</label>
        </div>
    </div>

    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Artworks</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label>From Date:</label>
                        <div class="input-group date">
                            <input type="date" :max="meta.to_date || today" class="form-control form-control-sm"
                                v-model="meta.from_date" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label>To Date:</label>
                        <div class="input-group date">
                            <input type="date" :min="meta.from_date" :max="today" class="form-control form-control-sm"
                                v-model="meta.to_date" />
                        </div>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label>Artwork Type</label>
                        <select class="form-control form-control-sm" v-model="meta.artwork_type" @change="search()">
                            <option value="">Select Artwork</option>
                            <option value="New">New</option>
                            <option value="Revised">Revised</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label>Languages</label>
                        <search :class="{ 'is-invalid': errors.language_id }"
                            :customClass="{ 'is-invalid': errors.language_id }" :initialize="meta.language_id"
                            id="language_id" label="language" placeholder="Select Language" :data="languages"
                            @input="selectLanguage($event)">
                        </search>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label>Bag Types </label>
                        <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.bag_type_id }"
                            v-model="meta.bag_type_id" @change.prevent="getMaterials()">
                            <option value="">Select Bag Type</option>
                            <option v-for="(bagtype, key) in bagtypes" :key="key" :value="bagtype.bag_type_id">
                                {{ bagtype.bag_type }}</option>
                        </select>
                        <span v-if="errors.bag_type_id" class="invalid-feedback">{{ errors.bag_type_id[0] }}</span>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label>Materials</label>
                        <search :class="{ 'is-invalid': errors.material_id }"
                            :customClass="{ 'is-invalid': errors.material_id }" :initialize="meta.material_id"
                            id="material_id" label="material" placeholder="Select Material" :data="materials"
                            @input="selectMaterail($event)">
                        </search>
                    </div>
                </div>
                <div class="col-sm-2">
                    <label>Cluster</label>
                    <select class="form-control form-control-sm" v-model="meta.cluster_id" @change="changeCluster()">
                        <option value="">Select Cluster</option>
                        <option v-for="cluster, key in clusters" :key="key" :value="cluster.cluster_id">{{
                            cluster.cluster }}</option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Plants</label>
                        <search :class="{ 'is-invalid': errors.plant_id }"
                            :customClass="{ 'is-invalid': errors.plant_id }" :initialize="meta.plant_id" id="plant_id"
                            label="plant_name" placeholder="Select Plant" :data="plants" @input="selectPlant($event)">
                        </search>
                        <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label>Material Code</label>
                        <search :class="{ 'is-invalid': errors.material_code }"
                            :customClass="{ 'is-invalid': errors.material_code }" :initialize="meta.material_code"
                            id="material_code" label="material_code" placeholder="Select Material Code"
                            :data="material_codes" @input="selectMaterialCode($event)">
                        </search>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="form-group">
                        <label>Artwork Stage</label>
                        <select class="form-control form-control-sm" v-model="meta.stage" @change="search()"
                            :disabled="is_stage_disabled">
                            <option value="">Select Artwork Stage</option>
                            <option value="TPMC">TPMC</option>
                            <option value="CPC Maker">CPC Maker</option>
                            <option value="CPC Checker">CPC Checker</option>
                            <option value="Vendor">Vendor</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div style="text-align: right;margin-top:25px">
                        <div class="btn-group" v-if="role == 'TS'">
                            <a class="btn btn-info btn-sm" :href="getArtworkReportUrl()"><i class="fa fa-download"
                                    aria-hidden="true"></i>Report</a>
                        </div>
                        <div class="btn-group" v-if="role == 'TPMC'">
                            <button type="button" class="btn btn-info btn-sm dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-download" aria-hidden="true"></i> Reports
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" :href="getPPSpecificationUrl()">Specification PP/RPP</a>
                                <a class="dropdown-item" :href="getLPPBOPPSpecificationUrl()">Specification LPP/BOPP</a>
                                <a class="dropdown-item" :href="getArtworkReportUrl()">Artwork</a>
                            </div>
                        </div>
                        <div class="btn-group" v-if="role == 'CPC Maker' || role == 'CPC Checker'">
                            <button type="button" class="btn btn-info btn-sm dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-download" aria-hidden="true"></i> Reports
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" :href="getPPSpecificationUrl()">Specification PP/RPP</a>
                                <a class="dropdown-item" :href="getLPPBOPPSpecificationUrl()">Specification LPP/BOPP</a>
                                <a class="dropdown-item" :href="getAcknowledgementReportUrl()">Acknowledgement</a>
                                <a class="dropdown-item" :href="getArtworkReportUrl()">Artwork</a>
                            </div>
                        </div>
                        <div class="btn-group" v-if="role == 'CPC User'">
                            <button type="button" class="btn btn-info btn-sm dropdown-toggle" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-download" aria-hidden="true"></i> Reports
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" :href="getAcknowledgementReportUrl()">Acknowledgement</a>
                                <a class="dropdown-item" :href="getArtworkReportUrl()">Artwork</a>
                                <a class="dropdown-item" :href="getPPSpecificationUrl()">Specification PP/RPP</a>
                                <a class="dropdown-item" :href="getLPPBOPPSpecificationUrl()">Specification LPP/BOPP</a>
                            </div>
                        </div>
                        <button class="btn btn-danger btn-sm" @click="resetFilters()"><i class="fa fa-undo"
                                aria-hidden="true"></i> Reset</button>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th @click="sort('artwork_no')">Artwork No &emsp;<i :class="getSortIcon('artwork_no')"></i>
                            </th>
                            <th @click="sort('artwork_date')">Artwork Date &emsp;<i
                                    :class="getSortIcon('artwork_date')"></i></th>
                            <th @click="sort('artwork_type')">Artwork Type &emsp;<i
                                    :class="getSortIcon('artwork_type')"></i></th>
                            <th @click="sort('plant_name')">Plant &emsp;<i :class="getSortIcon('plant_name')"></i></th>
                            <th @click="sort('bag_type')">Bag Type &emsp;<i :class="getSortIcon('bag_type')"></i></th>
                            <th @click="sort('material')">Material &emsp;<i :class="getSortIcon('material')"></i></th>
                            <th @click="sort('material_code')">Material Code &emsp;<i
                                    :class="getSortIcon('material_code')"></i></th>
                            <th @click="sort('language')">Language &emsp;<i :class="getSortIcon('language')"></i></th>
                            <th class="text-center">Stage </th>
                            <th class="text-center" v-if="role == 'CPC Maker'">Archived </th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="artworks.length == 0">
                            <td colspan="12" class="text-center">No records found</td>
                        </tr>
                        <tr v-for="(artwork, key) in artworks" :key="key">
                            <td>{{ meta.from + key }}</td>
                            <td>{{ artwork?.artwork_no }}</td>
                            <td>{{ convertDateFormat(artwork?.artwork_date) }}</td>
                            <td>{{ artwork?.artwork_type }}</td>
                            <td>{{ artwork?.plant?.plant_name }}</td>
                            <td>{{ artwork?.bag_type?.bag_type }}</td>
                            <td v-if="artwork.cpc_approved_date">{{ artwork?.contract.grade }}</td>
                            <td v-else>{{ artwork?.material?.material }}</td>
                            <td>{{ artwork.material_code }}</td>
                            <td>{{ artwork?.language?.language }}</td>
                            <td class="text-center">{{ artwork?.stage?.stage }}</td>
                            <td class="text-center" style="vertical-align: middle;" v-if="role == 'CPC Maker'">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input"
                                        :id="'archived_' + artwork.artwork_id" v-model="artwork.archive"
                                        @change="updateArchive(artwork)">
                                    <label class="custom-control-label" :for="'archived_' + artwork.artwork_id"></label>
                                </div>
                            </td>
                            <td class="text-center py-0 align-middle">
                                <button class="btn btn-info btn-sm" @click.prevent="showArtwork(artwork)"><i
                                        class="fas fa-eye"></i></button>
                                <!-- <div>
                                    <a href="javascript:void(0)" class="btn btn-info btn-sm" @click.prevent="edit(artwork)"><i class="fas fa-eye"></i></a>
                                    <a href="#" class="btn btn-danger"><i class="fas fa-trash"></i></a>
                                </div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <div class="float-right">
                <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page"
                    @pagechanged="onPageChange" />
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import search from "@/components/Search.vue";
import moment from "moment";
export default {
    components: { Pagination, search },
    data() {
        return {
            clusters:'',
            today: '',
            role: this.$store.getters.user?.role,
            status: true,
            artworks: [],
            plants: [],
            bagtypes: [],
            materials: [],
            languages: [],
            material_codes: [],
            material: {
                material_type_id: "",
                material_type: "",
                plant_id: "",
                bag_type_id: "",
                description: "",
            },
            reports: {
                specification_pp: '',
                specification_lpp_bopp: '',
                acknowledgement_report: '',
                artwork_report: ''
            },
            meta: {
                search: '',
                order_by: "desc",
                keyword: "artwork_id",
                per_page: 10,
                totalRows: 0,
                page: 1,
                lastPage: 1,
                from: 1,
                maxPage: 1,
                trashed: false,
                from_date: '',
                to_date: '',
                artwork_id: '',
                cluster_id: '',
                plant_id: '',
                artwork_type: '',
                bag_type_id: '',
                material_id: '',
                language_id: '',
                reference: false,
                vendor_code: '',
                material_code: '',
                stage: '',
                archived: false
            },
            errors: [],
            is_stage_disabled: '',
            versions: []
        };
    },
    watch: {
        'meta.from_date': function () {
            if (this.meta.to_date) {
                this.meta.page = 1
                this.index()
            }
        },
        'meta.to_date': function () {
            if (this.meta.from_date) {
                this.meta.page = 1;
                this.index()
            }
        },
        'meta.bag_type_id': function () {
            this.index()
        }

    },
    mounted() {
        this.today = moment().format('yyyy-MM-DD')
        this.meta.vendor_code = this.$store.getters.user?.vendor?.vendor_code
        if (this.role == 'MM' || this.role == 'TPIA' || this.role == 'Vendor' || this.role == 'QC') {
            this.meta.stage = 'Vendor'
            this.is_stage_disabled = true
        } else if (this.role == 'CPC User') {
            this.meta.stage = ''
            this.is_stage_disabled = false
        } else if (this.role == 'TS') {
            this.meta.stage = ''
            this.is_stage_disabled = false
        } else {
            this.meta.stage = this.role
            this.is_stage_disabled = false
        }
        this.index()
        this.getMaterialCodes()
    },
    methods: {

        getMaterialCodes() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getUniqueMaterialCodes" })
                .then(function (response) {
                    vm.material_codes = response.data;
                    vm.getClusters()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getClusters() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getClusters" })
                .then(function (response) {
                    vm.clusters = response.data.data;
                    vm.getPlants()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPPSpecificationUrl() {
            return this.$store.getters.webUrl + 'specificationSummaryPP?from_date=' + this.meta.from_date + '&to_date=' + this.meta.to_date + '&cluster_id=' + this.meta.cluster_id + '&plant_id=' + this.meta.plant_id + '&material_id=' + this.meta.material_id + '&artwork_type=' + this.meta.artwork_type + '&language_id=' + this.meta.language_id + '&stage=' + this.meta.stage + '&material_code=' + this.meta.material_code
        },
        getLPPBOPPSpecificationUrl() {
            return this.$store.getters.webUrl + 'specificationSummaryLPPBOPP?from_date=' + this.meta.from_date + '&to_date=' + this.meta.to_date + '&cluster_id=' + this.meta.cluster_id + '&plant_id=' + this.meta.plant_id + '&material_id=' + this.meta.material_id + '&artwork_type=' + this.meta.artwork_type + '&language_id=' + this.meta.language_id + '&stage=' + this.meta.stage + '&material_code=' + this.meta.material_code
        },
        getAcknowledgementReportUrl() {
            return this.$store.getters.webUrl + 'acknowledgementReport?from_date=' + this.meta.from_date + '&to_date=' + this.meta.to_date + '&cluster_id=' + this.meta.cluster_id + '&plant_id=' + this.meta.plant_id + '&bag_type_id=' + this.meta.bag_type_id + '&material_id=' + this.meta.material_id + '&artwork_type=' + this.meta.artwork_type + '&language_id=' + this.meta.language_id + '&stage=' + this.meta.stage + '&material_code=' + this.meta.material_code
        },
        getArtworkReportUrl() {
            return this.$store.getters.webUrl + 'artworkReport?from_date=' + this.meta.from_date + '&to_date=' + this.meta.to_date + '&cluster_id=' + this.meta.cluster_id + '&plant_id=' + this.meta.plant_id + '&bag_type_id=' + this.meta.bag_type_id + '&material_id=' + this.meta.material_id + '&artwork_type=' + this.meta.artwork_type + '&language_id=' + this.meta.language_id + '&stage=' + this.meta.stage + '&material_code=' + this.meta.material_code
        },
        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },
        getSortIcon(field) {
            if (this.meta.keyword == field && this.meta.order_by == 'asc')
                return 'fas fa-sort-up'
            else if (this.meta.keyword == field && this.meta.order_by == 'desc')
                return 'fas fa-sort-down'
            else return 'fas fa-sort'
        },
        resetFilters() {
            this.meta.search = '',
                this.meta.order_by = 'desc',
                this.meta.keyword = 'artwork_id',
                this.meta.per_page = 10,
                this.meta.totalRows = 0,
                this.meta.material_code = '',
                this.meta.page = 1,
                this.meta.lastPage = 1,
                this.meta.from = 1,
                this.meta.maxPage = 1,
                this.meta.trashed = false,
                this.meta.from_date = '',
                this.meta.to_date = '',
                this.meta.cluster_id = '',
                this.meta.plant_id = '',
                this.meta.artwork_type = '',
                this.meta.bag_type_id = '',
                this.meta.material_id = '',
                this.meta.language_id = '',
                this.meta.reference = false,
                this.meta.stage = '',
                this.meta.archived = false;
            this.getPlants()
            this.getMaterials()
            if (this.role == 'MM' || this.role == 'TPIA' || this.role == 'Vendor' || this.role == 'QC') {
                this.meta.stage = 'Vendor'
                this.is_stage_disabled = true
            } else if (this.role == 'TS') {
                this.meta.stage = ''
                this.is_stage_disabled = false
            } else {
                this.meta.stage = this.role
                this.is_stage_disabled = false
            }
            this.index()
        },
        showArtwork(artwork) {
            this.getArtwork(artwork)
        },

        getArtwork(artwork) {
            let vm = this;
            vm.$store.dispatch("setArtwork", null)
            vm.$store.dispatch("post", { uri: "getArtwork", data: artwork })
                .then(function (response) {
                    vm.$store.dispatch("setArtwork", response.data.data)
                    vm.$store.dispatch("setTab", 'ts')
                    vm.$router.push('artwork/' + artwork.artwork_id + '/show');
                    vm.setArtworkVersions()
                })
                .catch(function (error) {
                });
        },

        setArtworkVersions() {
            let vm = this
            vm.versions = []
            let artwork = this.$store.getters.artwork
            if (this.$store.getters.user.role == 'Vendor' || this.$store.getters.user.role == 'MM' || this.$store.getters.user.role == 'QC' || this.$store.getters.user.role == 'TPIA' || this.$store.getters.user.role == 'CPC User') {
                if (!vm.versions.length)
                    vm.versions.push({
                        artwork_id: artwork.artwork_id,
                        artwork_no: artwork.artwork_no,
                        artwork_date: artwork.artwork_date,
                        artwork_type: artwork.artwork_type,
                        plant_id: artwork.plant_id,
                        bag_type_id: artwork.bag_type_id,
                        material_id: artwork.material_id,
                        language_id: artwork.language_id,
                        bis_approval_date: artwork.bis_approval_date,
                        artwork_pdf_name: artwork.artwork_pdf,
                        artwork_image_name: artwork.artwork_image,
                        artwork_ai_name: artwork.artwork_ai,
                        cpc_approved_date: artwork.cpc_approved_date,
                        next_cpc_approved_date: artwork.next_cpc_approved_date
                    })
            } else {
                if (artwork.parent?.children.length) {
                    let version_exists = vm.versions.filter(function (element) {
                        element.artwork_id == artwork.parent.artwork_id
                    })
                    if (!version_exists.length) {
                        vm.versions = artwork.parent.children
                        if (vm.role == 'TS' || vm.role == 'TPMC' || vm.role == 'CPC Maker' || vm.role == 'CPC Checker') {
                            vm.versions.unshift({
                                artwork_id: artwork.parent.artwork_id,
                                artwork_no: artwork.parent.artwork_no,
                                artwork_date: artwork.parent.artwork_date,
                                artwork_type: artwork.parent.artwork_type,
                                plant_id: artwork.parent.plant_id,
                                bag_type_id: artwork.parent.bag_type_id,
                                material_id: artwork.parent.material_id,
                                language_id: artwork.parent.language_id,
                                bis_approval_date: artwork.parent.bis_approval_date,
                                artwork_pdf_name: artwork.parent.artwork_pdf,
                                artwork_image_name: artwork.parent.artwork_image,
                                artwork_ai_name: artwork.parent.artwork_ai,
                                cpc_approved_date: artwork.parent.cpc_approved_date,
                                next_cpc_approved_date: artwork.parent.next_cpc_approved_date
                            })
                        }
                    }
                } else if (artwork.children?.length) {
                    vm.versions = artwork.children
                    let version_exists = vm.versions.filter(function (element) {
                        element.artwork_id == artwork.artwork_id
                    })
                    if (!version_exists.length) {
                        vm.versions.unshift({
                            artwork_id: artwork.artwork_id,
                            artwork_no: artwork.artwork_no,
                            artwork_date: artwork.artwork_date,
                            artwork_type: artwork.artwork_type,
                            plant_id: artwork.plant_id,
                            bag_type_id: artwork.bag_type_id,
                            material_id: artwork.material_id,
                            language_id: artwork.language_id,
                            bis_approval_date: artwork.bis_approval_date,
                            artwork_pdf_name: artwork.artwork_pdf,
                            artwork_image_name: artwork.artwork_image,
                            artwork_ai_name: artwork.artwork_ai,
                            cpc_approved_date: artwork.cpc_approved_date,
                            next_cpc_approved_date: artwork.next_cpc_approved_date
                        })
                    }
                } else {
                    let version_exists = vm.versions.filter(function (element) {
                        element.artwork_id == artwork.artwork_id
                    })
                    if (!version_exists.length) {
                        vm.versions.unshift({
                            artwork_id: artwork.artwork_id,
                            artwork_no: artwork.artwork_no,
                            artwork_date: artwork.artwork_date,
                            artwork_type: artwork.artwork_type,
                            plant_id: artwork.plant_id,
                            bag_type_id: artwork.bag_type_id,
                            material_id: artwork.material_id,
                            language_id: artwork.language_id,
                            bis_approval_date: artwork.bis_approval_date,
                            artwork_pdf_name: artwork.artwork_pdf,
                            artwork_image_name: artwork.artwork_image,
                            artwork_ai_name: artwork.artwork_ai,
                            cpc_approved_date: artwork.cpc_approved_date,
                            next_cpc_approved_date: artwork.next_cpc_approved_date
                        })
                    }
                }
            }
            vm.$store.dispatch("setVersions", vm.versions);
        },

        convertDateFormat(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        changeCluster() {
            let vm = this;
            vm.meta.page = 1;
            vm.getPlants();
            vm.index();
        },
        search() {
            let vm = this;
            vm.meta.page = 1;
            vm.index();
        },
        index() {
            let vm = this;
            let role = vm.$store.getters.user?.role
            vm.$store
                .dispatch("post", { uri: "paginateArtworks", data: vm.meta })
                .then(function (response) {
                    vm.artworks = response.data.data
                    if (role == 'Vendor' || role == 'MM' || role == 'QC' || role == 'TPIA') {
                        let artworks = []
                        vm.artworks.map(function (element) {
                            if (element.vendor_visible) {
                                artworks.push(element)
                            }
                        })
                        vm.artworks = artworks
                    }
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPlants() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getPlants", data: vm.meta })
                .then(function (response) {
                    let plants = response.data.data
                    let plants_arr = []
                    let user_plants = vm.$store.getters.user?.user_plants
                    if (vm.role == 'MM' || vm.role == 'TPIA' || vm.role == 'QC') {
                        if (user_plants.length && plants.length) {
                            user_plants.map(function (user_plant) {
                                let plant_filter = plants.filter(function (plant) {
                                    return user_plant.plant_id == plant.plant_id
                                })
                                if (plant_filter.length) {
                                    plants_arr.push(plant_filter[0])
                                }
                            })
                        }
                        vm.plants = plants_arr
                    } else {
                        vm.plants = response.data.data
                    }
                    vm.getBagtypes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        selectMaterialCode(e) {
            let vm = this;
            vm.meta.material_code = e;
            vm.meta.page = 1
            vm.index()
        },
        selectMaterail(e) {
            let vm = this
            vm.meta.page = 1
            vm.meta.material_id = e
            vm.index()
        },

        selectPlant(e) {
            let vm = this;
            vm.meta.plant_id = e;
            vm.meta.page = 1
            vm.index()
        },
        selectLanguage(e) {
            let vm = this;
            this.meta.page = 1
            vm.meta.language_id = e;
            vm.index()
        },
        getBagtypes() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getBagTypes", data: vm.meta })
                .then(function (response) {
                    vm.bagtypes = response.data.data
                    vm.getLanguages()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getMaterials() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getMaterials", data: vm.meta })
                .then(function (response) {
                    vm.materials = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        getLanguages() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getLanguages", data: vm.meta })
                .then(function (response) {
                    vm.languages = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        submitForm() {
            let vm = this;
            if (vm.status) {
                vm.addMaterial();
            } else {
                vm.updateMaterial();
            }
        },
        addMaterial() {
            let vm = this;
            let loader = vm.$loading.show();
            vm.$store
                .dispatch("post", { uri: "addMaterial", data: vm.material })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Material Added successfully");
                    vm.index();
                    // vm.reset();
                    vm.discard();
                    vm.errors = [];
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        updateMaterial() {
            let vm = this;
            let loader = vm.$loading.show();
            let uri = { uri: "updateMaterial", data: vm.material };
            vm.$store
                .dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Material is successfully updated");
                    vm.discard();
                    vm.errors = [];
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        onPageChange(page) {
            this.meta.page = page;
            this.index();
        },

        sort(field) {
            this.meta.keyword = field;
            this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
            this.index();
        },

        updateArchive(artwork) {
            let vm = this;
            let data = {
                artwork_id: artwork.artwork_id,
                archive: artwork.archive
            }
            let loader = this.$loading.show();
            this.$store.dispatch('post', { uri: 'updateArchive', data: data })
                .then(response => {
                    loader.hide();
                    this.index();      
                })
                .catch(error => {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error', error.response.data.message);
                })
        },

        filterArchived() {
            this.meta.page = 1;
            this.index();            
        }
    },
};
</script>
<style scoped></style>
