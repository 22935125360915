<template>
    <h5 class="mb-2">{{artwork.artwork_no}}</h5>
    <div class="row">
        <div class="col-sm-3">
            <Versions  ref="versions"  @showArtwork="showArtwork"/>
        </div>
        <div class="col-sm-9">
            <div class="card">
                <Menu />
                <div class="card-body">
                    <div class="tabcontent">
                        <div class="row" v-if="$store.getters.user.role=='Vendor' && !artwork.vendor_acknowledgement">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <input type="checkbox" :class="{ 'is-invalid': errors.ack }" v-model="plant.ack">
                                    &nbsp;<label>Kindly acknowledge to download the artwork and specification <span class="text-danger">*</span></label>
                                    <span v-if="errors.ack" class="invalid-feedback" style="margin-top:-7px; margin-bottom: 1rem;">{{ errors.ack[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="$store.getters.user.role=='TPIA' && !artwork.tpia_acknowledgement">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <input type="checkbox" :class="{ 'is-invalid': errors.ack }" v-model="plant.ack">
                                    &nbsp;<label>Kindly acknowledge to download the artwork and specification <span class="text-danger">*</span></label>
                                    <span v-if="errors.ack" class="invalid-feedback" style="margin-top:-7px; margin-bottom: 1rem;">{{ errors.ack[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="$store.getters.user.role=='QC' && !artwork.qc_acknowledgement">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <input type="checkbox" :class="{ 'is-invalid': errors.ack }" v-model="plant.ack">
                                    &nbsp;<label>Kindly acknowledge to download the artwork and specification <span class="text-danger">*</span></label>
                                    <span v-if="errors.ack" class="invalid-feedback" style="margin-top:-7px; margin-bottom: 1rem;">{{ errors.ack[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="(artwork.qc_acknowledgement || artwork.vendor_acknowledgements?.length || artwork.tpia_acknowledgement)&&(role=='TPMC' || role=='CPC Maker' || role=='CPC Checker') ">
                            <div class="col-sm-12">
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th width="50%">Role</th>
                                            <th class="text-center" width="25%">Acknowledgement Date</th>
                                            <th class="text-center" width="25%">Acknowledgement</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="vendor_acknowledgement, key in artwork.vendor_acknowledgements" :key="key">
                                            <td>Vendor - {{ vendor_acknowledgement.user?.name }}</td>
                                            <td class="text-center">{{ vendor_acknowledgement.acknowledgement_date }}</td>
                                            <td class="text-center"><span class="badge badge-primary"><i class="fa fa-check"></i></span></td>
                                        </tr>
                                        <tr v-if="artwork.tpia_acknowledgement">
                                            <td>TPIA</td>
                                            <td class="text-center">{{ artwork.tpia_acknowledgement.acknowledgement_date }}</td>
                                            <td class="text-center"><span class="badge badge-primary"><i class="fa fa-check"></i></span></td>
                                        </tr>
                                        <tr v-if="artwork.qc_acknowledgement">
                                            <td>QC</td>
                                            <td class="text-center">{{ artwork.qc_acknowledgement.acknowledgement_date }}</td>
                                            <td class="text-center"><span class="badge badge-primary"><i class="fa fa-check"></i></span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                       <div class="row" v-if="($store.getters.user.role=='QC' && artwork.qc_acknowledgement) || ($store.getters.user.role=='Vendor' && artwork.vendor_acknowledgement)||($store.getters.user.role=='TPIA' && artwork.tpia_acknowledgement) || ($store.getters.user.role=='TPMC') || ($store.getters.user.role=='CPC Maker') || ($store.getters.user.role=='CPC Checker') || ($store.getters.user.role=='MM') || ($store.getters.user.role=='CPC User')">
                            <div class="col-sm-12">
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Attachment</th>
                                            <th>Version</th>
                                            <th class="text-center">Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="role!='Vendor'">
                                            <td>Artwork(PDF)</td>
                                            <td>{{ artwork.artwork_pdf_filename }}</td>
                                            <td class="text-center">
                                                <a :href="$store.getters.webUrl+'downloadArtwork?file_name='+artwork.artwork_pdf+'&file_type=pdf'" target="_blank">Download</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Artwork(Image)</td>
                                            <td>{{ artwork.artwork_image_filename }}</td>
                                            <td class="text-center">
                                                <a :href="$store.getters.webUrl+'downloadArtwork?file_name='+artwork.artwork_image+'&file_type=image'" target="_blank">Download</a>
                                            </td>
                                        </tr>
                                        <tr v-if="$store.getters.user.role=='Vendor' || $store.getters.user.role=='CPC Maker' || $store.getters.user.role=='CPC Checker'">
                                            <td>Artwork(AI)</td>
                                            <td>{{ artwork.artwork_ai_filename }}</td>
                                            <td class="text-center">
                                                <a :href="$store.getters.webUrl+'downloadArtworkAI?file_name='+artwork.artwork_ai+'&file_type=ai'" target="_blank">Download</a>
                                            </td>
                                        </tr>
                                        <tr v-if="(typeof artwork.specification_pdf == 'string')">
                                            <td>Specification</td>
                                            <td>{{ artwork.specification_pdf_filename }}</td>
                                            <td class="text-center">
                                                <a :href="$store.getters.webUrl+'downloadArtwork?file_name='+artwork.specification_pdf+'&file_type=pdf'" target="_blank">Download</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row" v-if="$store.getters.user.role=='TS'">
                            <div class="col-sm-12">
                                <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                    <thead>
                                        <tr>
                                            <th>Attachment</th>
                                            <th>Version</th>
                                            <th class="text-center">Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Artwork(PDF)</td>
                                            <td>{{ artwork.artwork_pdf_filename }}</td>
                                            <td class="text-center">
                                                <a :href="$store.getters.webUrl+'downloadArtwork?file_name='+artwork.artwork_pdf+'&file_type=pdf'" target="_blank">Download</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Artwork(Image)</td>
                                            <td>{{ artwork.artwork_image_filename }}</td>
                                            <td class="text-center">
                                                <a :href="$store.getters.webUrl+'downloadArtwork?file_name='+artwork.artwork_image+'&file_type=image'" target="_blank">Download</a>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>Artwork(AI)</td>
                                            <td>{{ artwork.artwork_ai_filename }}</td>
                                            <td class="text-center">
                                                <a :href="$store.getters.webUrl+'downloadArtworkAI?file_name='+artwork.artwork_ai+'&file_type=ai'" target="_blank">Download</a>
                                            </td>
                                        </tr>
                                        <tr v-if="artwork.attachment">
                                            <td>Other Attachment</td>
                                            <td>{{ artwork.attachment_filename }}</td>
                                            <td class="text-center">
                                                <a :href="$store.getters.webUrl+'downloadArtwork?file_name='+artwork.attachment+'&file_type=pdf'" target="_blank">Download</a>
                                            </td>
                                        </tr>
                                        <tr v-if="(typeof artwork.specification_pdf == 'string')">
                                            <td>Specification</td>
                                            <td>{{ artwork.specification_pdf_filename }}</td>
                                            <td class="text-center">
                                                <a :href="$store.getters.webUrl+'downloadArtwork?file_name='+artwork.specification_pdf+'&file_type=pdf'" target="_blank">Download</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right" v-if="$store.getters.user.role=='Vendor' && !artwork.vendor_acknowledgement">
                        <button class="btn btn-outline-success btn-sm" @click="createAcknowledgement()">
                            <span> <i class="fas fa-save"></i> Acknowledge </span>
                        </button>
                    </div>
                    <div class="float-right" v-if="$store.getters.user.role=='TPIA' && !artwork.tpia_acknowledgement">
                        <button class="btn btn-outline-success btn-sm" @click="createAcknowledgement()">
                            <span> <i class="fas fa-save"></i> Acknowledge </span>
                        </button>
                    </div>
                    <div class="float-right" v-if="$store.getters.user.role=='QC' && !artwork.qc_acknowledgement">
                        <button class="btn btn-outline-success btn-sm" @click="createAcknowledgement()">
                            <span> <i class="fas fa-save"></i> Acknowledge </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import Versions from '@/components/artworks/Versions.vue'
import Menu from '@/components/artworks/Menu.vue'
export default {    
    components:{Versions, Menu},
    data(){
        return{
            role:this.$store.getters.user?.role,
            artwork:{},
            versions:[],
            plant:{
                ack:'',
                artwork_id:''
            },
            errors:[]
        }
    },
    watch:{
        '$store.getters.artwork':function(){
            if(this.$store.getters.artwork){
                this.artwork = this.$store.getters.artwork
            }
        },
    },
    created(){
        this.artwork = this.$store.getters.artwork
    },
    methods:{
        showArtwork(artwork_id){
            this.$emit('showArtwork', artwork_id);
        },

        createAcknowledgement() {
            let vm = this;
            vm.plant.artwork_id = vm.artwork.artwork_id
            vm.$store.dispatch("post", { uri: "createAcknowledgement", data:vm.plant })
            .then(function () {
                vm.plant.ack = ""
                vm.errors = []
                vm.$store.dispatch("success", "Acknowledge updated successfully")  
                vm.getArtwork()                 
            })
            .catch(function (error) {
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message)
            });
        },
        getArtwork() {
            let vm = this;
            vm.$store.dispatch("setArtwork", null)
            vm.$store.dispatch("post", { uri: "getArtwork", data: vm.plant })
            .then(function (response) {
                vm.$store.dispatch("setArtwork", response.data.data)
            })
            .catch(function (error) {
                console.log(error)
            });
        },
    }
}
</script>