<template>
  <footer class="main-footer">
    <div class="container-fluid">
      <strong>Copyright © 2023 <a href="#">UltraTech Cement Limited</a>.</strong> All rights reserved.
    </div>
  </footer>
</template>
<script>
export default {
    data() {
        return{

        }
        
    },
}
</script>