<template>
    <Ts ref="ts" v-if="$store.getters.tab=='ts'"/>
    <Tpmc ref="tpmc" v-if="$store.getters.tab=='tpmc'"/>
    <Cpcm ref="cpcm" v-if="$store.getters.tab=='cpcm'"/>
    <Cpcc ref="cpcc" v-if="$store.getters.tab=='cpcc'"/>
    <Downloads ref="downloads" v-if="$store.getters.tab=='downloads'"/>
</template>
<script>
import Ts from '@/components/artworks/Ts'
import Tpmc from '@/components/artworks/Tpmc'
import Cpcm from '@/components/artworks/Cpcm'
import Cpcc from '@/components/artworks/Cpcc'
import Downloads from '@/components/artworks/Downloads'
export default {
    components:{ Ts, Tpmc, Cpcm, Cpcc, Downloads },
    data(){
        return{
            role: this.$store.getters.user?.role,
            tab: this.$store.getters.tab,
            artwork:{},
            versions:[]
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.artwork.artwork_id = to.params.artwork_id
        })
    },
    mounted(){
        // this.getArtwork()
    },
    created(){
        this.artwork = this.$store.getters.artwork
    },
    methods:{
        
    }
}
</script>