<template>
    <h5 class="mb-2">Artwork</h5>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Revise Artwork</h3>
                </div>
                <form @submit.prevent="addArtwork()">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>Artwork No <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': errors.artwork_no }" v-model="artwork.artwork_no" readonly />
                                    <span v-if="errors.artwork_no" class="invalid-feedback">{{ errors.artwork_no[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>Artwork Date <span class="text-danger">*</span></label>
                                    <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.artwork_date }" v-model="artwork.artwork_date" readonly/>
                                    <span v-if="errors.artwork_date" class="invalid-feedback">{{ errors.artwork_date[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>Plant</label>
                                    <select class="form-control form-control-sm" v-model="artwork.plant_id" disabled>
                                        <option v-for="plant, key in plants" :key="key" :value="plant.plant_id">{{ plant.plant_name}}</option>
                                    </select>
                                    <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Bag Types <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.bag_type_id }" v-model="artwork.bag_type_id" disabled>
                                        <option value="">Select Bag Type</option>
                                        <option v-for="(bag_type, key) in bag_types" :key="key" :value="bag_type.bag_type_id">{{bag_type.bag_type}}</option>
                                    </select>
                                    <span v-if="errors.bag_type_id" class="invalid-feedback">{{ errors.bag_type_id[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Materials</label>
                                    <select class="form-control form-control-sm" v-model="artwork.material_id" disabled>
                                        <option v-for="material, key in materials" :key="key" :value="material.material_id">{{ material.material}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Languages</label>
                                    <select class="form-control form-control-sm" v-model="artwork.language_id" disabled>
                                        <option v-for="language, key in languages" :key="key" :value="language.language_id">{{ language.language}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>BIS Approval Date <span class="text-danger">*</span></label>
                                    <div class="input-group date" id="reservationdate">
                                        <input type="date" class="form-control form-control-sm" :max="today" :class="{ 'is-invalid': errors.bis_approval_date }" v-model="artwork.bis_approval_date" />
                                        <span v-if="errors.bis_approval_date" class="invalid-feedback">{{ errors.bis_approval_date[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>Artwork PDF <span class="text-danger">*</span></label>
                                <div class="form-group">
                                    <div class="custom-file">
                                        <input type="file" ref="pdffile" class="custom-file-input" id="customFile" :class="{'is-invalid': errors.artwork_pdf}" @change="getPDFFile($event)" accept=".pdf" />
                                        <label class="custom-file-label">{{ artwork.artwork_pdf_name }}</label>
                                        <span v-if="errors.artwork_pdf" class="invalid-feedback">{{ errors.artwork_pdf[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>Artwork Image <span class="text-danger">*</span></label>
                                <div class="form-group">
                                    <div class="custom-file">
                                        <input type="file" ref="imagefile" class="custom-file-input" id="customFile2" :class="{'is-invalid': errors.artwork_image}" @change="getImageFile($event)" accept=".jpg,.png" />
                                        <label class="custom-file-label" for="customFile">{{ artwork.artwork_image_name }}</label>
                                        <span v-if="errors.artwork_image" class="invalid-feedback">{{ errors.artwork_image[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>Artwork AI <span class="text-danger">*</span></label>
                                <div class="form-group">
                                    <div class="custom-file">
                                        <input type="file" ref="aifile" class="custom-file-input" id="customFile1" :class="{'is-invalid': errors.artwork_ai}" @change="getAIFile($event)" accept=".ai" />
                                        <label class="custom-file-label" for="customFile">{{ artwork.artwork_ai_name }}</label>
                                        <span v-if="errors.artwork_ai" class="invalid-feedback">{{ errors.artwork_ai[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>Attachment </label>
                                <div class="form-group">
                                    <div class="custom-file">
                                        <input type="file" ref="attachment" class="custom-file-input" id="customFile1" :class="{'is-invalid': errors.attachment}" @change="getAttachment($event)" />
                                        <label class="custom-file-label" for="customFile">{{ artwork.attachment_name }}</label>
                                        <span v-if="errors.attachment" class="invalid-feedback">{{ errors.attachment[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="Remark">Remarks</label>
                                    <textarea type="text" class="form-control form-control-sm" id="Remarks" placeholder="Remarks" v-model="artwork.remarks" ref="remark" />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <span v-if="errors.duplicate_artwork" style="color:red">{{ errors.duplicate_artwork}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="float-right">
                            <button type="submit" class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button>
                            <button class="btn btn-outline-success btn-sm" type="submit">
                                <span> <i class="fas fa-save"></i> Submit </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import search from "@/components/Search.vue"
import moment from 'moment'
export default {
    components: { search },
    data(){
        return{
            latest_artwork:{
                artwork_id:'',
                artwork_no:''
            },
            today:'',
            artwork:{
                artwork_id:'',
                artwork_no:'UTCL',
                artwork_date:'',
                artwork_type:'New',
                change_in:'artwork',
                plant_id:'',
                bag_type_id:'',
                material_id:'',
                language_id:'',
                bis_approval_date:'',
                artwork_pdf: '',
                artwork_pdf_name:'Choose pdf file',
                pdf_name:'',
                artwork_image: '',
                artwork_image_name:'Choose image file',
                image_name:'',
                artwork_ai: '',
                artwork_ai_name:'Choose ai file',
                ai_name:'',
                attachment: '',
                attachment_name:'Choose file',
                reference_id:'',
                remarks:'',
                pdf_file_name:'',
                image_file_name:'',
                ai_file_name:'',
                attachment_file_name:''
            },
            artworks:[],
            plants:[],
            materials:[],
            bag_types:[],
            languages:[],
            errors:[]
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.artwork.artwork_id = to.params.artwork_id
            vm.getArtwork()
        })
    },
    mounted(){
        this.artwork.artwork_date =  moment().format('yyyy-MM-DD')
        this.today = moment().format('yyyy-MM-DD')
    },
    watch:{
        'artwork.plant_id':function(){
            if(this.artwork.plant_id)
                this.getPlantsWithTrashed()
        }
    },
    methods:{
        discard(){
            this.$router.push('/')
        },
        addArtwork() {
            let vm = this
            let loader = vm.$loading.show()
            const formData = new FormData()
            formData.append('user_id', vm.$store.getters.user.user_id)
            formData.append('artwork_no', vm.artwork.artwork_no)
            formData.append('parent_artwork_no', vm.artwork.parent_artwork_no)
            formData.append('artwork_date', vm.artwork.artwork_date)
            formData.append('plant_id', vm.artwork.plant_id)
            formData.append('bag_type_id', vm.artwork.bag_type_id) 
            formData.append('artwork_type', vm.artwork.artwork_type)
            formData.append('change_in', vm.artwork.change_in) 
            formData.append('material_id', vm.artwork.material_id) 
            formData.append('language_id', vm.artwork.language_id) 
            formData.append('bis_approval_date', vm.artwork.bis_approval_date) 
            formData.append('artwork_pdf', vm.artwork.artwork_pdf) 
            formData.append('artwork_image', vm.artwork.artwork_image) 
            formData.append('artwork_ai', vm.artwork.artwork_ai) 
             formData.append('artwork_pdf_name', vm.artwork.artwork_pdf_name) 
            formData.append('artwork_image_name', vm.artwork.artwork_image_name) 
            formData.append('artwork_ai_name', vm.artwork.artwork_ai_name) 
            formData.append('reference_id', vm.artwork.reference_id)
            formData.append('attachment', vm.artwork.attachment) 
            formData.append('attachment_name', vm.artwork.attachment_name) 
            formData.append('pdf_file_name', vm.artwork.pdf_file_name)
            formData.append('image_file_name', vm.artwork.image_file_name)
            formData.append('ai_file_name', vm.artwork.ai_file_name)
            formData.append('attachment_file_name', vm.artwork.attachment_file_name)
            if(vm.artwork.remarks)
                formData.append('remarks', vm.artwork.remarks)
            vm.$store
                .dispatch("post", { uri: "reviseArtwork", data: formData })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Artwork added successfully");
                    vm.$router.push("/")
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPlantsWithTrashed() {
            let vm = this
            vm.$store
                .dispatch("post", { uri: "getPlantsWithTrashed" })
                .then(function (response) {
                    vm.plants = response.data.data
                    vm.getBagTypesWithTrashed()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                    vm.$store.dispatch("error", error.response.data.message)
                })
        },
        selectMaterial(e) {
            this.artwork.material_id = e
        },
        selectLanguage(e) {
            this.artwork.language_id = e
        },
        getBagTypesWithTrashed() {
            let vm = this
            vm.$store
                .dispatch("post", { uri: "getBagTypesWithTrashed" })
                .then(function (response) {
                    vm.bag_types = response.data.data
                    vm.getMaterialsWithTrashed()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors
                    vm.$store.dispatch("error", error.response.data.message)
                })
        },

        getMaterialsWithTrashed() {
            let vm = this
            vm.$store
                .dispatch("post", { uri: "getMaterialsWithTrashed", data: vm.artwork })
                .then(function (response) {
                    vm.materials = response.data.data
                    vm.getLanguagesWithTrashed()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                    vm.$store.dispatch("error", error.response.data.message)
                })
        },
        
        getLanguagesWithTrashed() {
            let vm = this
            vm.$store
                .dispatch("post", { uri: "getLanguagesWithTrashed" })
                .then(function (response) {
                    vm.languages = response.data.data
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                    vm.$store.dispatch("error", error.response.data.message)
                })
        },
        
        getPDFFile(e) {
            let vm = this
            vm.artwork.artwork_pdf= e.target.files[0]
            vm.artwork.artwork_pdf_name = e.target.files[0].name
        },
        getImageFile(e) {
            let vm = this
            vm.artwork.artwork_image= e.target.files[0]
            vm.artwork.artwork_image_name = e.target.files[0].name
        },
        getAIFile(e) {
            let vm = this
            vm.artwork.artwork_ai= e.target.files[0]
            vm.artwork.artwork_ai_name = e.target.files[0].name
        },
        getAttachment(e){
            let vm = this
            vm.artwork.attachment= e.target.files[0]
            vm.artwork.attachment_name = e.target.files[0].name
        },
        getArtwork() {
            let vm = this
            vm.$store.dispatch("post", { uri: "getArtwork", data: vm.artwork })
            .then(function (response) {
                vm.artwork = response.data.data
                vm.artwork.parent_artwork_no = vm.artwork.artwork_no
                vm.artwork.artwork_date = moment().format('yyyy-MM-DD')
                vm.artwork.artwork_type = 'Revised'
                vm.artwork.artwork_pdf_name = vm.artwork.artwork_pdf
                vm.artwork.artwork_image_name = vm.artwork.artwork_image
                vm.artwork.artwork_ai_name = vm.artwork.artwork_ai
                vm.artwork.attachment_name = vm.artwork.attachment
                vm.artwork.pdf_file_name = vm.artwork.artwork_pdf
                vm.artwork.image_file_name = vm.artwork.artwork_image
                vm.artwork.ai_file_name = vm.artwork.artwork_ai
                vm.artwork.attachment_file_name = vm.artwork.attachment
                vm.artwork.artwork_pdf = ''
                vm.artwork.artwork_image = ''
                vm.artwork.artwork_ai = ''
                vm.artwork.attachment = ''
                if(vm.artwork){
                    if(vm.artwork.reference_id){
                        vm.latest_artwork.artwork_id = vm.artwork.reference_id
                        vm.getLatestArtWorkNo()
                    }else{
                        vm.latest_artwork.artwork_no = vm.artwork.artwork_no
                        vm.artwork.reference_id = vm.artwork.artwork_id
                        vm.generateArtworkNo()
                    }
                      
                }
            })
            .catch(function(){

            })
        },
        getLatestArtWorkNo() {
            let vm = this
            vm.$store
                .dispatch("post", { uri: "getLatestArtworkNo", data: vm.latest_artwork })
                .then(function (response) {
                    vm.latest_artwork.artwork_no = response.data?.artwork_no
                    vm.generateArtworkNo()
                })
                .catch(function (error) {
                    console.log(error)
                    vm.errors = error.response.data.errors
                    vm.$store.dispatch("error", error.response.data.message)
                })
        },
        generateArtworkNo(){
            let vm = this
            vm.artwork.artwork_no = vm.latest_artwork.artwork_no.split("_")[0]+'_'+vm.latest_artwork.artwork_no.split("_")[1]+'_'+vm.latest_artwork.artwork_no.split("_")[2]+'_v'+(parseInt(vm.latest_artwork.artwork_no.split("_")[3].match(/\d+/)[0]) + 1)
        },
    }
}
</script>