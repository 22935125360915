<template>
    <nav class="main-header navbar navbar-expand-md navbar-light navbar-dark bluebg">
        <div class="container-fluid">
            <a href="javascript:void(0)" class="navbar-brand">
                <img src="@/assets/ultratech-cement-logo.png" alt="AMT" class="logo" />
                <div class="text-white">
                    <span class="title">UTCL - AMT</span>
                    <small class="sub_title">Artwork Management Tool</small>
                </div>
            </a>
            <button class="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse order-3" id="navbarCollapse">
                <!-- Left navbar links -->
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link"> <i class="fa fa-home" aria-hidden="true"></i> Dashboard</router-link>
                    </li>

                    <li class="nav-item dropdown" v-if="$store.getters.user.role=='CPC Maker' || $store.getters.user.role=='CPC Checker'">
                        <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle"> <i class="fa fa-cog"></i> Configuration</a>
                        <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
                            <li>
                                <router-link to="/cluster" class="dropdown-item"><i class="fa fa-th" aria-hidden="true"></i>&emsp;Clusters</router-link>
                            </li>
                            <li>
                                <router-link to="/plant" class="dropdown-item"><i class="fa fa-industry" aria-hidden="true"></i>&emsp;Plants</router-link>
                            </li>
                            <li>
                                <router-link to="/bag_type" class="dropdown-item"><i class="fa fa-shopping-bag" aria-hidden="true"></i>&emsp;Bag Types</router-link>
                            </li>
                            <li>
                                <router-link to="/material" class="dropdown-item"><i class="fa fa-list" aria-hidden="true"></i>&emsp;Materials</router-link>
                            </li>
                            <li>
                                <router-link to="/language" class="dropdown-item"><i class="fa fa-language" aria-hidden="true"></i>&ensp;&nbsp;Languages</router-link>
                            </li>
                            <li>
                                <router-link to="/vendor" class="dropdown-item"><i class="fa fa-user" aria-hidden="true"></i>&ensp;&nbsp;Vendors</router-link>
                            </li>
                            <li>
                                <router-link to="/user" class="dropdown-item"><i class="fa fa-users" aria-hidden="true"></i>&emsp;Users</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item" v-if="$store.getters.user.role=='TS'">
                        <router-link to="/artwork/create" class="nav-link"> <i class="fa fa-cube" aria-hidden="true"></i> New Artwork</router-link>
                    </li>
                    <li class="nav-item" v-if="$store.getters.user.role=='CPC Maker' || $store.getters.user.role=='CPC Checker'">
                        <router-link to="/contracts" class="nav-link"> <i class="fa fa-list" aria-hidden="true"></i> Contracts</router-link>
                    </li>
                    <li class="nav-item" v-if="$store.getters.user.role=='CPC Maker' || $store.getters.user.role=='CPC Checker'">
                        <router-link to="/logs" class="nav-link"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Logs</router-link>
                    </li>
                </ul>
            </div>

            <ul class="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                <div>
                    <span style="color: white; font-size: 10pt;">{{$store.getters.user?.name}}</span><br />
                    <span style="color: white; font-size: 8pt;">{{$store.getters.user?.role}}</span>
                </div>
                <!-- <h6 style="color:white">{{$store.getters.user?.name}}<br/>adfafd</h6><br/>
                <span style="color:white">{{ $store.getters.user?.role }}</span> -->
                <li class="nav-item dropdown">
                    <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" style="margin-top: -7px;">
                        <img :src="getUserIcon()" class="img-circle" alt="User Image" style="width: 40px !important; height: 40px !important;" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                        <a href="#" class="dropdown-item" @click.prevent="removeConsent()"> <i class="fas fa-file-signature"></i> Remove Consent </a>
                        <router-link to="/profile" class="dropdown-item"> <i class="fa fa-user"></i> Profile </router-link>
                        <a href="#" class="dropdown-item" @click.prevent="logout()"> <i class="fas fa-sign-out-alt"></i> Logout </a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {
        data() {
            return {};
        },
        methods: {
            getUserIcon() {
                return "img/avatar.png";
                // if(this.$store.getters.user?.avatar)
                //     return this.$store.getters.user.avatar
                // else
                //     return 'img/avatar.png'
            },
            logout() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "logout" })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("logout");
                        vm.$router.push("/login");
                        location.reload();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            removeConsent() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "deleteConsent", data: { consent_id: vm.$store.getters.user?.consent?.consent_id } })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Successfully removed consent");
                        vm.logout();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>

<style scoped>
    .bluebg {
        background-color: #392eca;
    }
    .navbar-brand {
        display: flex;
        align-items: center;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 1rem;
        line-height: 18px;
        white-space: nowrap;
    }
    .text-white {
        padding-top: 10px;
    }
    .logo {
        width: 50px;
        display: block;
    }
    .title {
        font-weight: bold;
        font-size: 25px;
        padding-left: 10px;
        padding-bottom: 0px;
        letter-spacing: 1px;
        display: block !important;
    }
    .sub_title {
        font-size: 12px;
        padding-left: 10px;
    }

    .nav-item {
        padding-top: 5px;
    }
</style>
