<template>
    <h5 class="mb-2">{{artwork.artwork_no}}</h5>
    <div class="row">
        <div class="col-sm-3">
            <Versions  ref="versions"  @showArtwork="showArtwork"/>
        </div>
        <div class="col-sm-9">
            <div class="card">
                <Menu />
                <div class="card-body">
                    <div class="row" v-if="!artwork.material_code">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Select Material Code <span class="text-danger">*</span></label>
                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.material_code }" v-model="cpcm.material_code" @change="getContracts()">
                                    <option value="" selected>Select Material Code</option>
                                    <option v-for="(material_code,key) in material_codes" :key="key" :value="material_code.material_code">
                                        {{ material_code.material_code }} - {{material_code.grade}}
                                    </option>
                                </select>
                                <span v-if="errors.material_code" class="invalid-feedback">{{ errors.material_code[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>SKU Mapped Date <span class="text-danger">*</span></label>
                                <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.sku_mapped_date }" v-model="cpcm.sku_mapped_date" readonly/>
                                <span v-if="errors.sku_mapped_date" class="invalid-feedback">{{ errors.sku_mapped_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th class="text-center">#</th>
                                        <th>Contract No</th>
                                        <th>Material Code</th>
                                        <th>Grade</th>
                                        <th>Vendor Code</th>
                                        <th>Vendor Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="contract, key in contracts" :key="key">
                                        <td style="text-align:center">{{ key+1 }}</td>
                                        <td>{{ contract.sap_contract_no}}</td>
                                        <td>{{ contract.material_code}}</td>
                                        <td>{{ contract.grade}}</td>
                                        <td>{{ contract.vendor?.vendor_code }}</td>
                                        <td>{{ contract.vendor?.vendor_name }}</td> 
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <table class="table table-responsive-sm table-bordered table-striped table-sm">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th>Contract No</th>
                                    <th>Material Code</th>
                                    <th>Grade</th>
                                    <th>Vendor Code</th>
                                    <th>Vendor Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="contract, key in contracts" :key="key">
                                    <td style="text-align:center">{{ key+1 }}</td>
                                    <td>{{ contract.sap_contract_no }}</td>
                                    <td>{{ contract.material_code}}</td>
                                    <td>{{ contract.grade}}</td>
                                    <td>{{ contract.vendor.vendor_code }}</td>
                                    <td>{{ contract.vendor.vendor_name }}</td> 
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <!-- <span>{{ update_status }}</span> -->
                        <button type="button" v-if="showEditButton()" class="btn btn-outline-warning btn-sm" @click.prevent="editArtworkCpcm()"><i class="fas fa-edit"></i> Edit</button>
                        <!-- <button type="button" v-if="this.$store.getters.user?.role=='CPC Maker' && cpcm.update_status" class="btn btn-outline-warning btn-sm" @click.prevent="editArtworkCpcm()"><i class="fas fa-edit"></i> Edit</button> -->
                        <!-- <button type="button" v-if="this.$store.getters.user?.role=='CPC Maker' && cpcm.update_status && artwork.material_code " class="btn btn-outline-success btn-sm" @click.prevent="updateArtworkCpcm()"><i class="fas fa-edit"></i> Update</button> -->
                        <!-- <button type="button" v-if="this.$store.getters.user?.role=='CPC Maker' && !cpcm.update_status && !artwork.material_code " class="btn btn-outline-success btn-sm" @click.prevent="updateArtworkCpcm()"><i class="fas fa-edit"></i> Update</button> -->
                        <!-- <button type="button" v-if="this.$store.getters.user?.role=='CPC Checker' && !cpcm.update_status  && !artwork.cpc_approved_date" class="btn btn-outline-warning btn-sm" @click.prevent="editArtworkCpcm()"><i class="fas fa-edit"></i> Edit</button> -->
                        
                        <!-- <button type="button" v-if="this.$store.getters.user?.role=='CPC Maker' && !cpcm.update_status" class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button> -->
                        <button class="btn btn-outline-success btn-sm" v-if="showUpdateButton()" @click="updateArtworkCpcm()">
                            <span> <i class="fas fa-save"></i> Update </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import Versions from '@/components/artworks/Versions.vue'
import Menu from '@/components/artworks/Menu.vue'
import moment from 'moment'
export default {    
    components:{Versions, Menu},

    watch:{
        '$store.getters.artwork':function(){
            if(this.$store.getters.artwork){
                this.artwork = this.$store.getters.artwork
                if(this.artwork.material_code){
                    this.setContracts()
                    this.setUpdateStatus()
                }
            }
        },
    },

    data(){
        return{
            role:this.$store.getters.user?.role,
            cpcm:{
                contracts:[],
                contract_id:'',
                artwork_id:'',
                plant_id:'',
                vendor_id:'',
                material_code:'',
                update_status:false,
                sku_mapped_date: moment().format('yyyy-MM-DD'),
                is_visible:false
            },
            contracts:[],
            material_codes:[],
            artwork:{},
            errors:[],
            edit_button_visible:false,
            update_button_visible:false
        }
    },

    created(){
        this.artwork = this.$store.getters.artwork
        this.getUniqueMaterialCode()
        if(this.artwork.material_code){
            this.setUpdateStatus()
            this.setContracts()
        }
    },
    methods:{
        setUpdateStatus(){
            let role = this.$store.getters.user?.role
            let stage = this.artwork?.stage?.stage
            let material_code = this.artwork.material_code
            let cpc_approved_date = this.artwork.cpc_approved_date
            if(role == 'CPC Maker' && stage == 'CPC Maker' && material_code && !cpc_approved_date){
                this.edit_button_visible = true
                this.update_button_visible = true
            }else if(role == 'CPC Maker' && stage == 'CPC Checker' && material_code && !cpc_approved_date){
                this.edit_button_visible = true
                this.update_button_visible = false
            }else if(role == 'CPC Checker' && stage == 'CPC Maker' && material_code && !cpc_approved_date){
                this.edit_button_visible = true
                this.update_button_visible = true
            }else if(role == 'CPC Checker' && stage == 'CPC Checker' && material_code && !cpc_approved_date){
                this.edit_button_visible = true
                this.update_button_visible = false
            }else{
                this.edit_button_visible = false
                this.update_button_visible = false
            }
        },
        showEditButton(){
            if(this.edit_button_visible)
                return true
            else 
                return false
        },
        showUpdateButton(){
            if(this.update_button_visible)
                return true
            else
                return false
        },
        showArtwork(artwork_id){
            this.$emit('showArtwork', artwork_id);
        },

        editArtworkCpcm(){
            this.artwork.material_code  = ''
            this.edit_button_visible = false
            this.update_button_visible = false
            this.cpcm.material_code = ''
            this.contracts = []
        },

        updateArtworkCpcm(){
            let vm = this;
            let loader = vm.$loading.show()
            vm.cpcm.artwork_id = vm.artwork.artwork_id
            // vm.cpcm.material_code = vm.artwork.material_code
            vm.cpcm.contracts = []
            vm.contracts.map(function(element){
                vm.cpcm.contracts.push({
                    'artwork_id': vm.artwork.artwork_id,
                    'vendor_id': element.vendor_id,
                    'contract_id': element.contract_id
                })
            })
            vm.$store
                .dispatch("post", { uri: "updateArtworkCpcm", data:vm.cpcm })
                .then(function () {
                    loader.hide();
                    vm.artwork.material_code = vm.cpcm.material_code
                    vm.errors = []
                    vm.cpcm.contracts = []
                    vm.cpcm.artwork_id = ''
                    vm.cpcm.material_code = ''
                    vm.cpcm.sku_mapped_date =  moment().format('yyyy-MM-DD'),
                    vm.getArtwork()
                    vm.$store.dispatch("success", "Artwork updated successfully")                    
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message)
                });
        },
        getArtwork() {
            let vm = this;
            vm.$store.dispatch("setArtwork", null)
            vm.$store.dispatch("post", { uri: "getArtwork", data: vm.artwork })
            .then(function (response) {
                vm.$store.dispatch("setArtwork", response.data.data)
            })
            .catch(function (error) {
                console.log(error)
            });
        },
        getUniqueMaterialCode(){
            let vm = this;
            vm.cpcm.artwork_id = vm.artwork.artwork_id
            vm.cpcm.plant_id = vm.artwork.plant.plant_id
            vm.$store
                .dispatch("post", { uri: "getUniqueMaterialCode", data:vm.cpcm })
                .then(function (response) {
                    vm.material_codes = response.data                   
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message)
                });
        },
        getContracts(){
            let vm = this;
            vm.cpcm.artwork_id = vm.artwork.artwork_id
            vm.cpcm.plant_id = vm.artwork.plant.plant_id
            // vm.cpcm.material_code = vm.artwork.material_code
            // console.log(vm.cpcm.material_code)
            vm.$store
                .dispatch("post", { uri: "getContracts", data:vm.cpcm })
                .then(function (response) {
                    vm.contracts = response.data.data
                    vm.errors = []
                    vm.edit_button_visible = true
                    vm.update_button_visible = true
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    console.log(vm.errors)
                    vm.$store.dispatch("error", error.response.data.message)
                });
        },

        setContracts(){
            let vm = this;
            vm.cpcm.artwork_id = vm.artwork.artwork_id
            vm.cpcm.plant_id = vm.artwork.plant.plant_id
            vm.cpcm.material_code = vm.artwork.material_code
            // console.log(vm.cpcm.material_code)
            vm.$store
                .dispatch("post", { uri: "getContracts", data:vm.cpcm })
                .then(function (response) {
                    vm.contracts = response.data.data
                    vm.errors = []                 
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    console.log(vm.errors)
                    vm.$store.dispatch("error", error.response.data.message)
                });
        },

        discard(){
            this.errors = [] 
            this.cpcm.material_code = '' 
            this.contracts = '' 
            this.cpcm.contracts = '' 
            // this.$refs.specification_excel.value = ''
            // this.$refs.specification_pdf.value = ''
            // this.artwork_tpmc.specification_pdf  = ''
            // this.artwork.tpmc_submitted_date = moment().format('yyyy-MM-DD')
        },
    }
}
</script>