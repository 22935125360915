<template>
    <h5 class="mb-2">Artwork</h5>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">New Artwork</h3>
                </div>
                <form @submit.prevent="addArtwork()">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>Artwork No <span class="text-danger">*</span></label>
                                    <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': errors.artwork_no }" v-model="artwork.artwork_no" readonly />
                                    <span v-if="errors.artwork_no" class="invalid-feedback">{{ errors.artwork_no[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>Artwork Date <span class="text-danger">*</span></label>
                                    <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.artwork_date }" v-model="artwork.artwork_date" readonly/>
                                    <span v-if="errors.artwork_date" class="invalid-feedback">{{ errors.artwork_date[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>Plants <span class="text-danger">*</span></label>
                                    <search
                                        :class="{ 'is-invalid': errors.plant_id }"
                                        :customClass="{ 'is-invalid': errors.plant_id }"
                                        :initialize="artwork.plant_id"
                                        id="plant_id"
                                        label="plant_name"
                                        placeholder="Select Plant"
                                        :data="plants"
                                        @input="selectPlant($event)"
                                    >
                                    </search>
                                    <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Bag Types <span class="text-danger">*</span></label>
                                    <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.bag_type_id }" v-model="artwork.bag_type_id" @change.prevent="getMaterials()">
                                        <option value="">Select Bag Type</option>
                                        <option v-for="(bag_type, key) in bag_types" :key="key" :value="bag_type.bag_type_id">{{bag_type.bag_type}}</option>
                                    </select>
                                    <span v-if="errors.bag_type_id" class="invalid-feedback">{{ errors.bag_type_id[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Materials <span class="text-danger">*</span></label>
                                    <search
                                        :class="{ 'is-invalid': errors.material_id }"
                                        :customClass="{ 'is-invalid': errors.material_id }"
                                        :initialize="artwork.material_id"
                                        id="material_id"
                                        label="material"
                                        placeholder="Select Material"
                                        :data="materials"
                                        @input="selectMaterial($event)"
                                    >
                                    </search>
                                    <span v-if="errors.material_id" class="invalid-feedback">{{ errors.material_id[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Languages <span class="text-danger">*</span></label>
                                    <search
                                        :class="{ 'is-invalid': errors.language_id }"
                                        :customClass="{ 'is-invalid': errors.language_id }"
                                        :initialize="artwork.language_id"
                                        id="language_id"
                                        label="language"
                                        placeholder="Select Language"
                                        :data="languages"
                                        @input="selectLanguage($event)"
                                    >
                                    </search>
                                    <span v-if="errors.language_id" class="invalid-feedback">{{ errors.language_id[0] }}</span>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>BIS Approval Date <span class="text-danger">*</span></label>
                                    <div class="input-group date" id="reservationdate">
                                        <input type="date" class="form-control form-control-sm" :max="today" :class="{ 'is-invalid': errors.bis_approval_date }" v-model="artwork.bis_approval_date" />
                                        <span v-if="errors.bis_approval_date" class="invalid-feedback">{{ errors.bis_approval_date[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>Artwork PDF <span class="text-danger">*</span></label>
                                <div class="form-group">
                                    <div class="custom-file">
                                        <input type="file" ref="pdffile" class="custom-file-input" id="customFile" :class="{'is-invalid': errors.artwork_pdf}" @change="getPDFFile($event)" accept=".pdf" />
                                        <label class="custom-file-label">{{ artwork.artwork_pdf_name }}</label>
                                        <span v-if="errors.artwork_pdf" class="invalid-feedback">{{ errors.artwork_pdf[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>Artwork Image <span class="text-danger">*</span></label>
                                <div class="form-group">
                                    <div class="custom-file">
                                        <input type="file" ref="imagefile" class="custom-file-input" id="customFile2" :class="{'is-invalid': errors.artwork_image}" @change="getImageFile($event)" accept=".jpg,.png" />
                                        <label class="custom-file-label" for="customFile">{{ artwork.artwork_image_name }}</label>
                                        <span v-if="errors.artwork_image" class="invalid-feedback">{{ errors.artwork_image[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>Artwork AI <span class="text-danger">*</span></label>
                                <div class="form-group">
                                    <div class="custom-file">
                                        <input type="file" ref="aifile" class="custom-file-input" id="customFile1" :class="{'is-invalid': errors.artwork_ai}" @change="getAIFile($event)" accept=".ai" />
                                        <label class="custom-file-label" for="customFile">{{ artwork.artwork_ai_name }}</label>
                                        <span v-if="errors.artwork_ai" class="invalid-feedback">{{ errors.artwork_ai[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <label>Other Attachment </label>
                                <div class="form-group">
                                    <div class="custom-file">
                                        <input type="file" ref="attachment" class="custom-file-input" id="customFile1" :class="{'is-invalid': errors.attachment}" @change="getAttachment($event)" />
                                        <label class="custom-file-label" for="customFile">{{ artwork.attachment_name }}</label>
                                        <span v-if="errors.attachment" class="invalid-feedback">{{ errors.attachment[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="Remark">Remarks</label>
                                    <textarea type="text" class="form-control form-control-sm" id="Remarks" placeholder="Remarks" v-model="artwork.remarks" ref="remark" />
                                </div>
                            </div>
                            <div class="col-md-12">
                                <span v-if="errors.duplicate_artwork" style="color:red">{{ errors.duplicate_artwork}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="float-right">
                            <button type="submit" class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button>
                            <button class="btn btn-outline-success btn-sm" type="submit">
                                <span> <i class="fas fa-save"></i> Submit </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import search from "@/components/Search.vue";
import moment from 'moment';
export default {
    components: { search },
    data(){
        return{
            today:moment().format('yyyy-MM-DD'),
            artwork:{
                artwork_no:'UTCL',
                artwork_date:'',
                artwork_type:'New',
                plant_id:'',
                bag_type_id:'',
                material_id:'',
                language_id:'',
                bis_approval_date:'',
                artwork_pdf: '',
                artwork_pdf_name:'Choose pdf file',
                pdf_name:'',
                artwork_image: '',
                artwork_image_name:'Choose image file',
                image_name:'',
                artwork_ai: '',
                artwork_ai_name:'Choose ai file',
                ai_name:'',
                attachment:'',
                attachment_name:'Choose file',
                remarks:''
            },
            artworks:[],
            plants:[],
            materials:[],
            bag_types:[],
            languages:[],
            errors:[]
        }
    },
    mounted(){
        this.artwork.artwork_date =  moment().format('yyyy-MM-DD')
        this.getPlants()
    },
    watch:{
        'artwork.plant_id':function(){
            if(this.artwork.plant_id)
                this.getArtworks()
        }
    },
    methods:{
        discard(){
            this.artwork.artwork_no = 'UTCL',
            this.artwork.artwork_date = moment().format('yyyy-MM-DD'),
            this.artwork.artwork_type = 'New',
            this.artwork.plant_id = '',
            this.artwork.bag_type_id = '',
            this.artwork.material_id = '',
            this.artwork.language_id = '',
            this.artwork.bis_approval_date = '',
            this.artwork.artwork_pdf =  '',
            this.artwork.artwork_pdf_name = 'Choose pdf file',
            this.artwork.pdf_name = '',
            this.artwork.artwork_image =  '',
            this.artwork.artwork_image_name = 'Choose image file',
            this.artwork.image_name = '',
            this.artwork.artwork_ai =  '',
            this.artwork.artwork_ai_name = 'Choose ai file',
            this.artwork.ai_name = '',
            this.artwork.remarks = ''
            this.errors = []
            this.getMaterials()
        },
        generateArtworkNo(){
            let vm = this
            let plant = this.plants.filter(function(element){
                return element.plant_id == vm.artwork.plant_id
            })
            if(plant.length){
                vm.artwork.artwork_no = 'UTCL_'+plant[0].plant_code
                if(vm.artworks.length){
                    let num = parseInt(vm.artworks[vm.artworks.length-1].artwork_no.split("_")[2])+1
                    num = num.toString()
                    while (num.length < 4) num = "0" + num
                    vm.artwork.artwork_no = 'UTCL_'+plant[0].plant_code+'_'+num+'_v1'
                }else{
                    vm.artwork.artwork_no = 'UTCL_'+plant[0].plant_code+'_0001_v1'
                }
            }
        },
        addArtwork() {
            let vm = this
            let loader = vm.$loading.show()
            const formData = new FormData()
            formData.append('user_id', vm.$store.getters.user.user_id)
            formData.append('artwork_no', vm.artwork.artwork_no)
            formData.append('artwork_date', vm.artwork.artwork_date)
            formData.append('plant_id', vm.artwork.plant_id)
            formData.append('bag_type_id', vm.artwork.bag_type_id) 
            formData.append('artwork_type', vm.artwork.artwork_type) 
            formData.append('material_id', vm.artwork.material_id) 
            formData.append('language_id', vm.artwork.language_id) 
            formData.append('bis_approval_date', vm.artwork.bis_approval_date) 
            formData.append('artwork_pdf', vm.artwork.artwork_pdf) 
            formData.append('artwork_image', vm.artwork.artwork_image) 
            formData.append('artwork_ai', vm.artwork.artwork_ai)
            formData.append('attachment', vm.artwork.attachment)
            formData.append('remarks', vm.artwork.remarks)
            vm.$store
                .dispatch("post", { uri: "addArtwork", data: formData })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Artwork added successfully");
                    vm.$router.push("/")
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getArtworks() {
            let vm = this;
            vm.artwork.user_id = vm.$store.getters.user.user_id;
            if(vm.artwork.plant_id){
                vm.$store
                    .dispatch("post", { uri: "getArtworks", data: vm.artwork })
                    .then(function (response) {
                        vm.artworks = response.data.data;
                        vm.errors = []
                        vm.generateArtworkNo()
                    })
                    .catch(function (error) {
                        console.log(error)
                    });
            }
        },
        getPlants() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data
                    vm.getArtworks()
                    vm.getBagTypes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        selectPlant(e) {
            this.artwork.plant_id = e
        },
        selectMaterial(e) {
            this.artwork.material_id = e
        },
        selectLanguage(e) {
            this.artwork.language_id = e
        },
        getBagTypes() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getBagTypes" })
                .then(function (response) {
                    vm.bag_types = response.data.data
                    vm.getLanguages()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getMaterials() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getMaterials", data: vm.artwork })
                .then(function (response) {
                    vm.materials = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getLanguages() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getLanguages", data: vm.meta })
                .then(function (response) {
                    vm.languages = response.data.data
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getPDFFile(e) {
            let vm = this;
            vm.artwork.artwork_pdf= e.target.files[0]
            vm.artwork.artwork_pdf_name = e.target.files[0].name
        },
        getImageFile(e) {
            let vm = this;
            vm.artwork.artwork_image= e.target.files[0]
            vm.artwork.artwork_image_name = e.target.files[0].name
        },
        getAIFile(e) {
            let vm = this;
            vm.artwork.artwork_ai= e.target.files[0]
            vm.artwork.artwork_ai_name = e.target.files[0].name
        },
        getAttachment(e){
            let vm = this;
            vm.artwork.attachment= e.target.files[0]
            vm.artwork.attachment_name = e.target.files[0].name
        }
    }
}
</script>