<template>
    <h5 class="mb-2">Profile</h5>
    <div class="row">
        <div class="col-sm-8">
            <form class="card" @submit.prevent="updateProfile">
                <div class="card-header">
                    <strong>Update Profile</strong>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="tax_name">Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="user.name" ref="name" />
                        <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                    </div>

                    <div class="form-group">
                        <label for="email">Email <span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="user.email" />
                        <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                    </div>

                    <div class="form-group">
                        <label for="mobile">Role <span class="text-danger">*</span></label>
                        <input type="text" readonly class="form-control form-control-sm" :class="{'is-invalid': errors.mobile}" placeholder="Role" v-model="user.role" />
                        <span v-if="errors.mobile" class="invalid-feedback">{{ errors.mobile[0] }}</span>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <router-link :to="from_path" class="btn btn-outline-danger btn-sm">Discard</router-link>
                        <button class="btn btn-outline-success btn-sm" type="submit">
                            <span> <i class="fas fa-save"></i> Update </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-sm-4">
            <form class="card" @submit.prevent="updatePassword">
                <div class="card-header">
                    <strong>Reset Password</strong>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label>Current Password <span class="text-danger">*</span></label>
                        <input type="password" class="form-control" :class="{'is-invalid': errors.current_password}" v-model="user.current_password" placeholder="Current Password" />
                        <span v-if="errors.current_password" class="invalid-feedback">{{ errors.current_password[0] }}</span>
                    </div>
                    <div class="form-group">
                        <label>New Password <span class="text-danger">*</span></label>
                        <input type="password" class="form-control" :class="{'is-invalid': errors.new_password}" v-model="user.new_password" placeholder="New Password" />
                        <span v-if="errors.new_password" class="invalid-feedback">{{ errors.new_password[0] }}</span>
                    </div>
                    <div class="form-group">
                        <label>Confirm Password <span class="text-danger">*</span></label>
                        <input type="password" class="form-control" :class="{'is-invalid': errors.confirm_password}" v-model="user.confirm_password" placeholder="Confirm Password" />
                        <span v-if="errors.confirm_password" class="invalid-feedback">{{ errors.confirm_password[0] }}</span>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <router-link :to="from_path" class="btn btn-outline-danger btn-sm">Discard</router-link>
                        <button class="btn btn-outline-success btn-sm" type="submit">
                            <span> <i class="fas fa-save"></i> Update </span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Profile",
        data() {
            return {
                status: true,
                user: {
                    user_id: "",
                    name: "",
                    email: "",
                    mobile: "",
                    role: "",
                    password: "",
                    address: "",
                    avatar: "",
                    plants:[],
                    current_password: "",
                    new_password:'',
                    confirm_password: "",
                },
                from_path:'',
                users: [],
                errors: [],
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.from_path = from.path
                vm.user.user_id = vm.$store?.getters?.user?.user_id;
                let loader = vm.$loading.show();
                let uri = { uri: "showUser", data: vm.user };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.user = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            });
        },
        mounted() {
        },
        methods: {
            updateProfile() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateUser", data: vm.user };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("setUser", response.data.data);
                        vm.$store.dispatch("success", "Profile is successfully updated");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.user.avatar = e.target.result;
                };
            },

            updatePassword() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "changePassword", data: vm.user };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.user.user_id = ''
                        vm.user.current_password = ''
                        vm.user.new_password = ''
                        vm.user.confirm_password = ''
                        vm.errors = [];
                        vm.$store.dispatch("success", "Password is successfully updated");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>
