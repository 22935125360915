<template>
    <h5 class="mb-2">{{artwork?.artwork_no}}</h5>
    <div class="row">
        <div class="col-sm-3">
            <Versions  ref="versions"/>
        </div>
        <div class="col-sm-9">
            <div class="card">
                <Menu />
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Artwork No <span class="text-danger">*</span></label>
                                <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': errors.artwork_no }" v-model="artwork.artwork_no" disabled />
                                <span v-if="errors.artwork_no" class="invalid-feedback">{{ errors.artwork_no[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Artwork Date <span class="text-danger">*</span></label>
                                <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.artwork_date }" v-model="artwork.artwork_date" disabled />
                                <span v-if="errors.artwork_date" class="invalid-feedback">{{ errors.artwork_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Artwork Type <span class="text-danger">*</span></label>
                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.artwork_type }" v-model="artwork.artwork_type" disabled>
                                    <option value="">Select Artwork</option>
                                    <option value="New">New</option>
                                    <option value="Revised">Revised</option>
                                </select>
                                <span v-if="errors.artwork_type" class="invalid-feedback">{{ errors.artwork_type[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-4" v-if="$store.getters.user?.role=='TS'">
                            <div class="form-group">
                                <label>Plant <span class="text-danger">*</span></label>
                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.plant_id }" v-model="artwork.plant_id"  ref="plant" :disabled="is_disabled_plant">
                                    <option value="">Select Plant Name</option>
                                    <option v-for="(plant,key) in plants" :key="key" :value="plant.plant_id">{{plant.plant_name}}</option>
                                </select>
                                <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-8" v-else>
                            <div class="form-group">
                                <label>Plant <span class="text-danger">*</span></label>
                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.plant_id }" v-model="artwork.plant_id"  ref="plant" :disabled="is_disabled_plant">
                                    <option value="">Select Plant Name</option>
                                    <option v-for="(plant,key) in plants" :key="key" :value="plant.plant_id">{{plant.plant_name}}</option>
                                </select>
                                <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Bag Type <span class="text-danger">*</span></label>
                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.bag_type_id }" v-model="artwork.bag_type_id" @change.prevent="getMaterials()" :disabled="is_disabled_bag_type">
                                    <option value="">Select Bag Type</option>
                                    <option v-for="(bagtype, key) in bagtypes" :key="key" :value="bagtype.bag_type_id">{{bagtype.bag_type}}</option>
                                </select>
                                <span v-if="errors.bag_type_id" class="invalid-feedback">{{ errors.bag_type_id[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group" v-if="artwork.cpc_approved_date">
                                <label>Material <span class="text-danger">*</span></label>
                                <input type="text" class="form-control form-control-sm" :value="artwork.contract.grade" readonly>
                            </div>
                            <div class="form-group" v-else>
                                <label>Material <span class="text-danger">*</span></label>
                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.material_id }" v-model="artwork.material_id" :disabled="is_disabled_material">
                                    <option value="">Select Material</option>
                                    <option v-for="(material,key) in materials" :key="key" :value="material.material_id">{{ material.material }}</option>
                                </select>
                                <span v-if="errors.material_id" class="invalid-feedback">{{ errors.material_id[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Language <span class="text-danger">*</span></label>
                                <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.language_id }" v-model="artwork.language_id" :disabled="is_disabled_language">
                                    <option value="" selected>Select Language</option>
                                    <option v-for="(language,key) in languages" :key="key" :value="language.language_id">{{language.language}}</option>
                                </select>
                                <span v-if="errors.language_id" class="invalid-feedback">{{ errors.language_id[0] }}</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>BIS Approval Date <span class="text-danger">*</span></label>
                                <div class="input-group date" id="reservationdate">
                                    <input type="date" class="form-control form-control-sm" :max="artwork.artwork_date" :class="{ 'is-invalid': errors.bis_approval_date }" v-model="artwork.bis_approval_date" :disabled="is_disabled_bis_approval_date" />
                                    <span v-if="errors.bis_approval_date" class="invalid-feedback">{{ errors.bis_approval_date[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label>Artwork PDF <span class="text-danger">*</span></label>
                            <div class="form-group">
                                <div class="custom-file">
                                    <input type="file" ref="pdffile" class="custom-file-input custom-file-input-sm" id="customFile" :class="{'is-invalid': errors.artwork_pdf}" @change="getPDFFile($event)" accept=".pdf" :disabled="is_disabled_artwork_pdf" />
                                    <label class="custom-file-label">{{ artwork.artwork_pdf_name }}</label>
                                    <span v-if="errors.artwork_pdf" class="invalid-feedback">{{ errors.artwork_pdf[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label>Artwork Image <span class="text-danger">*</span></label>
                            <div class="form-group">
                                <div class="custom-file">
                                    <input type="file" ref="imagefile" class="custom-file-input" id="customFile2" :class="{'is-invalid': errors.artwork_image}" @change="getImageFile($event)" accept=".jpg,.png" :disabled="is_disabled_artwork_image" />
                                    <label class="custom-file-label" for="customFile">{{ artwork.artwork_image_name }}</label>
                                    <span v-if="errors.artwork_image" class="invalid-feedback">{{ errors.artwork_image[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <label>Artwork AI <span class="text-danger">*</span></label>
                            <div class="form-group">
                                <div class="custom-file">
                                    <input type="file" ref="aifile" class="custom-file-input" id="customFile1" :class="{'is-invalid': errors.artwork_ai}" @change="getAIFile($event)" accept=".ai" :disabled="is_disabled_artwork_ai" />
                                    <label class="custom-file-label" for="customFile">{{ artwork.artwork_ai_name }}</label>
                                    <span v-if="errors.artwork_ai" class="invalid-feedback">{{ errors.artwork_ai[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4" v-if="$store.getters.user?.role=='TS'">
                            <label>Other Attachment </label>
                            <div class="form-group">
                                <div class="custom-file">
                                    <input type="file" ref="attachment" class="custom-file-input" id="customFile1" :class="{'is-invalid': errors.attachment}" @change="getAttachment($event)" :disabled="is_disabled_attachment" />
                                    <label class="custom-file-label" for="customFile">{{ artwork.attachment_name }}</label>
                                    <span v-if="errors.attachment" class="invalid-feedback">{{ errors.attachment[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="Remark">Remarks</label>
                                <textarea type="text" class="form-control form-control-sm" id="Remarks" placeholder="Remarks" v-model="artwork.remarks" ref="remark" :disabled="is_disabled_remarks" />
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <button type="button" v-if="this.$store.getters.user?.role=='TS' && !this.update_status && !artwork.tpmc_submitted_date" class="btn btn-outline-warning btn-sm" @click.prevent="editArtworkTs()"><i class="fas fa-edit"></i> Edit</button>
                        <button type="button" v-if="this.$store.getters.user?.role=='CPC Maker' && !this.update_status && !artwork.cpc_approved_date" class="btn btn-outline-warning btn-sm" @click.prevent="editArtworkTs()"><i class="fas fa-edit"></i> Edit</button>
                        <button type="button" v-if="this.$store.getters.user?.role=='TS' && !this.update_status && artwork.revise && artwork.is_latest" class="btn btn-outline-success btn-sm" @click.prevent="reviseArtwork()"><i class="fa fa-undo"></i> Revise</button>
                        <button type="button" v-if="this.$store.getters.user?.role=='CPC Checker' && !this.update_status && !artwork.cpc_approved_date" class="btn btn-outline-warning btn-sm" @click.prevent="editArtworkTs()"><i class="fas fa-edit"></i> Edit</button>
                        <button class="btn btn-outline-success btn-sm" v-if="this.update_status" @click="updateArtworkTs()">
                            <span> <i class="fas fa-save"></i> Update </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>      
</template>
<script>
import Versions from '@/components/artworks/Versions.vue'
import Menu from '@/components/artworks/Menu.vue'
export default {    
    components:{Versions, Menu},
    // props:{
    //     artwork:Object,
    // },
    data(){
        return{
            is_disabled_plant:false,
            is_disabled_bag_type:false,
            is_disabled_material:false,
            is_disabled_language:false,
            is_disabled_bis_approval_date:false,
            is_disabled_artwork_pdf:false,
            is_disabled_artwork_image:false,
            is_disabled_artwork_ai:false,
            is_disabled_remarks:false,
            is_disabled_attachment:false,
            role:this.$store.getters.user?.role,
            update_status:false,
            artwork:{},
            versions:[],
            errors:[],
            plants:[],
            tab:'ts',
            role:'',
            bagtypes:[],
            materials:[],
            languages:[]
        }
    },
    watch:{
        '$store.getters.artwork':function(){
            if(this.$store.getters.artwork){
                this.getPlants()
                this.artwork = this.$store.getters.artwork,
                this.artwork.artwork_no = this.artwork.artwork_no
                this.artwork.artwork_pdf_name = this.artwork.artwork_pdf,
                this.artwork.artwork_image_name = this.artwork.artwork_image,
                this.artwork.artwork_ai_name = this.artwork.artwork_ai,
                this.artwork.attachment_name = this.artwork.attachment
            }
        },
        
    },
    mounted(){
        this.getPlants()
        this.artwork = this.$store.getters.artwork,
        // alert(this.artwork.artwork_id)
        // console.log(this.artwork)
        // console.log(this.$store.getters.user?.role)
        // this.artwork.artwork_pdf_name = this.artwork.artwork_pdf,
        // this.artwork.artwork_image_name = this.artwork.artwork_image,
        // this.artwork.artwork_ai_name = this.artwork.artwork_ai,
        // this.artwork.attachment_name = this.artwork.attachment,
        this.is_disabled_plant = true,
        this.is_disabled_bag_type = true,
        this.is_disabled_material = true,
        this.is_disabled_language = true,
        this.is_disabled_bis_approval_date = true,
        this.is_disabled_artwork_pdf = true,
        this.is_disabled_artwork_image = true,
        this.is_disabled_artwork_ai = true,
        this.is_disabled_remarks = true,
        this.is_disabled_attachment = true
        
    },
    created(){
        if(this.$store.getters.artwork){
            this.getPlants()
            this.artwork = this.$store.getters.artwork,
            this.artwork.artwork_no = this.artwork.artwork_no
            this.artwork.artwork_pdf_name = this.artwork.artwork_pdf,
            this.artwork.artwork_image_name = this.artwork.artwork_image,
            this.artwork.artwork_ai_name = this.artwork.artwork_ai,
            this.artwork.attachment_name = this.artwork.attachment
        }
    },
    methods:{
        getPDFFile(e) {
            let vm = this;
            vm.artwork.artwork_pdf= e.target.files[0]
            vm.artwork.artwork_pdf_name = e.target.files[0].name
        },
        getImageFile(e) {
            let vm = this;
            vm.artwork.artwork_image= e.target.files[0]
            vm.artwork.artwork_image_name = e.target.files[0].name
        },
        getAIFile(e) {
            let vm = this;
            vm.artwork.artwork_ai= e.target.files[0]
            vm.artwork.artwork_ai_name = e.target.files[0].name
        },
        getAttachment(e) {
            let vm = this;
            vm.artwork.attachment= e.target.files[0]
            vm.artwork.attachment_name = e.target.files[0].name
        },

        getPlants() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data
                    vm.getBagTypes()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getBagTypes() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getBagTypes" })
                .then(function (response) {
                    vm.bagtypes = response.data.data
                    vm.getMaterials()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getMaterials() {
            let vm = this;
            if(!vm.is_disabled_bag_type)
                vm.artwork.material_id= ''
            vm.$store
                .dispatch("post", { uri: "getMaterials", data:vm.artwork })
                .then(function (response) {
                    vm.materials = response.data.data
                    vm.getLanguages()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },
        getLanguages() {
            let vm = this;
            vm.$store
                .dispatch("post", { uri: "getLanguages" })
                .then(function (response) {
                    vm.languages = response.data.data
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error;
                    vm.$store.dispatch("error", error.response.data.message);
                });
        },

        editArtwork(){
            this.is_disabled_plant = false,
            this.is_disabled_bag_type = false,
            this.is_disabled_material = false,
            this.is_disabled_language = false,
            this.is_disabled_bis_approval_date = false,
            this.is_disabled_artwork_pdf = false,
            this.is_disabled_artwork_image = false,
            this.is_disabled_artwork_ai = false,
            this.is_disabled_remarks = false
            this.is_disabled_attachment = false
        },

        disableArtwork(){
            this.is_disabled_plant = true,
            this.is_disabled_bag_type = true,
            this.is_disabled_material = true,
            this.is_disabled_language = true,
            this.is_disabled_bis_approval_date = true,
            this.is_disabled_artwork_pdf = true,
            this.is_disabled_artwork_image = true,
            this.is_disabled_artwork_ai = true,
            this.is_disabled_remarks = true
            this.is_disabled_attachment = true
        },

        editArtworkTs(){
            this.update_status = true
            this.editArtwork()
        },

        updateArtworkTs(){
            this.update_status = false
            this.updateArtwork()
        },

        updateArtwork(){
            let vm = this;
            let loader = vm.$loading.show()
            const formData = new FormData()
            formData.append('artwork_id', vm.artwork.artwork_id)
            formData.append('artwork_no', vm.artwork.artwork_no)
            formData.append('artwork_date', vm.artwork.artwork_date)
            formData.append('plant_id', vm.artwork.plant_id)
            formData.append('bag_type_id', vm.artwork.bag_type_id) 
            formData.append('artwork_type', vm.artwork.artwork_type) 
            formData.append('material_id', vm.artwork.material_id) 
            formData.append('language_id', vm.artwork.language_id) 
            formData.append('bis_approval_date', vm.artwork.bis_approval_date) 
            formData.append('artwork_pdf', vm.artwork.artwork_pdf) 
            formData.append('artwork_image', vm.artwork.artwork_image) 
            formData.append('artwork_ai', vm.artwork.artwork_ai)
            formData.append('attachment', vm.artwork.attachment)
            formData.append('change_in', vm.artwork.change_in)
            if(vm.artwork.reference_id)
                formData.append('reference_id', vm.artwork.reference_id)
            if(vm.artwork.remarks)
                formData.append('remarks', vm.artwork.remarks)
            vm.$store.dispatch("post", { uri: "updateArtwork", data:formData })
            .then(function (response) {
                loader.hide();
                vm.errors = []
                vm.artwork.artwork_pdf_name = response.data.data.artwork_pdf
                vm.artwork.artwork_image_name = response.data.data.artwork_image
                vm.artwork.artwork_ai_name = response.data.data.artwork_ai
                vm.artwork.attachment_name = response.data.data.attachment
                vm.artwork.attachment = response.data.data.attachment
                vm.disableArtwork()
                vm.$store.dispatch("success", "Artwork updated successfully");
            })
            .catch(function (error) {
                loader.hide();
                console.log(error)
                vm.errors = error.response.data.errors;
                vm.$store.dispatch("error", error.response.data.message);
            });
        },

         reviseArtwork(){
            if(confirm('Are you sure you want to revise artwork?'))
                this.$router.push('/artwork/'+this.artwork.artwork_id+'/revise')
        },
    }
}
</script>