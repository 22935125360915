<template>
    <div class="tab">
        <button class="tablinks" :class="ts_active" @click="showTab('ts')">TS</button>
        <div>
            <button class="tablinks" :class="tpmc_active" @click="showTab('tpmc')" v-if="tpmc_visible">TPMC</button>
        </div>
        <div>
            <button class="tablinks" :class="cpcm_active" @click="showTab('cpcm')" v-if="cpcm_visible">CPC Maker</button>
        </div>
        <div>
            <button class="tablinks" :class="cpcc_active" @click="showTab('cpcc')" v-if="cpcc_visible">CPC Checker</button>
        </div>
        <div>
            <button class="tablinks" :class="download_active" @click="showTab('downloads')">Download</button>
        </div>
    </div>
</template>
<script>
export default {
    data(){
		return{
			tab: this.$store.getters.tab,
			ts_active : '',
			tpmc_active : '',
			cpcm_active : '',
			cpcc_active : '',
			download_active : '',
            tpmc_visible:'',
            cpcm_visible:'',
            cpcc_visible:'',
            artwork_no:''
		}
    },
    watch:{
        '$store.getters.artwork':function(){
            let role = this.$store.getters.user.role
            if(role == 'CPC Maker' && !this.$store.getters.artwork.tpmc_submitted_date){
                if(this.tab.localeCompare('downloads')){
                    this.$store.dispatch("setTab", 'ts')
                }
            }
            if(role== 'CPC Checker' && this.$store.getters.artwork.tpmc_submitted_date && !this.$store.getters.artwork.sku_mapped_date){
                if(!this.tab.localeCompare('cpcc') || !this.tab.localeCompare('cpcm')){
                    this.$store.dispatch("setTab", 'ts')
                }
            }
            if(role== 'CPC Checker' && !this.$store.getters.artwork.tpmc_submitted_date){
                if(!this.tab.localeCompare('cpcc') || !this.tab.localeCompare('cpcm') || !this.tab.localeCompare('tpmc')){
                    this.$store.dispatch("setTab", 'ts')
                }
            }
            this.updateVisibility()
        },
    },

	mounted(){
		// this.setActive()
	},

    created(){
        this.setActive()
    },

    methods:{
        showTab(tab){
            this.$store.dispatch("setTab", tab)
            this.showArtwork()
		},
		setActive(){
            if(this.tab == 'ts'){
                this.ts_active = 'active'
                this.tpmc_active = ''
                this.cpcm_active = ''
                this.cpcc_active = ''
                this.download_active = ''
            }
            if(this.tab == 'tpmc'){
                this.ts_active = ''
                this.tpmc_active = 'active'
                this.cpcm_active = ''
                this.cpcc_active = ''
                this.download_active = ''
            }
            if(this.tab == 'cpcm'){
                this.ts_active = ''
                this.tpmc_active = ''
                this.cpcm_active = 'active'
                this.cpcc_active = ''
                this.download_active = ''
            }
            if(this.tab == 'cpcc'){
                
                this.ts_active = ''
                this.tpmc_active = ''
                this.cpcm_active = ''
                this.cpcc_active = 'active'
                this.download_active = ''
            }
            if(this.tab == 'downloads'){
                this.ts_active = ''
                this.tpmc_active = ''
                this.cpcm_active = ''
                this.cpcc_active = ''
                this.download_active = 'active'
            }
            this.updateVisibility()
        },

        updateVisibility(){
            // alert("1")
            let role = this.$store.getters.user.role
            // alert(this.$store.getters.artwork.tpmc_submitted_date)
            if(role == 'TPMC'){
                this.tpmc_visible = true
                this.cpcm_visible = false
                this.cpcc_visible = false
            }
            else if(role == 'CPC Maker' && this.$store.getters.artwork.tpmc_submitted_date){
                this.tpmc_visible = true
                this.cpcm_visible = true
                this.cpcc_visible = false
            }else if(role == 'CPC Maker' && !this.$store.getters.artwork.tpmc_submitted_date){
                this.tpmc_visible = false
                this.cpcm_visible = false
                this.cpcc_visible = false
            }else if(role== 'CPC Checker' && this.$store.getters.artwork.tpmc_submitted_date && !this.$store.getters.artwork.sku_mapped_date){
                this.tpmc_visible = true
                this.cpcm_visible = false
                this.cpcc_visible = false
            }else if(role== 'CPC Checker' && this.$store.getters.artwork.sku_mapped_date){
                this.tpmc_visible = true
                this.cpcm_visible = true
                this.cpcc_visible = true
            }else{
                this.tpmc_visible = false
                this.cpcm_visible = false
                this.cpcc_visible = false
            }
        },

        showArtwork(){            
            let vm = this;
            let artwork = this.$store.getters.artwork
            vm.$store.dispatch("post", { uri: "getArtwork", data: artwork })
            .then(function (response) {
                vm.$store.dispatch("setArtwork", response.data.data)
                // vm.setArtworkVersions()
            })
            .catch(function (error) {
                console.log(error)
            });
        },
        setArtworkVersions(){
            let vm = this
            vm.versions = []
            let artwork = this.$store.getters.artwork
            if(artwork.children?.length){
                vm.versions = artwork.children
                    vm.versions.unshift({
                    artwork_id : artwork.artwork_id,
                    artwork_no : artwork.artwork_no,
                    artwork_date : artwork.artwork_date,
                    artwork_type : artwork.artwork_type,
                    plant_id : artwork.plant_id,
                    bag_type_id : artwork.bag_type_id,
                    material_id : artwork.material_id,
                    language_id : artwork.language_id,
                    bis_approval_date : artwork.bis_approval_date,
                    artwork_pdf_name : artwork.artwork_pdf,
                    artwork_image_name : artwork.artwork_image,
                    artwork_ai_name : artwork.artwork_ai,
                    cpc_approved_date : artwork.cpc_approved_date,
                    next_cpc_approved_date: artwork.next_cpc_approved_date
                })
            }else if(artwork.parent?.children.length){
                vm.versions = artwork.parent.children
                    vm.versions.unshift({
                    artwork_id : artwork.parent.artwork_id,
                    artwork_no : artwork.parent.artwork_no,
                    artwork_date : artwork.parent.artwork_date,
                    artwork_type : artwork.parent.artwork_type,
                    plant_id : artwork.parent.plant_id,
                    bag_type_id : artwork.parent.bag_type_id,
                    material_id : artwork.parent.material_id,
                    language_id : artwork.parent.language_id,
                    bis_approval_date : artwork.parent.bis_approval_date,
                    artwork_pdf_name : artwork.parent.artwork_pdf,
                    artwork_image_name : artwork.parent.artwork_image,
                    artwork_ai_name : artwork.parent.artwork_ai,
                    cpc_approved_date: artwork.parent.cpc_approved_date,
                    next_cpc_approved_date: artwork.parent.next_cpc_approved_date
                })
            }else{
                vm.versions.unshift({
                    artwork_id : artwork.artwork_id,
                    artwork_no : artwork.artwork_no,
                    artwork_date : artwork.artwork_date,
                    artwork_type : artwork.artwork_type,
                    plant_id : artwork.plant_id,
                    bag_type_id : artwork.bag_type_id,
                    material_id : artwork.material_id,
                    language_id : artwork.language_id,
                    bis_approval_date : artwork.bis_approval_date,
                    artwork_pdf_name : artwork.artwork_pdf,
                    artwork_image_name : artwork.artwork_image,
                    artwork_ai_name : artwork.artwork_ai,
                    cpc_approved_date : artwork.cpc_approved_date,
                    next_cpc_approved_date: artwork.next_cpc_approved_date
                })
            }
        }
    }
}
</script>
<style scoped>
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 16px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #007bff;
  color: #fff;
}

/* Style the tab content */
.tabcontent {
  padding: 6px 12px;
  border: none;
}
</style>