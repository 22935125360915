<template>
    <h5 class="mb-2">Users</h5>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">New User</h3>
        </div>
        <form @submit.prevent="submitForm">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="user.name" ref="name" />
                            <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label>Username <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.username}" placeholder="Username" v-model="user.username" />
                            <span v-if="errors.username" class="invalid-feedback">{{ errors.username[0] }}</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group" v-if="status">
                            <label>Password <span class="text-danger">*</span></label>
                            <input type="password" class="form-control form-control-sm" :class="{'is-invalid': errors.password}" placeholder="Password" v-model="user.password" />
                            <span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <label>Email <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.email}" placeholder="Email Address" v-model="user.email" />
                            <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label>Role <span class="text-danger">*</span></label>
                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.role }" v-model="user.role">
                                <option value="">Select Role</option>
                                <option value="TS">TS</option>
                                <option value="TPMC">TPMC</option>
                                <option value="CPC Maker">CPC Maker</option>
                                <option value="CPC Checker">CPC Checker</option>
                                <option value="CPC User">CPC User</option>
                                <option value="MM">MM</option>
                                <option value="QC">QC</option>
                                <option value="Vendor">Vendor</option>
                                <option value="TPIA">TPIA</option>
                            </select>

                            <span v-if="errors.role" class="invalid-feedback">{{ errors.role[0] }}</span>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group" v-if="user.role==='MM'||  user.role==='QC' || user.role==='TPIA'">
                            <!-- <PlantSearch
                                :class="{ 'is-invalid': errors.plant_id }"
                                :customClass="{ 'is-invalid': errors.plant_id }"
                                :initialize="user.plant_id"
                                id="plant_id"
                                label="plant_name"
                                placeholder="Select Plant"
                                :data="plants"
                                @input="selectPlant($event)"
                            /> -->
                            <label>Plant</label>
                            <div class="dropdown" @click="showDropdown">
                                <div class="overselect"></div>
                                <select class="form-control form-control-sm">
                                    <option value="">Please Select Plant</option>
                                </select>
                            </div>
                            <div class="multiselect">
                                <ul>
                                    <li class="" v-for="(plant, index) in plants" :key="index">
                                        <input type="checkbox" :value="plant.plant_id" v-model="user.plants" style="padding: 2px;" />
                                        <label style="margin-left: 5px;">{{ plant.plant_name }}</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group" v-if="user.role==='Vendor'">       
                            <label>Vendors</label>
                            <Search
                                :class="{ 'is-invalid': errors.vendor_id }"
                                :customClass="{ 'is-invalid': errors.vendor_id }"
                                :initialize="user.vendor_id"
                                id="vendor_id"
                                label="vendor_name"
                                placeholder="Select Vendor"
                                :data="vendors"
                                @input="selectVendor($event)"
                            />
                            <span v-if="errors.vendor_id" class="invalid-feedback">{{ errors.vendor_id[0] }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="float-right">
                    <button type="button" class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button>
                    <button class="btn btn-outline-success btn-sm" type="submit">
                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                        <span v-else> <i class="fas fa-save"></i> Update </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Users</h3>
                    <div class="card-tools">
                        <div class="input-group input-group-sm">
                            <input type="text" name="table_search" class="form-control float-right" placeholder="Search" v-model="meta.search" @keyup="search()" />
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive table-responsive-sm">
                    <table class="table table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th @click="sort('name')">
                                    Name
                                    <i v-if="meta.keyword=='name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th @click="sort('username')">
                                    Username
                                    <i v-if="meta.keyword=='username' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='username' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th @click="sort('email')">
                                    Email
                                    <i v-if="meta.keyword=='email' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='email' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th @click="sort('role')">
                                    Role
                                    <i v-if="meta.keyword=='role' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='role' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>

                                <th class="text-center">Status</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="users.length==0">
                                <td colspan="7" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(user, key) in users" :key="key">
                                <td>{{ meta.from+key }}</td>
                                <td>{{ user.name }}</td>
                                <td>{{ user.username}}</td>
                                <td>{{ user.email}}</td>
                                <td>{{ user.role}}</td>
                                <td class="text-center">
                                    <div class="card-tools">
                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                :id="'user' + user.user_id"
                                                :checked="user.status"
                                                :value="user.status"
                                                @change="deleteUser(user)"
                                            />
                                            <label class="custom-control-label" :for="'user' + user.user_id"></label>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center py-0 align-middle">
                                    <button class="btn btn-xs btn-outline-success" @click.prevent="edit(user)" data-toggle="tooltip" data-placement="bottom" title="Edit">
                                        <i class="fas fa-pencil-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    import Search from "@/components/Search.vue";
    import PlantSearch from "@/components/PlantSearch.vue";
    export default {
        components: { Pagination, Search, PlantSearch },
        data() {
            return {
                status: true,
                show: false,
                plants:[],
                vendors:[],
                user: {
                    name: '',
                    email: '',
                    plant_id: [],
                    vendor_id:'',
                    plants: [],
                    vendors:[],
                    role: '',
                    username:'',
                    password: '',
                    is_admin: true,
                },
                users:[],
                meta: {
                    search: '',
                    order_by: "asc",
                    keyword: "user_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    },
                errors: [],
            };
        },
        mounted() {
            this.index();
        },
        methods: {
            selectVendor(e) {
                let vm = this
                vm.user.vendor_id = e
            },
            selectPlant(e) {
                let vm = this
                vm.user.plant_id = e
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            showDropdown() {
                this.show = !this.show;
            },
            addUser() {
                let vm = this;
                if (vm.urlpath === "vendor") {
                    vm.userheading = "Vendor";
                    vm.h1userheading = "Vendor";
                    vm.user.role = "VENDOR";
                }
                if (vm.urlpath === "User") {
                    vm.userheading = "User";
                    vm.h1userheading = "User";
                }
                vm.user.plant_id = vm.selected;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addUser", data: vm.user })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", " Added successfully");
                        vm.index();
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getPlants() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getPlants" })
                    .then(function (response) {
                        vm.plants = response.data.data
                        vm.getVendors()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getVendors() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getVendors" })
                    .then(function (response) {
                        vm.vendors = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateUsers", data: vm.meta })
                    .then(function (response) {
                        vm.users = response.data.data
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                        vm.getPlants()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.addUser();
                } else {
                    vm.updateUser();
                }
            },
            addLanguage() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addLanguage", data: vm.language })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Language Added successfully");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            updateUser() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateUser", data: vm.user };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "User is successfully updated");
                        vm.index();
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
                let vm = this;
                vm.user.name = ''
                vm.user.username = ''
                vm.user.password = ''
                vm.user.email = ''
                vm.user.role = ''
                vm.user.plant_id=''
                vm.user.plants=[]
                vm.user.vendors=[]
                vm.user.vendor_id=''
                vm.$refs.name.focus();
                vm.index();
                vm.errors = [];
                vm.status = true;
            },
            edit(user) {
                let vm = this
                vm.status = false
                vm.user = user
                vm.user.plants = []
                vm.user.vendors = []
                if(user.user_plants.length){
                    user.user_plants.map(function(element){
                        vm.user.plants.push(element.plant_id)
                    })
                }
                vm.$refs.name.focus();
            },
            deleteUser(user) {
                let vm = this;
                user.status = user.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deleteUser", data: user })
                    .then(function () {
                        vm.discard();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
        },
    };
</script>
<style scoped>
    .dropdown {
        position: relative;
        cursor: pointer;
    }
    .multiselect {
        position: relative;
    }
    .multiselect ul {
        border: 1px solid #ddd;
        border-top: 0;
        border-radius: 0 0 3px 3px;
        left: 0px;
        padding: 8px 8px;
        top: -0.1rem;
        width: 100%;
        list-style: none;
        max-height: 150px;
        overflow: auto;
        background: white;
    }
    .overselect {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>

