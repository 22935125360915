<template>
    <h5 class="mb-2">Logs</h5>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Logs</h3>
                    <div class="card-tools">
                        <div class="input-group input-group-sm">
                            <input type="text" name="table_search" class="form-control float-right" placeholder="Search" v-model="meta.search" @keyup="search()" />
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-responsive-sm table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th @click="sort('file_path')" width="30%">
                                    File Path
                                    <span>
                                        <i v-if="meta.keyword=='file_path' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                        <i v-else-if="meta.keyword=='file_path' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('date_time')" width="15%">
                                    Upload Date Time
                                    <i v-if="meta.keyword=='date_time' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='date_time' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th @click="sort('created_at')" width="15%">
                                    Created Date Time
                                    <i v-if="meta.keyword=='created_at' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='created_at' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th @click="sort('message')" width="20%">
                                    Message
                                    <i v-if="meta.keyword=='message' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='message' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th class="text-center" width="10%">Status</th>
                                <th class="text-center" width="10%">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="logs.length==0">
                                <td colspan="7" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(log,key) in logs" :key="key">
                                <td>{{meta.from+key }}</td>
                                <td>{{log.file_path}}</td>
                                <td>
                                    {{convertDateFormat(log.date_time)}}
                                </td>
                                <td>
                                    {{convertDateFormat(log.created_at)}}
                                </td>
                                <td>
                                    {{log.message}}
                                </td>
                                <td class="text-center py-0 align-middle">
                                    <span class="badge bg-success" v-if="log.status">Success</span>
                                    <span class="badge bg-danger" v-else>Failed</span>
                                </td>
                                <td class="text-center py-0 align-middle">
                                    <button class="btn btn-xs btn-outline-info" data-toggle="tooltip" data-placement="bottom" title="View" @click.prevent="view(log)">
                                        <i class="fas fa-eye"></i>
                                    </button>&emsp;
                                    <a :href="$store.getters.webUrl+'downloadLogs?file_name='+log.file_path" class="btn btn-xs btn-outline-warning" data-toggle="tooltip" data-placement="bottom" title="Download">
                                        <i class="fas fa-download"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    import moment from "moment";
    export default {
        components: { Pagination },
        data() {
            return {
                logs: [],
                meta: {
                    search: "",
                    order_by: "desc",
                    keyword: "log_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                errors: [],
            };
        },
        mounted() {
            this.index();
        },
        methods: {
            convertDateFormat(date) {
                return moment(date).format("DD-MM-YYYY hh:mm A")
            },
            view(log){
                this.$router.push('log/'+log.log_id+'/view');
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginateLogs", data: vm.meta })
                    .then(function (response) {
                        vm.logs = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
        },
    };
</script>
<style scoped>
.padding{
    padding:5px;
}
</style>
