import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store/index.js";
import Dashboard from "@/views/Dashboard.vue";

import Cluster from "@/views/configuration/Cluster";
import Plant from "@/views/configuration/Plant";
import BagType from "@/views/configuration/BagType";
import Language from "@/views/configuration/Language";
import Material from "@/views/configuration/Material";
import Vendor from "@/views/configuration/Vendor";
import Login from "@/views/auth/Login";
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import User from "@/views/configuration/User";
import Profile from "@/views/auth/Profile";

import CreateArtwork from "@/views/artwork/Create";
import ArtworkShow from "@/views/artwork/Show";
import ArtworkRevise from "@/views/artwork/Revise";

import Contracts from "@/views/Contracts";

import Logs from "@/views/logs/Index";
import LogView from "@/views/logs/View";


const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: { auth: true, access:true },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: { unAuth: true },
    },

    {
        path: "/cluster",
        name: "Cluster",
        component: Cluster,
        meta: { auth: true, access:false },
    },

    {
        path: "/plant",
        name: "Plant",
        component: Plant,
        meta: { auth: true, access:false },
    },

    {
        path: "/bag_type",
        name: "Bagtype",
        component: BagType,
        meta: { auth: true, access:false },
    },
    {
        path: "/language",
        name: "Language",
        component: Language,
        meta: { auth: true, access:false },
    },
    {
        path: "/material",
        name: "Material",
        component: Material,
        meta: { auth: true, access:false },
    },
    {
        path: "/vendor",
        name: "Vendor",
        component: Vendor,
        meta: { auth: true, access:false },
    },
    {
        path: "/user",
        name: "User",
        component: User,
        meta: { auth: true, access:false },
    },
    {
        path: "/artwork/create",
        name: "CreateArtwork",
        component: CreateArtwork,
        meta: { auth: true, access:true },
    },
    {
        path: "/artwork/:artwork_id/show",
        name: "ArtworkShow",
        component: ArtworkShow,
        meta: { auth: true, access:true },
    },
    {
        path: "/artwork/:artwork_id/revise",
        name: "ArtworkRevise",
        component: ArtworkRevise,
        meta: { auth: true, access:true },
    },
    { 
		path: '/forgot_password', name: 'ForgotPassword.Index', 
		component: ForgotPassword,  meta: { unAuth: true }  
	},
    {
        path: "/profile",
        name: "profile",
        component: Profile,
        meta: { auth: true, access:true },
    },

    {
        path: "/logs",
        name: "Logs",
        component: Logs,
        meta: { auth: true, access:false },
    },
    {
        path: "/contracts",
        name: "Contracts",
        component: Contracts,
        meta: { auth: true, access:false },
    },
    {
        path: "/log/:log_id/view",
        name: "LogView",
        component: LogView,
        meta: { auth: true, access:false },
    },

];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes
});
router.beforeEach(function (to, _, next) {
    if(!to.meta.access && store.getters.user?.is_admin){
        to.meta.access = true
    }else if(!to.meta.access && !store.getters.user?.is_admin){
        to.meta.access = false
    }else if(to.meta.access && store.getters.user?.is_admin){
        to.meta.access = true
    }else{
        to.meta.access = true
    }
    if (to.meta.auth && !store.getters.user) {
        next("login");
    } else if ((to.meta.unAuth && store.getters.user)||(to.meta.auth && store.getters.user && !to.meta.access)) {
        next("/");
    } else {
        next();
    }
});

export default router;
