<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Versions</h3>
        </div>
        <ul class="list-group" v-if="versions?.length">
            <li class="list-group-item" :class="version?.artwork_id==artwork?.artwork_id ? 'active' : ''" v-for="version, key in versions" :key="key" @click="showArtwork(version)">
                <span>{{version.artwork_no}}</span><br/><span class="small" v-if="version.cpc_approved_date">{{convertDateFormat(version.cpc_approved_date)}}</span><span class="small" v-if="version.next_cpc_approved_date">{{' to '+convertDateFormat(version.next_cpc_approved_date)}}</span>
            </li>
        </ul>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    data(){
        return{
            artwork:{},
            versions:[]
        }
    },
    watch:{
        '$store.getters.artwork':function(){
            if(this.$store.getters.artwork){
                this.artwork = this.$store.getters.artwork
            }
        },
        '$store.getters.versions':function(){
            if(this.$store.getters.versions){
                this.versions = this.$store.getters.versions
            }
        },
    },
    mounted(){
        
    },
    created(){
        this.artwork = this.$store.getters.artwork
        this.versions = this.$store.getters.versions
    },
    methods:{
        convertDateFormat(date){
            return  moment(date).format('DD-MM-YYYY')
        },
        showArtwork(artwork){
            if(this.$store.getters.user.role=='TS' || this.$store.getters.user.role=='TPMC' || this.$store.getters.user.role=='CPC Maker' || this.$store.getters.user.role=='CPC Checker'){            
                let vm = this;
                vm.$store.dispatch("post", { uri: "getArtwork", data: artwork })
                .then(function (response) {
                    vm.$store.dispatch("setArtwork", response.data.data)
                    vm.setArtworkVersions()
                })
                .catch(function (error) {
                    console.log(error)
                });
            }
        },
        setArtworkVersions(){
            let vm = this
            vm.versions = []
            let artwork = this.$store.getters.artwork
            if(artwork.children?.length){
                vm.versions = artwork.children
                    vm.versions.unshift({
                    artwork_id : artwork.artwork_id,
                    artwork_no : artwork.artwork_no,
                    artwork_date : artwork.artwork_date,
                    artwork_type : artwork.artwork_type,
                    plant_id : artwork.plant_id,
                    bag_type_id : artwork.bag_type_id,
                    material_id : artwork.material_id,
                    language_id : artwork.language_id,
                    bis_approval_date : artwork.bis_approval_date,
                    artwork_pdf_name : artwork.artwork_pdf,
                    artwork_image_name : artwork.artwork_image,
                    artwork_ai_name : artwork.artwork_ai,
                    cpc_approved_date : artwork.cpc_approved_date,
                    next_cpc_approved_date: artwork.next_cpc_approved_date
                })
            }else if(artwork.parent?.children.length){
                vm.versions = artwork.parent.children
                    vm.versions.unshift({
                    artwork_id : artwork.parent.artwork_id,
                    artwork_no : artwork.parent.artwork_no,
                    artwork_date : artwork.parent.artwork_date,
                    artwork_type : artwork.parent.artwork_type,
                    plant_id : artwork.parent.plant_id,
                    bag_type_id : artwork.parent.bag_type_id,
                    material_id : artwork.parent.material_id,
                    language_id : artwork.parent.language_id,
                    bis_approval_date : artwork.parent.bis_approval_date,
                    artwork_pdf_name : artwork.parent.artwork_pdf,
                    artwork_image_name : artwork.parent.artwork_image,
                    artwork_ai_name : artwork.parent.artwork_ai,
                    cpc_approved_date: artwork.parent.cpc_approved_date,
                    next_cpc_approved_date: artwork.parent.next_cpc_approved_date
                })
            }else{
                vm.versions.unshift({
                    artwork_id : artwork.artwork_id,
                    artwork_no : artwork.artwork_no,
                    artwork_date : artwork.artwork_date,
                    artwork_type : artwork.artwork_type,
                    plant_id : artwork.plant_id,
                    bag_type_id : artwork.bag_type_id,
                    material_id : artwork.material_id,
                    language_id : artwork.language_id,
                    bis_approval_date : artwork.bis_approval_date,
                    artwork_pdf_name : artwork.artwork_pdf,
                    artwork_image_name : artwork.artwork_image,
                    artwork_ai_name : artwork.artwork_ai,
                    cpc_approved_date : artwork.cpc_approved_date,
                    next_cpc_approved_date: artwork.next_cpc_approved_date
                })
            }
        }
    }
}
</script>