<template>
    <h5 class="mb-2">{{artwork.artwork_no}}</h5>
    <div class="row">
        <div class="col-sm-3">
            <Versions  ref="versions"  @showArtwork="showArtwork"/>
        </div>
        <div class="col-sm-9">
            <div class="card">
                <Menu />
                <div class="card-body">
                    <div class="row" v-if="!artwork.cpc_approved_date">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label>CPC Approval Date <span class="text-danger">*</span></label>
                                    <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.cpc_approved_date }" v-model="cpcc.cpc_approved_date" readonly />
                                    <span v-if="errors.cpc_approved_date" class="invalid-feedback">{{ errors.cpc_approved_date[0] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <table class="table table-responsive-sm table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th width="5%">1</th>
                                        <th width="25%">CPC Approved Date</th>
                                        <td width="70%">
                                            <input type="date" class="form-control form-control-sm" :class="{ 'is-invalid': errors.artwork_date }" v-model="artwork.cpc_approved_date" disabled />
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                        </div>

                </div>
                <div class="card-footer">
                    <div class="float-right" v-if="!artwork.cpc_approved_date">
                        <button class="btn btn-outline-success btn-sm" v-if="!cpcc.update_status" @click="updateArtworkCpcc()">
                            <span> <i class="fas fa-save"></i> Approve </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import Versions from '@/components/artworks/Versions.vue'
import Menu from '@/components/artworks/Menu.vue'
import moment from 'moment'
export default {    
    components:{Versions, Menu},
    data(){
        return{
            role:this.$store.getters.user?.role,
            tab: 'cpcc',
            cpcc:{
                artwork_id:'',
                cpc_approved_date: moment().format('yyyy-MM-DD'),
                update_status:false,
                is_visible:false
            },
            artwork:{},
            versions:[], 
            errors:[]
        }
    },
    watch:{
        '$store.getters.artwork':function(){
            if(this.$store.getters.artwork){
                this.artwork = this.$store.getters.artwork
            }
        },
    },
    mounted(){
        this.artwork = this.$store.getters.artwork
        
    },
    methods:{
        showArtwork(artwork_id){
            this.$emit('showArtwork', artwork_id);
        },

        updateArtworkCpcc(){
            let vm = this;
            let loader = vm.$loading.show()
            vm.cpcc.artwork_id = vm.artwork.artwork_id
            vm.$store
                .dispatch("post", { uri: "updateArtworkCpcc", data:vm.cpcc })
                .then(function () {
                    loader.hide();
                    vm.errors = []
                    vm.getArtwork()
                    vm.$store.dispatch("success", "Artwork updated successfully")                   
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message)
                });
        },
        getArtwork() {
            let vm = this;
            vm.$store.dispatch("setArtwork", null)
            vm.$store.dispatch("post", { uri: "getArtwork", data: vm.cpcc })
            .then(function (response) {
                vm.$store.dispatch("setArtwork", response.data.data)
            })
            .catch(function (error) {
                console.log(error)
            });
        },
    }
}
</script>