import { createStore } from 'vuex'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import axios from 'axios';

export default createStore({
	state: {
		apiUrl: "https://artwork.akxatech.com/api/",
		assetUrl: "https://artwork.akxatech.com/storage/",
		webUrl: "https://artwork.akxatech.com/",

		// apiUrl: "https://artwork-staging.akxatech.com/api/",
		// assetUrl: "https://artwork-staging.akxatech.com/storage/",
		// webUrl: "https://artwork-staging.akxatech.com/",
		
		// apiUrl: "http://192.168.0.213/artwork-laravel-app/public/api/",
		// assetUrl: "http://192.168.0.213/artwork-laravel-app/public/storage/",
		// webUrl: "http://192.168.0.213/artwork-laravel-app/public/",

		// apiUrl: "http://192.168.0.174/artwork-laravel-app/public/api/",
        // assetUrl: "http://192.168.0.174/artwork-laravel-app/public/storage/",
        // webUrl: "http://192.168.0.174/artwork-laravel-app/public/",

		
		authenticated:false,
		user:null,
		organization:null,
		token:"",
		permissions:[],
		tab:'ts',
		artwork:'',
		versions:[],
	},
	
	mutations: {
		setArtwork(state, artwork){
			state.artwork = artwork;
		},
		setVersions(state, versions){
			state.versions = versions;
		},
		setUser(state, user){
			state.user = user;
		},
		setToken(state, token){
			state.token = token;
		},		
		setUrl(state, url){
			state.apiUrl = url;
		},
		webUrl(state, url){
			state.webUrl = url;
		},
		setPermissions(state, permissions){
			state.permissions = permissions;
		},
		setTab(state, tab){
			state.tab = tab;
		},
	},

	getters: {
		artwork(state){
			return state.artwork;
		},
		versions(state){
			return state.versions;
		},
		user(state){
			return state.user;
		},
		token(state){
			return state.token;
		},
		apiUrl(state){
			return state.apiUrl;
		},
		webUrl(state){
			return state.webUrl;
		},
		assetUrl(state){
			return state.assetUrl;
		},
		pdfUrl(state){
            return state.pdfUrl;
        },
		permissions(state){
			return state.permissions;
		},
		tab(state){
			return state.tab;
		},
	},

	actions: {
		async setUser(context,payload) {
			await context.commit('setUser',payload);
		},
		async setArtwork(context,payload) {
			await context.commit('setArtwork',payload);
		},
		async setVersions(context,payload) {
			await context.commit('setVersions',payload);
		},
		async setToken(context,payload) {
			await context.commit('setToken',payload);
		},
		async setPermissions(context,payload) {
			await context.commit('setPermissions',payload);
		},
		async setTab(context,payload) {
			await context.commit('setTab',payload);
		},
		async logout(context) {
			await context.commit('setUser',null);
			await context.commit('setToken',"");
			await context.commit('setPermissions',[]);
		},


		auth(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data)
				.then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		get(context,payload) {
			return new Promise((resolve, reject) => {
				axios.get(this.state.apiUrl+payload.uri,payload.data, {
                    headers: {
                        'Authorization': 'Bearer' + ' ' + context.getters.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
				});
			});
		},

		post(context,payload) {
			return new Promise((resolve, reject) => {
				axios.post(this.state.apiUrl+payload.uri,payload.data, {
                    headers: {
                        'Authorization': 'Bearer' + ' ' + context.getters.token
                    }
                }).then(function (response) {
					resolve(response);
				})
				.catch(function (error) {
					reject(error);
					if(error.response.data.message == "Unauthenticated.")
					{
						context.commit('setUser',null);
						context.commit('setOrganization',null);
						window.location.href = "/#/login"
						window.location.reload();
					}
				});
			});
		},

		async error(context,description) {
			await createToast({
				title: 'Error',
				description: description || "The given data was invalid."
			},
			{
				showIcon: true,
				hideProgressBar: true,
				type: 'danger',
				position: 'top-right'
			})
		},

		async success(context,description) {
			await createToast({
				title: 'Success',
				description: description || "Data is successfuly subbmited."
			},
			{
				showIcon: true,
				hideProgressBar: true,
				type: 'success',
				position: 'top-right'
			})
		},
	}
})
