<template>
    <h5 class="mb-2">Plants</h5>
    <div class="row">
        <div class="col-sm-4">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">New Plant</h3>
                </div>
                <form @submit.prevent="submitForm">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="plantcode">Cluster</label><span class="text-danger">*</span>
                            <select class="form-control form-control-sm" v-model="plant.cluster_id" :class="{'is-invalid': errors.cluster_id}">
                                <option value="">Select Cluster</option>
                                <option v-for="cluster, key in clusters" :key="key" :value="cluster.cluster_id">{{ cluster.cluster }}</option>
                            </select>
                            <span v-if="errors.cluster_id" class="invalid-feedback">{{ errors.cluster_id[0] }}</span>
                        </div>
                        <div class="form-group">
                            <label for="plantcode">Plant Code</label><span class="text-danger">*</span>
                            <input type="text" class="form-control form-control-sm" id="plantcode" placeholder="Plant Code" :class="{'is-invalid': errors.plant_code}" v-model="plant.plant_code" ref="plant_code" />
                            <span v-if="errors.plant_code" class="invalid-feedback">{{ errors.plant_code[0] }}</span>
                        </div>
                        <div class="form-group">
                            <label for="plantname">Plant Name</label><span class="text-danger">*</span>
                            <input type="text" class="form-control form-control-sm" id="plantname" placeholder="Plant Name" :class="{'is-invalid': errors.plant_name}" v-model="plant.plant_name" ref="plant_name" />
                            <span v-if="errors.plant_name" class="invalid-feedback">{{ errors.plant_name[0] }}</span>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="float-right">
                            <button class="btn btn-outline-danger btn-sm" @click.prevent="discard()"><i class="fas fa-ban"></i> Discard</button>
                            <button class="btn btn-outline-success btn-sm" type="submit">
                                <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                <span v-else> <i class="fas fa-save"></i> Update </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-sm-8">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Plants</h3>
                    <div class="card-tools">
                        <div class="input-group input-group-sm">
                            <input type="text" name="table_search" class="form-control float-right" placeholder="Search" v-model="meta.search" @keyup="search()" />
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-responsive-sm table-bordered table-striped table-sm">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th @click="sort('plant_code')">
                                    Cluster
                                    <span>
                                        <i v-if="meta.keyword=='cluster' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                        <i v-else-if="meta.keyword=='cluster' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('plant_code')">
                                    Plant Code
                                    <span>
                                        <i v-if="meta.keyword=='plant_code' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                        <i v-else-if="meta.keyword=='plant_code' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                        <i v-else class="fas fa-sort"></i>
                                    </span>
                                </th>
                                <th @click="sort('plant_name')">
                                    Plant Name
                                    <i v-if="meta.keyword=='plant_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                    <i v-else-if="meta.keyword=='plant_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                    <i v-else class="fas fa-sort"></i>
                                </th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="plants.length==0">
                                <td colspan="7" class="text-center">No records found</td>
                            </tr>
                            <tr v-for="(plant,key) in plants" :key="key">
                                <td>{{meta.from+key}}</td>
                                <td>{{plant.cluster?.cluster}}</td>
                                <td>{{plant.plant_code}}</td>
                                <td>{{plant.plant_name}}</td>
                                <td class="text-center py-0 align-middle">
                                    <div class="card-tools">
                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" :id="'plant' + plant.plant_id" :checked="plant.status" :value="plant.status" @change="deletePlant(plant)" />
                                            <label class="custom-control-label" :for="'plant' + plant.plant_id"></label>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center py-0 align-middle">
                                    <button class="btn btn-xs btn-outline-success"  @click.prevent="edit(plant)" data-toggle="tooltip" data-placement="bottom" title="Edit">
                                        <i class="fas fa-pencil-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card-footer">
                    <div class="float-right">
                        <Pagination :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                status: true,
                plants: [],
                plant: {
                    plant_id: "",
                    cluster_id:'',
                    plant_code: "",
                    plant_name: "",
                },
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "plant_id",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    trashed: false,
                },
                errors: [],
                clusters:[]
            };
        },
        mounted() {
            this.getClusters()
            this.index()
        },
        methods: {
            getClusters(){
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getClusters" })
                    .then(function (response) {
                        vm.clusters = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },
            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "paginatePlants", data: vm.meta })
                    .then(function (response) {
                        vm.plants = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.addPlant();
                } else {
                    vm.updatePlant();
                }
            },
            addPlant() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addPlant", data: vm.plant })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Plant Added successfully");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            edit(plant) {
                let vm = this
                vm.errors = []
                vm.status = false
                vm.plant = plant
                vm.$refs.plant_code.focus();
            },
            updatePlant() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updatePlant", data: vm.plant };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Plant is successfully updated");
                        vm.discard();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            discard() {
                let vm = this;
                vm.plant.cluster_id = ''
                vm.plant.plant_code = ''
                vm.plant.plant_name = ''
                vm.plant.description = ''
                vm.$refs.plant_code.focus()
                vm.index()
                vm.errors = []
                vm.status = true
            },
            deletePlant(plant) {
                let vm = this;
                plant.status = plant.status == 1 ? 0 : 1;
                vm.$store
                    .dispatch("post", { uri: "deletePlant", data: plant })
                    .then(function () {
                        vm.discard();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.error;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            sort(field) {
                this.meta.keyword = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
        },
    };
</script>

